import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import range from 'lodash/range';
import dayjs from 'dayjs';
import { Tag } from 'antd';
import { useIntl } from 'react-intl';
import { DATE_TIME_FORMAT } from '../../../../const/system';



const CategoriesPriorities = ({ data, onOrderChange }) => {
  const intl = useIntl();
  const [ initOrder, setInitOrder ] = useState([]);
  const [ items, setItems ] = useState(initOrder);

  useEffect(() => {
    if (data.length > 0) {
      const init = range(1, data.length + 1).map((item) => `${item}`);

      setInitOrder(init);
      setItems(init);
    }
  }, [ data ]);

  const handleDragEnd = (result) => {
    if (!result.destination || result.destination.index === result.source.index) {
      return;
    }

    const initialOrder = [ ...items ];
    const [ reorderedItem ] = initialOrder.splice(result.source.index, 1);

    initialOrder.splice(result.destination.index, 0, reorderedItem);
    setItems(initialOrder);
    onOrderChange(initialOrder);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="items">
        {({ innerRef, droppableProps, placeholder }) => {
          return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <div className="categories-priority__container" ref={innerRef} {...droppableProps}>
              {items.map((item, index) => {
                return (
                  <Draggable draggableId={item} index={index} key={item}>
                    {({ innerRef, draggableProps, dragHandleProps }) => {
                      const position = Number(item) - 1;

                      return (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <div className={`categories-priority__item ${!data[position]?.status ? 'categories-priority__item--inactive' : ''} ${data[position]?.deleted_at ? 'categories-priority__item--deleted' : ''}`} ref={innerRef} {...draggableProps} {...dragHandleProps}>
                          <div className="hp-d-flex hp-d-flex-justify-between hp-align-items-center">
                            <div className="hp-p-16">
                              {`${data[position]?.name} (${intl.formatMessage({ id: 'content-images-category-client-id' })}: ${data[position]?.client_id})`}
                            </div>
                            {data[position]?.deleted_at && (
                              <Tag color="default">
                                <span>
                                  {`${intl.formatMessage({ id: 'content-images-category-client-id' })}: `}
                                </span>
                                {dayjs(data[position]?.deleted_at).format(DATE_TIME_FORMAT)}
                              </Tag>
                            )}
                          </div>
                        </div>
                      );
                    }}
                  </Draggable>
                );
              })}
              {placeholder}
            </div>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};

CategoriesPriorities.propTypes = {
  data: PropTypes.array.isRequired,
  onOrderChange: PropTypes.func.isRequired,
};

export default CategoriesPriorities;
