import React from 'react';
import { Row, Switch, Tooltip } from 'antd';
import { PiPencil } from 'react-icons/pi';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import getFormattedDate from '../../../../lib/getFormattedDate';
import { HasRights } from '../../../../components/has-rights/HasRights';
import { Permissions } from '../../../../const/permissions';



export const LANGUAGE_STATUS = { enabled: 'enabled', disabled: 'disabled' };

export const getLanguageListTableColumns = (handleToggleStatus, handleToggleUpdateModal) => [
  {
    title: <IntlMessages id="ui-general-id" />,
    dataIndex: 'id',
    align: 'center',
    width: '80px',
    sorter: true,
  },
  {
    title: <IntlMessages id="ui-general-name" />,
    dataIndex: 'name',
    align: 'center',
    width: '200px',
    sorter: true,
    render: (value) => <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{value}</div>,
  },
  {
    title: <IntlMessages id="languages-code-field" />,
    align: 'center',
    dataIndex: 'code',
    width: '100px',
  },
  {
    title: <IntlMessages id="ui-general-status" />,
    align: 'center',
    width: '110px',
    dataIndex: 'status',
    sorter: true,
    render: (val, item) => (<Switch
      size='small'
      checked={item.status === LANGUAGE_STATUS.enabled}
      disabled={!HasRights([ Permissions.ADMINISTRATION.LANGUAGES.LANGUAGES_LIST.EDIT ]) || item.is_secure}
      defaultChecked
      onChange={(checked) => {
        handleToggleStatus(item.id, { status: checked ? LANGUAGE_STATUS.enabled : LANGUAGE_STATUS.disabled });
      }}
    />),
  },
  {
    title: <IntlMessages id="ui-general-created-at" />,
    dataIndex: 'created_at',
    align: 'center',
    width: '150px',
    render: (value) => getFormattedDate(value),
    sorter: true,
  },
  {
    title: <IntlMessages id="ui-general-action" />,
    key: 'action',
    align: 'center',
    width: '90px',
    render: (item) => {
      return (
        <Row className="da-h-100" align="middle" justify="center">
          {HasRights([ Permissions.ADMINISTRATION.LANGUAGES.LANGUAGES_LIST.EDIT ]) && !item.is_secure && (
            <Tooltip placement="top" title={<IntlMessages id="ui-general-edit" />}>
              <div className="hp-text-right">
                <PiPencil
                  onClick={() => handleToggleUpdateModal(item)}
                  size={20}
                  className="hp-cursor-pointer hp-transition hp-hover-text-color-warning-1 hp-text-color-black-80 hp-m-4"
                />
              </div>
            </Tooltip>
          )}
        </Row>
      );
    },
  },
];
