const popups = {
  'popups-breadcrumbs': 'Диалоги',
  'popups-details-title': 'Диалог',
  'popups-filter-title': 'Фильтр диалогов',
  'popups-table-delete-btn-confirm-message': 'Вы уверены что хотите удалить этот диалог?',
  'popups-deleted-successfully': 'Диалог удален',
  'popups-delete-failed': 'Ошибка при удалении диалога',
  'popups-priority-title': 'Установите правильный порядок диалогов',
  'popups-priority-filter-title': 'Выберите диалоги по дате',
  'popups-priority-updated-successfully': 'Приоритет диалогов обновлен',
  'popups-priority-updated-failed': 'Ошибка при обновлении приоритета диалогов',
};

export default popups;
