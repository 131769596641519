const translations = {
  'translations-breadcrumbs': 'Translations',
  'translations-updated-successfully': 'Translations saved successfully',
  'translations-edit': 'Edit translations',
  'translations-import-title': 'Import dictionary',
  'translations-import-warning': 'Attention! The dictionary translations will be replaced with the data from the imported file.',
  'translations-column-field-label': 'Field',
};

export default translations;
