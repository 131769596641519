const roles = {
  'roles-breadcrumbs': 'Roles',
  'role-details-breadcrumbs': 'Role details',
  'roles-search-by': 'Search by name or display name...',
  'roles-create-role-title': 'Create role',
  'roles-table-role-col': 'Name',
  'roles-table-tooltip-edit-btn': 'Role details',
  'roles-table-tooltip-delete-btn': 'Delete role',
  'roles-table-delete-btn-confirm-message': 'Are you sure to delete role?',
  'form-user-roles-name-field': 'Role name',
  'form-user-role-name-placeholder': 'Specify role name',
  'form-user-roles-display-name-field': 'Display name',
  'form-user-role-display-name-placeholder': 'Specify role display name',
  'form-user-roles-description-field': 'Description',
  'form-user-roles-description-placeholder': 'Specify role description',
  'role-details-card-header': 'Role details',
  'role-permissions-card-header': 'Role permissions',
  'form-user-role-permissions-placeholder': 'Select permissions',
};

export default roles;
