import { useIntl } from 'react-intl';



const useCategoriesOptions = (categories = []) => {
  const intl = useIntl();

  return [ {
    label: intl.formatMessage({ id: 'ui-general-without-category' }),
    value: 999999,
  } ].concat(
    categories.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    }),
  );
};

export default useCategoriesOptions;
