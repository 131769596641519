const getSearchValue = (searchParams) => {
  if (searchParams.has('search')) {
    const search = searchParams.get('search').split(';')[0];

    if (search) {
      return search.split(':')[1];
    }

    return '';
  }

  return '';
};

export default getSearchValue;
