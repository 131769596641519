import React from 'react';
import { notification } from 'antd';
import {
  RiCloseFill,
  RiCheckboxCircleFill,
  RiErrorWarningFill,
} from 'react-icons/ri';



export const notificationTypes = {
  error: 'error',
  success: 'success',
  info: 'info',
  warning: 'warning',
};

const renderNotificationIcon = (type) => {
  switch (type) {
    case 'success': return <RiCheckboxCircleFill style={{ color: '#00F7BF' }} />;
    case 'info': return <RiErrorWarningFill style={{ color: '#1BE7FF' }} />;
    case 'warning': return <RiErrorWarningFill style={{ color: '#FFC700' }} />;
    case 'error': return <RiErrorWarningFill style={{ color: '#FF0022' }} />;

    default: return <RiCheckboxCircleFill style={{ color: '#00F7BF' }} />;
  }
};

export const makeNotification = (type, message, descriptionText = '') => {
  notification.open({
    key: type,
    top: 65,
    message,
    description: descriptionText,
    icon: renderNotificationIcon(type),
    closeIcon: (
      <RiCloseFill className="remix-icon da-text-color-black-80" size={24} />
    ),
  });
};
