import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Tag } from 'antd';
import capitalize from 'lodash/capitalize';
import dayjs from 'dayjs';
import PreviewInfo from '../PreviewInfo';
import { NEWS_STATUS_MAP } from '../../pages/PageNews/PageNews.const';
import { DATE_TIME_FORMAT, EMPTY_IMAGE_PLACEHOLDER, EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';



const NewsPreview = ({ data, languages }) => {

  const dictionary = () => {
    if (data.dictionary?.data?.id) {
      const lang = languages?.find((lang) => lang.code === 'ENG');

      if (!lang) {
        return null;
      }

      return (
        <table>
          <tbody>
            {data?.dictionary?.data?.structure?.data?.fields?.data?.map((field) => {
              const translation = data?.dictionary?.data?.translations.data.find((item) => {
                return item.structure_field_id === field.id && lang.id === item.language_id;
              });

              return (
                <tr key={lang.name}>
                  <th className="hp-px-8 hp-py-4 text-end">{capitalize(field.label)}</th>
                  <td>{translation?.text || EMPTY_VALUE_PLACEHOLDER}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }

    if (data.legacy_dictionary) {
      const fields = Object.entries(data.legacy_dictionary?.translation_english);

      return (
        <table>
          <tbody>
            {fields.map((field) => {
              const [ key, value ] = field;

              return (
                <tr key={key}>
                  <th className="hp-px-8 hp-py-4 text-end">{capitalize(key)}</th>
                  <td>{value}</td>
                </tr>);
            })}
          </tbody>
        </table>
      );
    }

    return null;
  };

  if (!data) {
    return null;
  }

  return (
    <div>
      <Row gutter={16} className="hp-my-16">
        <Col span={10}>
          <img src={data.icon ?? EMPTY_IMAGE_PLACEHOLDER} alt="news preview" />
        </Col>
        <Col span={14}>
          <Row gutter={[ 16, 16 ]} justify="space-around">
            <Col>
              <PreviewInfo
                title="ID"
                value={data.id}
              />
            </Col>
            <Col>
              <PreviewInfo
                title="Status"
                value={<Tag color={data.status === NEWS_STATUS_MAP.ON ? 'success' : 'warning'}>{data.status}</Tag>}
              />
            </Col>
            <Col>
              <PreviewInfo
                title="Priority"
                value={data.priority}
              />
            </Col>
          </Row>
          <hr color="lightgray" className="hp-my-16" style={{ borderTop: 0 }} />
          <div>
            <table>
              <tbody>
                <tr>
                  <th className="hp-px-8 hp-py-4 text-end">Published</th>
                  <td>
                    {data.published_at && dayjs(data.published_at).format(DATE_TIME_FORMAT)}
                    &nbsp;
                    -
                    &nbsp;
                    {data.published_at && dayjs(data.published_to).format(DATE_TIME_FORMAT)}
                  </td>
                </tr>
                <tr>
                  <th className="hp-px-8 hp-py-4 text-end">Type</th>
                  <td>{data.type}</td>
                </tr>
                <tr>
                  <th className="hp-px-8 hp-py-4 text-end">Scheme</th>
                  <td>
                    <div>{data.target_scheme || EMPTY_VALUE_PLACEHOLDER}</div>
                  </td>
                </tr>
                <tr>
                  <th className="hp-px-8 hp-py-4 text-end">URL</th>
                  <td>
                    <div style={{ overflowWrap: 'anywhere' }}>{data.target_url || EMPTY_VALUE_PLACEHOLDER}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr color="lightgray" className="hp-my-16" style={{ borderTop: 0 }} />
          <div>
            {dictionary()}
          </div>
        </Col>
      </Row>
    </div>
  );
};

NewsPreview.propTypes = {
  data: PropTypes.any,
  languages: PropTypes.array.isRequired,
};

export default NewsPreview;
