import React from 'react';
import { Drawer } from 'antd';
import PropTypes from 'prop-types';
import useCheckMobileScreen from '../../app/hooks/useCheckMobileScreen';
import IntlMessages from '../../layout/components/lang/IntlMessages';



const Sidebar = ({
  toggleSidebar,
  visible,
  children,
  title,
  placement = 'right',
  width = 500,
}) => {
  const isMobile = useCheckMobileScreen(600);

  return (
    <Drawer
      title={title || <IntlMessages id='ui-general-filter-title' />}
      onClose={toggleSidebar}
      placement={placement}
      visible={visible}
      width={isMobile ? 350 : width}
    >
      {children}
    </Drawer>
  );
};

Sidebar.propTypes = {
  title: PropTypes.any,
  width: PropTypes.number,
  toggleSidebar: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  placement: PropTypes.oneOf([ 'right', 'left', 'top', 'bottom' ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Sidebar;
