import React from 'react';
import { Button, Card, Col, Descriptions, Dropdown, Menu, Popconfirm, Row, Skeleton, Space } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { MdOutlineUnpublished } from 'react-icons/md';
import { useIntl } from 'react-intl';
import { PiGear, PiPencilSimpleLine, PiTrashSimple } from 'react-icons/pi';
import { DownOutlined } from '@ant-design/icons';
import { RiArrowLeftLine } from 'react-icons/ri';
import dayjs from 'dayjs';
import BreadCrumbs from '../../../../layout/components/breadcrumbs';
import useCheckMobileScreen from '../../../../app/hooks/useCheckMobileScreen';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { makeNotification, notificationTypes } from '../../../../lib/makeNotification';
import { Permissions } from '../../../../const/permissions';
import { PrivateComponent } from '../../../../components/has-rights/HasRights';
import urlPageDynamicNewsEdit from '../../../../urls/urlPageDynamicNewsEdit';
import urlPageDynamicNewsPriority from '../../../../urls/urlPageDynamicNewsPriority';
import urlPageDynamicNews from '../../../../urls/urlPageDynamicNews';
import ActionButton from '../../../../layout/components/action-button';
import PublishedTag from '../../../../components/PublishedTag';
import getFormattedDate from '../../../../lib/getFormattedDate';
import mkPreparedTemplateData from '../../utils/mkPreparedTemplateData';
import TemplateCollapsibleCards from '../../../../components/TemplateCollapsibleCards';
import { TYPE_DICTIONARY } from '../../../Constructors/const/templates';
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  EMPTY_IMAGE_PLACEHOLDER, EMPTY_VALUE_PLACEHOLDER,
} from '../../../../const/system';
import { useGetDictionaryQuery } from '../../../Dictionary/api/dictionariesApiSlice';
import {
  useDeleteDynamicNewsMutation,
  useGetSpecificDynamicNewsQuery,
  useUnpublishDynamicNewsMutation,
} from '../../api/dynamicNewsApiSlice';
import { useGetLanguagesQuery } from '../../../Language/api/languagesApiSlice';



const PageDynamicNewsDetails = () => {
  const { newsId } = useParams();
  const intl = useIntl();
  const isMobile = useCheckMobileScreen(600);
  const navigate = useNavigate();

  const { data: dynamicNews = {}, isFetching } = useGetSpecificDynamicNewsQuery(newsId);

  const dictionaryField = dynamicNews?.dynamic_data?.data?.find((item) => item.type === TYPE_DICTIONARY);

  const { data: newsDictionary = {} } = useGetDictionaryQuery({
    id: dictionaryField?.value,
    include: 'translations,structure,structure.fields.translations',
  }, { skip: !dictionaryField?.value });

  const { data: languages = { languages: [] } } = useGetLanguagesQuery({
    queryParams: 'limit=0',
  }, { skip: !dictionaryField });

  const [ unpublish, { isLoading: isUnpublishDynamicNewsLoading } ] = useUnpublishDynamicNewsMutation();
  const [ deleteDynamicNews, { isLoading: isDynamicNewsDeleting } ] = useDeleteDynamicNewsMutation();

  const data = mkPreparedTemplateData({
    templateData: dynamicNews?.template?.data?.fields?.data,
    dynamicData: dynamicNews?.dynamic_data?.data,
    dictionary: newsDictionary,
    languages: languages?.languages,
  });

  const handleUnpublish = () => {
    unpublish(newsId)
      .unwrap()
      .then(() => {
        makeNotification(
          notificationTypes.success,
          intl.formatMessage({ id: 'ui-general-success' }),
          intl.formatMessage({ id: 'ui-general-unpublish-successfully' }),
        );
      })
      .catch((error) => {
        makeNotification(
          notificationTypes.error,
          intl.formatMessage({ id: 'ui-general-error' }),
          <div className="hp-mb-4">
            {intl.formatMessage({ id: 'ui-general-unpublish-failed' })}
            {error?.data?.message}
          </div>,
        );
      });
  };

  const handleDelete = (id) => {
    deleteDynamicNews(id)
      .unwrap()
      .then(() => {
        makeNotification(
          notificationTypes.success,
          intl.formatMessage({ id: 'ui-general-success' }),
          intl.formatMessage({ id: 'dynamic-news-deleted-successfully' }),
        );
        navigate(urlPageDynamicNews());
      })
      .catch((error) => {
        makeNotification(
          notificationTypes.error,
          intl.formatMessage({ id: 'ui-general-error' }),
          <>
            <p className="hp-mb-4">{intl.formatMessage({ id: 'dynamic-news-delete-failed' })}</p>
            {error?.data?.message && <p>{ error.data.message }</p>}
          </>,
        );
      });
  };

  const actionItems = (
    <Menu>
      <PrivateComponent allowedPermissions={[ Permissions.CONTENT.NEWS.PRIORITY ]}>
        {Boolean(dynamicNews.released_at) && (
          <Menu.Item
            key="priority"
            icon={<PiGear size={18} />}
            onClick={() => {
              const preparedData = dayjs(dynamicNews.released_at).format(DATE_FORMAT);

              return navigate({
                pathname: urlPageDynamicNewsPriority(),
                search: `?search=date%3A${preparedData}`,
              });
            }}
          >
            <IntlMessages id="ui-general-priority" />
          </Menu.Item>
        )}
      </PrivateComponent>
      <PrivateComponent allowedPermissions={[ Permissions.CONTENT.NEWS.EDIT ]}>
        <Menu.Item
          key="edit"
          icon={<PiPencilSimpleLine size={18} />}
          onClick={() => navigate(urlPageDynamicNewsEdit({ newsId: dynamicNews.id }))}
        >
          <IntlMessages id="ui-general-edit" />
        </Menu.Item>
      </PrivateComponent>
      <PrivateComponent allowedPermissions={[ Permissions.CONTENT.NEWS.EDIT ]}>
        {Boolean(dynamicNews?.is_published) && (
          <Menu.Item
            key="unpublish"
            icon={<MdOutlineUnpublished size={18} />}
            onClick={handleUnpublish}
          >
            <IntlMessages id="ui-general-unpublish" />
          </Menu.Item>
        )}
      </PrivateComponent>
      <PrivateComponent allowedPermissions={[ Permissions.CONTENT.NEWS.DELETE ]}>
        {!dynamicNews.is_published &&
          <Popconfirm
            title={<IntlMessages id="dynamic-news-table-delete-btn-confirm-message" />}
            placement="top"
            onConfirm={() => {
              handleDelete(dynamicNews.id);
            }}
          >
            <Menu.Item
              key="delete"
              icon={<PiTrashSimple size={18} />}
              onClick={() => {}}
            >
              <IntlMessages id="ui-general-delete" />
            </Menu.Item>
          </Popconfirm>}
      </PrivateComponent>
    </Menu>
  );

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={<Link to={urlPageDynamicNews()}><IntlMessages id='dynamic-news-breadcrumbs' /></Link>}
          breadCrumbActive={<IntlMessages id='dynamic-news-preview-breadcrumbs' />}
        />

        <Col md={10} span={24} className='text-end'>
          <Space size='middle'>
            <PrivateComponent
              allowedPermissions={[
                Permissions.CONTENT.NEWS.EDIT,
                Permissions.CONTENT.NEWS.DELETE,
              ]}
            >
              <Dropdown overlay={actionItems}>
                <Button onClick={(ev) => ev.preventDefault()}>
                  <Space size='middle'>
                    <IntlMessages id='ui-general-action' />
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </PrivateComponent>

            <ActionButton
              title={intl.formatMessage({ id: 'ui-general-back-to-list' })}
              icon={<RiArrowLeftLine className="hp-mr-6" />}
              onClick={() => {
                navigate(urlPageDynamicNews());
              }}
            />
          </Space>
        </Col>
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card
            loading={isUnpublishDynamicNewsLoading || isDynamicNewsDeleting}
            className="hp-border-color-black-40 hp-card-6 dynamic-news-details"
            title={dynamicNews?.title}
          >
            {isFetching ? (
              <Row gutter={16}>
                <Col span={4}>
                  <Skeleton.Image active />
                </Col>
                <Col span={20}>
                  <Skeleton active />
                </Col>
              </Row>
            ) : (
              <Row gutter={[ 24, 24 ]}>
                <Col sm={24} md={6}>
                  <img src={dynamicNews?.preview ?? EMPTY_IMAGE_PLACEHOLDER} alt="preview" className="hp-mb-24" />
                </Col>
                <Col sm={24} md={18}>
                  <Descriptions
                    title={<IntlMessages id='structures-source-news' />}
                    layout={isMobile ? 'vertical' : 'horizontal'}
                    bordered
                    className="hp-mb-32"
                    column={1}
                  >
                    <Descriptions.Item label="ID" style={{ width: '20vw' }} >
                      <span style={{ wordBreak: 'keep-all' }}>{dynamicNews?.id}</span>
                    </Descriptions.Item>
                    <Descriptions.Item label={<IntlMessages id="ui-general-title" />}>
                      {dynamicNews?.title ?? EMPTY_VALUE_PLACEHOLDER}
                    </Descriptions.Item>
                    <Descriptions.Item label={<IntlMessages id="ui-general-published" />}>
                      <PublishedTag
                        published={dynamicNews?.is_published}
                        publishedDate={dynamicNews?.published_at}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item label={<IntlMessages id="ui-general-released-at" />}>
                      {getFormattedDate(dynamicNews.released_at, DATE_TIME_FORMAT)}
                    </Descriptions.Item>
                    <Descriptions.Item label={<IntlMessages id="ui-general-released-to" />}>
                      {getFormattedDate(dynamicNews.released_to, DATE_TIME_FORMAT)}
                    </Descriptions.Item>
                  </Descriptions>

                  <TemplateCollapsibleCards
                    dictionary={newsDictionary}
                    languages={languages?.languages}
                    data={data}
                    targetData={dynamicNews?.target?.data}
                  />
                </Col>
              </Row>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PageDynamicNewsDetails;
