import React from 'react';
import { Formik } from 'formik';
import { Col, DatePicker, Row } from 'antd';
import { Form, FormItem, SubmitButton } from 'formik-antd';
import { useIntl } from 'react-intl';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import moment from 'moment';
import Yup from '../../vendor/yup';
import IntlMessages from '../../layout/components/lang/IntlMessages';
import useDatepickerHandler from '../../app/hooks/useDatepickerHandler';
import { DATE_FORMAT } from '../../const/system';



const validationSchema = Yup.object().shape({
  date: Yup.date().required('Required to specify date'),
});

const PriorityFilter = ({
  onSubmit,
  onCancel,
  isSubmitting,
  formErrors = {},
  initialValues = {},
}) => {
  const intl = useIntl();
  const { onBlur } = useDatepickerHandler();

  return (
    <Formik
      enableReinitialize
      isSubmitting
      validationSchema={validationSchema}
      initialValues={initialValues}
      initialErrors={formErrors}
      onSubmit={onSubmit}
      onReset={(values) => {
        onCancel(values);
      }}
    >
      {({ isValid, values, setFieldValue }) => {
        return (
          <Form layout="vertical" >
            <Row gutter={16}>
              <Col span={24}>
                <FormItem
                  label={intl.formatMessage({ id: 'news-priority-form-date-label' })}
                  name='date'
                  required
                >
                  <DatePicker
                    showToday
                    name='date'
                    format={DATE_FORMAT}
                    value={values?.date ? moment(values.date) : moment(new Date())}
                    onChange={async (date, dateString) => {
                      await setFieldValue('date', !isEmpty(dateString) ? dateString : moment(new Date()).format(DATE_FORMAT));
                    }}
                    onBlur={async ({ target }) => {
                      await onBlur('date', target.value, setFieldValue);
                    }}
                  />
                </FormItem>
              </Col>
            </Row>

            <Row gutter={[ 16, 16 ]} justify='end'>
              <Col>
                <SubmitButton
                  type="primary"
                  loading={isSubmitting}
                  disabled={!isValid || isEqual(initialValues, values)}
                >
                  <IntlMessages id="ui-general-apply" />
                </SubmitButton>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

PriorityFilter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  initialValues: PropTypes.object,
  formErrors: PropTypes.object,
};

export default PriorityFilter;
