const pushIcons = {
  'push-icons-breadcrumbs': 'Push Icons',
  'push-icons-preview-title': 'Preview push icon',
  'push-icons-table-content-column': 'Content',
  'push-icons-table-folder-column': 'Folder',
  'push-icons-table-message-column': 'Message',
  'push-icons-table-link-column': 'Link',
  'push-icons-create-title': 'Create push icon',
  'push-icons-update-title': 'Update push message',
  'push-icons-search': 'Search by id',
  'push-icon-create-form-dropzone-label': 'File',
  'push-icon-create-form-dropzone-description': 'Click or drag file to this area to upload. File name must be i.(png | jpg | jpeg).',
  'push-icon-create-form-title-label': 'Title',
  'push-icon-create-form-text-label': 'Text',
  'push-icon-create-form-folder-label': 'Folder',
  'push-icons-delete-item-confirmation': 'Are you sure you want to delete the push icon?',
  'push-icons-deleting-failed': 'Deleting push icon failed',
  'push-icons-deleting-success': 'Push icon deleted successfully',
  'push-icons-updating-success': 'Push message updated successfully',
  'push-icons-updating-failed': 'Push message update failed',
};

export default pushIcons;
