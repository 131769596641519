const users = {
  'users-breadcrumbs': 'Users',
  'user-details-breadcrumbs': 'User details',
  'users-create-user-title': 'Create user',
  'users-search-by': 'Search by name or email...',
  'users-table-name-col': 'Name',
  'users-table-email-col': 'Email',
  'users-table-reg-date-col': 'Registration date',
  'users-table-log-date-col': 'Last login',
  'users-table-tooltip-edit-btn': 'User details',
  'users-table-tooltip-delete-btn': 'Delete user',
  'users-table-delete-btn-confirm-message': 'Are you sure to delete user?',
  'user-details-card-header': 'User details',
  'user-details-id-field': 'User ID',
  'user-details-email-field': 'Email',
  'user-details-created-at-field': 'Created at',
  'user-details-updated-at-field': 'Updated at',
  'user-details-last-log-field': 'Last login',
  'user-details-roles-placeholder': 'This user has no roles',
  'user-details-permissions-placeholder': 'This user has no permissions',
  'user-details-activate-btn': 'Activate user',
  'user-details-deactivate-btn': 'Deactivate user',
  'user-details-deactivate-confirm-message': 'Are you sure you want to deactivate this user?',
  'user-details-activate-confirm-message': 'Are you sure you want to activate this user?',
  'form-create-user-email-placeholder': 'Specify user email',
  'form-create-user-name-placeholder': 'Specify user name',
  'form-create-user-permissions-placeholder': 'Select permissions',
  'form-create-user-roles-placeholder': 'Select roles',
};

export default users;
