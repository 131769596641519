import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';



const { Title } = Typography;
const PreviewInfo = ({ title, value }) => {
  return (
    <div>
      <Title className="hp-text-center" level={5}>{title}</Title>
      <div className="hp-d-flex hp-d-flex-justify-center hp-align-items-center">
        {value}
      </div>
    </div>
  );
};

PreviewInfo.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
};

export default PreviewInfo;
