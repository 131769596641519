import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import isObject from 'lodash/isObject';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import FormEditStructure from '../../forms/FormEditStructure/FormEditStructure';
import { useGetStructureQuery, useUpdateStructureMutation } from '../../api/structuresApiSlice';
import BreadCrumbs from '../../../../layout/components/breadcrumbs';
import { useGetLanguagesQuery } from '../../../Language/api/languagesApiSlice';
import urlPageStructures from '../../../../urls/urlPageStructures';



const PageStructuresDetails = () => {
  const { id } = useParams();
  const { data: details = {}, isLoading } = useGetStructureQuery(id);
  const navigate = useNavigate();

  const { data: languages = { data: [] } } = useGetLanguagesQuery({
    queryParams: 'limit=0&search=status:enabled',
  });

  const [ errorsBag, setErrorsBag ] = useState({});

  const [ update, { isLoading: isLoadingUpdate } ] = useUpdateStructureMutation();

  const handleSubmitForm = (data) => {
    update({ id, values: data })
      .unwrap()
      .then(() => {
        navigate(urlPageStructures());
      })
      .catch((error) => {
        if (isObject(error?.data?.errors)) {
          setErrorsBag(error.data.errors);
        }
      });
  };

  return (
    <>
      <Row gutter={32}>
        <Col className="hp-mb-32" span={24}>
          <BreadCrumbs
            breadCrumbParent={<Link to={urlPageStructures()}><IntlMessages id='ui-general-structures' /></Link>}
            breadCrumbActive={<IntlMessages id='structures-breadcrumbs-item' />}
          />
        </Col>
      </Row>

      <Row gutter={[ 16, 16 ]}>

        <FormEditStructure
          initialValues={details}
          languages={languages.languages}
          formErrors={errorsBag}
          isLoading={isLoadingUpdate || isLoading}
          onSubmit={handleSubmitForm}
          isSubmitting={isLoadingUpdate}
        />
      </Row>
    </>
  );
};

export default PageStructuresDetails;
