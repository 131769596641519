import React from 'react';
import { Form, FormItem, Input, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'antd';
import { useIntl } from 'react-intl';
import Yup from '../../../../vendor/yup';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { HasRights } from '../../../../components/has-rights/HasRights';
import { Permissions } from '../../../../const/permissions';



const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Min 2 symbols required')
    .max(20, 'Max 20 symbols required')
    .required('Name field is required'),
  display_name: Yup.string()
    .min(1, 'Min 1 symbol required')
    .max(100, 'Max 100 symbols required')
    .nullable()
    .required('Display name field is required'),
  description: Yup.string()
    .min(1, 'Min 1 symbol required')
    .max(191, 'Max 191 symbol required')
    .nullable()
    .required('Description field is required'),
});

const FormCreateEditRole = ({
  onSubmit,
  initialValues,
  isSubmitting,
  formErrors,
  handleSetFormEdit,
  isEditDisabled,
  isRoleEdit,
  onCancel,
}) => {
  const intl = useIntl();

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      isSubmitting={isSubmitting}
      initialValues={initialValues}
      initialErrors={formErrors}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { isValid, setFieldValue, values, dirty, resetForm } = props;

        return (
          <Form layout="vertical" style={{ width: '100%' }}>
            <FormItem
              className='ant-form-item-col'
              name="name"
              label={<IntlMessages id="form-user-roles-name-field" />}
              required
            >
              <Input
                name='name'
                placeholder={intl.formatMessage({ id: 'form-user-role-name-placeholder' })}
                disabled={isEditDisabled || values.is_secure}
                onChange={(event) => {
                  setFieldValue('name', event.target.value.replace(/\s/g, ''));
                }}
              />
            </FormItem>

            <FormItem
              className='ant-form-item-col'
              name="display_name"
              label={<IntlMessages id="form-user-roles-display-name-field" />}
              required
            >
              <Input
                name='display_name'
                disabled={isEditDisabled}
                placeholder={intl.formatMessage({ id: 'form-user-role-display-name-placeholder' })}
              />
            </FormItem>

            <FormItem
              className='ant-form-item-col'
              name="description"
              label={<IntlMessages id="form-user-roles-description-field" />}
              required
            >
              <Input.TextArea
                disabled={isEditDisabled}
                name='description'
                placeholder={intl.formatMessage({ id: 'form-user-roles-description-placeholder' })}
                rows={5}
                showCount
                maxLength={191}
              />
            </FormItem>

            {HasRights([ Permissions.ADMINISTRATION.ROLES.ROLE_DETAILS.EDIT_ROLE_DETAILS ]) && (
              <Row gutter={[ 16, 16 ]} justify='center'>
                <Col>
                  {isRoleEdit ? (
                    <Button
                      type='secondary'
                      onClick={() => {
                        resetForm();
                        handleSetFormEdit(!isEditDisabled);
                      }}
                    >
                      <IntlMessages id={isEditDisabled ? 'ui-general-edit' : 'ui-general-cancel'} />
                    </Button>
                  ) : (
                    <Button
                      type='secondary'
                      onClick={onCancel}
                    >
                      <IntlMessages id='ui-general-cancel' />
                    </Button>
                  )}
                </Col>

                {!isEditDisabled && (
                  <Col>
                    <SubmitButton
                      loading={isSubmitting}
                      disabled={!isValid || !dirty}
                    >
                      <IntlMessages id='ui-general-submit' />
                    </SubmitButton>
                  </Col>
                )}
              </Row>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

FormCreateEditRole.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  initialValues: PropTypes.shape({}).isRequired,
  formErrors: PropTypes.object,
  values: PropTypes.shape({
    is_secure: PropTypes.bool.isRequired,
  }),
  isSubmitting: PropTypes.bool.isRequired,
  handleSetFormEdit: PropTypes.func,
  isEditDisabled: PropTypes.bool,
  isRoleEdit: PropTypes.bool,
};

FormCreateEditRole.defaultProps = {
  formErrors: {},
  isEditDisabled: false,
  isRoleEdit: false,
  handleSetFormEdit: () => {},
  onCancel: () => {},
};

export default FormCreateEditRole;
