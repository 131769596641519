import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import Yup from '../../../../vendor/yup';
import { targetValidation } from '../../../../const/targetValidation';
import { DATE_FORMAT } from '../../../../const/system';



export const mkValidationSchema = (
  featureConfig,
  unavailableDates = [],
  dynamicData = {},
) => Yup.lazy((values) => Yup.object().shape({
  title: Yup.string()
    .min(3, 'Min 3 symbols required')
    .max(2000, 'Max 2000 symbols required'),
  description: Yup.string()
    .min(1, 'Min 1 symbol required')
    .max(1000, 'Max 1000 symbols required'),
  template_id: Yup.string().required('Required to specify template'),
  released_at: Yup.string().required('Required to specify released at date')
    .test(
      'test_day_in_the_past',
      'Released at date must be same or after than yesterday',
      (value) => {
        return !moment(value).isBefore(moment(new Date())
          .subtract(1, 'days').startOf('day')
          .utc(false));
      },
    )
    .test(
      'test_date_after',
      'Released at date must be same or before than released to',
      (value) => {
        if (!isEmpty(values?.released_to) && !isEmpty(value)) {
          return moment(values?.released_to).isSameOrAfter(value);
        }
        return true;
      },
    )
    .test(
      'test_date_available',
      'This date is already taken',
      (value) => {
        return !(!isEmpty(value) && unavailableDates.includes(moment(value).format(DATE_FORMAT)));
      },
    ),
  ...featureConfig?.config?.release_range && {
    released_to: Yup.string().required('Required to specify released to date')
      .test(
        'test_day_in_the_past',
        'Released to date must be same or after than yesterday',
        (value) => {
          return !moment(value).isBefore(moment(new Date())
            .subtract(1, 'days').startOf('day')
            .utc(false));
        },
      )
      .test(
        'test_date_before',
        'Released to date must be same or after than released at',
        (value) => {
          if (!isEmpty(values?.released_at) && !isEmpty(value)) {
            return moment(values?.released_at).isSameOrBefore(value);
          }
          return true;
        },
      ),
  },
  target: targetValidation,
  ...dynamicData,
}));
