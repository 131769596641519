import React from 'react';
import { Form, FormItem, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Button, Col, Row, Upload, Typography, Spin } from 'antd';
import { RiUploadCloud2Line } from 'react-icons/ri';
import { useIntl } from 'react-intl';
import ImgCrop from 'antd-img-crop';
import Yup from '../../../../vendor/yup';
import { FILE_SIZE, SUPPORTED_FORMATS, SUPPORTED_TYPES } from './FormUploadAvatar.const';
import { LOADING_TIP_PLACEHOLDER } from '../../../../const/system';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';



const { Text } = Typography;

const validationSchema = Yup.object().shape({
  list: Yup
    .mixed()
    .required('A file is required')
    .test(
      'fileSize',
      'File size is too large and should be less than 400Kb',
      (value) => {
        if (value.length) {
          return value && value[0]?.size <= FILE_SIZE;
        }

        return true;
      },
    ),
});


const FormUploadAvatar = ({
  onSubmit,
  onCancel,
  isSubmitting,
}) => {
  const intl = useIntl();

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      isSubmitting={isSubmitting}
      initialValues={{
        avatar: null,
        list: [],
      }}
      onSubmit={async (values, { resetForm, setErrors }) => {
        await onSubmit(values);
        resetForm();
        setErrors({});
      }}
    >
      {({ values, errors, isValid, dirty, setFieldValue, setErrors, resetForm }) => {
        return (
          <Form layout="vertical" style={{ width: '100%' }}>
            <Spin spinning={isSubmitting} tip={LOADING_TIP_PLACEHOLDER}>
              <FormItem
                label={intl.formatMessage({ id: 'form-upload-user-avatar-label' })}
                name='avatar'
                valuePropName="avatar"
                required
              >
                <ImgCrop
                  showGrid
                  modalTitle={<IntlMessages id='form-upload-user-avatar-edit-title' />}
                  modalOk={<IntlMessages id='ui-general-ok' />}
                  modalCancel={<IntlMessages id='ui-general-cancel' />}
                >
                  <Upload.Dragger
                    name="avatar"
                    accept={SUPPORTED_FORMATS}
                    type={SUPPORTED_TYPES}
                    multiple={false}
                    maxCount={1}
                    listType="picture"
                    disabled={isSubmitting}
                    fileList={values.list}
                    onChange={(event) => {
                      setFieldValue('list', event.fileList);
                    }}
                    beforeUpload={(file) => {
                      setFieldValue('avatar', file);
                      return false;
                    }}
                    onRemove={async () => {
                      await setFieldValue('list', []);
                      await setFieldValue('avatar', null);
                      setErrors({});
                    }}
                  >
                    <p className="ant-upload-drag-icon">
                      <RiUploadCloud2Line className="remix-icon" />
                    </p>

                    <p className="ant-upload-text">
                      <IntlMessages id="form-upload-user-avatar-description" />
                    </p>
                  </Upload.Dragger>
                </ImgCrop>
                <div className="hp-mt-4">
                  <Text type="danger">{errors.list ? errors.list : null}</Text>
                </div>
              </FormItem>

              <Row gutter={[ 16, 16 ]} justify='center'>
                <Col>
                  <Button
                    type='secondary'
                    onClick={async () => {
                      onCancel();
                      await setFieldValue('avatar', null);
                      await setFieldValue('list', []);
                      resetForm();
                      setErrors({});
                    }}
                  >
                    <IntlMessages id="ui-general-cancel" />
                  </Button>
                </Col>

                <Col>
                  <SubmitButton
                    loading={isSubmitting}
                    disabled={!isValid || !dirty}
                  >
                    <IntlMessages id="ui-general-submit" />
                  </SubmitButton>
                </Col>
              </Row>
            </Spin>
          </Form>
        );
      }}
    </Formik>
  );
};

FormUploadAvatar.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default FormUploadAvatar;
