const priority = {
  'news-priority-form-date-label': 'Date',
  'news-priority-filter-title': 'Select news by date',
  'news-priority-title': 'Set correct order for news',
  'news-priority-updated-successfully': 'News priority updated',
  'news-priority-updated-failed': 'News priority update failed',
  'news-priority-empty': 'There is no any news for this date.',
};

export default priority;
