import React, { useCallback, useState } from 'react';
import { Card, Divider, Spin, Tag, Tooltip } from 'antd';
import { PiHandTap } from 'react-icons/pi';
import { HiMiniPaintBrush } from 'react-icons/hi2';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import capitalize from 'lodash/capitalize';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import mapImageTypeToColor from '../../../../lib/mapImageTypeToColor';
import ImageBadge from '../ImageBadge';
import appColors from '../../../../const/colors';
import ContentCardTags from '../ContentCardTags';
import BeeLink from '../BeeLink';
import { EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';



const ContentCardDetails = ({
  intl,
  data,
  isMainCategory,
  actions,
  showContour,
  handleClick,
  lazyLoadStyles,
  minCardHeight = '',
}) => {
  const [ isLoading, setLoading ] = useState(true);

  return (
    <Card
      style={{ minHeight: minCardHeight }}
      className={`content-card content-image ${isMainCategory ? 'content-card__main-category' : ''}`}
      actions={actions}
      cover={
        <div className="hp-position-relative">
          <Spin spinning={isLoading}>
            <LazyLoadImage
              onLoad={() => {
                setLoading(false);
              }}
              style={lazyLoadStyles}
              effect='blur'
              alt="picture preview"
              src={data[showContour ? 'icon' : 'preview'].data.path}
              onClick={handleClick}
            />
          </Spin>
          <ImageBadge image={data} />
        </div>
      }
    >
      <div className="hp-d-flex content-card__tags">
        <Tag color={appColors.lightIndigo}>
          {`ID: ${data?.id ?? EMPTY_VALUE_PLACEHOLDER}`}
        </Tag>
        <Tag className="hp-mr-0" color={mapImageTypeToColor(data.source.data.type_content)}>
          {capitalize(data.source.data.type_content)}
        </Tag>
      </div>
      <div className="hp-d-flex content-card__metadata">
        <BeeLink
          link={data.source.data.link}
          title={data.source.data.source_item_id}
        />
        <div className="hp-d-flex hp-align-items-center">
          <Tooltip placement="top" title={<IntlMessages id="content-image-taps" />}>
            <span className="content-stat-info">
              <PiHandTap className='hp-mr-4' />
              {data?.data?.data[0]?.taps ?? EMPTY_VALUE_PLACEHOLDER}
            </span>
          </Tooltip>
          /
          <Tooltip placement="top" title={<IntlMessages id="content-image-colors-amount" />}>
            <span className="content-stat-info">
              <HiMiniPaintBrush className='hp-mr-4' />
              {data?.data?.data[0]?.colors ?? EMPTY_VALUE_PLACEHOLDER}
            </span>
          </Tooltip>
        </div>
      </div>

      <ContentCardTags items={data.categories.data.map((item) => item.name)} />
      <ContentCardTags
        items={data.labels ?? []}
        color={appColors.brightCyan}
      />

      {data.release_date && (
        <>
          <Divider />
          <div className="bold-sm-text">
            {`${intl.formatMessage({ id: 'ui-general-released-at' })}: ${data.release_date}`}
          </div>
        </>
      )}
    </Card>
  );
};

const ContentCard = ({
  data,
  showContour = false,
  onClick,
  actions,
  useTooltip = false,
  allowImageClick = false,
  minCardHeight = '',
}) => {
  const intl = useIntl();
  const { categoryId } = useParams();

  const isMainCategory = data?.categories.data?.find((category) => category.id === Number.parseInt(categoryId, 10))?.is_main;
  const lazyLoadStyles = { minHeight: '230px', objectFit: 'cover', cursor: `${allowImageClick ? 'pointer' : 'default'}` };

  const handleClick = useCallback(() => {
    onClick(data);
  }, [ onClick ]);

  return (
    <div>
      {useTooltip ? (
        <Tooltip key="download" placement="top" title={<IntlMessages id='ui-general-attach-image-click' />}>
          <ContentCardDetails
            intl={intl}
            data={data}
            isMainCategory={isMainCategory}
            actions={actions}
            showContour={showContour}
            handleClick={handleClick}
            lazyLoadStyles={lazyLoadStyles}
            minCardHeight={minCardHeight}
          />
        </Tooltip>
      ) : (
        <ContentCardDetails
          intl={intl}
          data={data}
          isMainCategory={isMainCategory}
          actions={actions}
          showContour={showContour}
          handleClick={handleClick}
          lazyLoadStyles={lazyLoadStyles}
          minCardHeight={minCardHeight}
        />
      )}
    </div>
  );
};

ContentCardDetails.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.array,
  showContour: PropTypes.bool,
  lazyLoadStyles: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  useTooltip: PropTypes.bool,
  allowImageClick: PropTypes.bool,
  isMainCategory: PropTypes.number,
  minCardHeight: PropTypes.string,
};

ContentCard.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.array,
  showContour: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  useTooltip: PropTypes.bool,
  allowImageClick: PropTypes.bool,
  minCardHeight: PropTypes.string,
};

export default ContentCard;
