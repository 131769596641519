const languages = {
  'languages-breadcrumbs': 'Языки',
  'languages-search-by': 'Поиск по имени или коду...',

  'languages-code-field': 'Код',
  'languages-table-delete-btn-confirm-message': 'Вы действительно хотите удалить язык?',

  'form-languages-name-placeholder': 'English',
  'form-languages-code-placeholder': 'ENG',
};

export default languages;
