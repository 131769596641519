import React, { useCallback, useState } from 'react';
import { Formik } from 'formik';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Col, Row } from 'antd';
import { Form, FormItem, Input, Select, ResetButton, SubmitButton } from 'formik-antd';
import { PiWarningCircleLight } from 'react-icons/pi';
import { LuMoveVertical } from 'react-icons/lu';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Yup from '../../../../vendor/yup';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { useGetCategoriesQuery } from '../../../Content/api/categoriesApiSlice';
import { destinationOptions, initOrder, themeOptions } from './FormCreateDeeplink.const';
import { atLeastOneFieldIsFilled, matchUrlRegexp, prepareDeeplink, sortDeeplinkFields } from '../../utils/deeplink';
import { useGetPacksQuery } from '../../../Content/api/packsApiSlice';
import { useGetImagesQuery } from '../../../Content/api/imagesApiSlice';
import useDebounce from '../../../../app/hooks/useDebounce';
import makeSelectFilterOption from '../../../../lib/makeSelectFilterOption';
import getPacksOptions from '../../../../lib/getPacksOptions';
import getImagesOptions from '../../../../lib/getImagesOptions';
import getBonusesOptions from '../../../../lib/getBonusesOptions';
import { useGetOldBonusesQuery } from '../../../Content/api/bonusesApiSlice';
import useCategoriesOptions from '../../../../app/hooks/useCategoriesOptions';



const validationSchema = Yup.object().shape({
  code: Yup.number().nullable(),
  count: Yup.number().min(1).nullable(),
  picture_open: Yup.number().test(
    'pictureOpen',
    'At least one field should be filled',
    atLeastOneFieldIsFilled,
  ),
  external_link: Yup.string().test(
    'externalLink',
    'External link can be URL only',
    matchUrlRegexp,
  ),
});

const FormCreateDeeplink = ({
  onSubmit,
  onCancel,
  isSubmitting,
}) => {
  const intl = useIntl();
  const [ items, setItems ] = useState(initOrder);
  const [ search, setSearch ] = useState('');
  const searchQuery = useDebounce(search.trim(), 300);

  const { data: categories = { data: [] }, isFetching: isCategoriesLoading } = useGetCategoriesQuery({
    queryParams: 'limit=0',
  });

  const { data: images = { data: [] }, isLoading: isImagesLoading } = useGetImagesQuery({
    queryParams: searchQuery ? `limit=0&search=partial_id:${searchQuery}` : 'limit=100&filter:id',
  });

  const { data: packs = { data: [] }, isFetching: isPacksLoading } = useGetPacksQuery({
    queryParams: 'limit=0&filter=id;label',
  });

  const { data: bonuses = [], isFetching: isBonusesLoading } = useGetOldBonusesQuery();

  const categoryOptions = useCategoriesOptions(categories?.data);

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const initialOrder = [ ...items ];
    const [ reorderedItem ] = initialOrder.splice(result.source.index, 1);

    initialOrder.splice(result.destination.index, 0, reorderedItem);

    setItems(initialOrder);
  };

  const onSelect = (event, field, setFieldValue) => {
    setFieldValue(field, event);
  };

  const onSearch = useCallback((searchText) => {
    setSearch(searchText);
  }, [ search ]);

  const getFormElement = (id, values, categories, setFieldValue) => {
    let element = null;

    switch (id) {
      case '1':
        element = (
          <FormItem
            label={intl.formatMessage({ id: 'deeplinks-form-image-label' })}
            name='picture_open'
          >
            <div className="hp-d-flex hp-align-items-center">
              <Select
                showSearch
                loading={isImagesLoading}
                value={values?.dictionary ?? null}
                filterOption={false}
                name='dictionary'
                allowClear
                options={getImagesOptions(images)}
                onSelect={(event) => onSelect(event, 'picture_open', setFieldValue)}
                onSearch={onSearch}
                onChange={(event) => onSelect(event, 'picture_open', setFieldValue)}
                placeholder={intl.formatMessage({ id: 'deeplinks-form-image-placeholder' })}
              />
              <LuMoveVertical className="hp-mx-8" />
            </div>

          </FormItem>
        );
        break;
      case '2':
        element = (
          <FormItem
            label={intl.formatMessage({ id: 'deeplinks-form-pack-label' })}
            name='pack_open'
          >
            <div className="hp-d-flex hp-align-items-center">
              <Select
                allowClear
                loading={isPacksLoading}
                options={getPacksOptions(packs)}
                showSearch
                optionFilterProp="children"
                value={values?.pack_open || null}
                onSelect={(value) => {
                  setFieldValue('pack_open', value);
                }}
                onClear={() => {
                  setFieldValue('pack_open', null);
                }}
                filterOption={(input, option) => makeSelectFilterOption(option?.label ?? '', input)}
                name="pack_open"
                placeholder={intl.formatMessage({ id: 'deeplinks-form-pack-placeholder' })}
              />
              <LuMoveVertical className="hp-mx-8" />
            </div>
          </FormItem>
        );
        break;
      case '3':
        element = (
          <FormItem
            label={intl.formatMessage({ id: 'deeplinks-form-category-label' })}
            name='category_open'
          >
            <div className="hp-d-flex hp-align-items-center">
              <Select
                allowClear
                options={categoryOptions}
                filterOption={(input, option) => makeSelectFilterOption(option?.label ?? '', input)}
                name="category_open"
                showSearch
                optionFilterProp="children"
                value={values?.category_open || null}
                onSelect={(value) => {
                  setFieldValue('category_open', value);
                }}
                onClear={() => {
                  setFieldValue('category_open', null);
                }}
                placeholder={intl.formatMessage({ id: 'deeplinks-form-category-placeholder' })}
                loading={isCategoriesLoading}
              />
              <LuMoveVertical className="hp-mx-8" />
            </div>
          </FormItem>
        );
        break;
      case '4':
        element = (
          <FormItem
            label={intl.formatMessage({ id: 'deeplinks-form-theme-label' })}
            name='theme'
          >
            <div className="hp-d-flex hp-align-items-center">
              <Select
                allowClear
                name="theme"
                options={themeOptions}
                placeholder={intl.formatMessage({ id: 'deeplinks-form-theme-placeholder' })}
              />
              <LuMoveVertical className="hp-mx-8" />
            </div>
          </FormItem>
        );
        break;
      case '5':
        element = (
          <FormItem
            label={intl.formatMessage({ id: 'deeplinks-form-bonus-code-label' })}
            name='bonus_code'
          >
            <div className="hp-d-flex hp-align-items-center">
              <Select
                allowClear
                value={values?.bonus_code || null}
                loading={isBonusesLoading}
                name="bonus_code"
                options={getBonusesOptions(bonuses)}
                showSearch
                optionFilterProp="children"
                onSelect={(value) => {
                  setFieldValue('bonus_code', value);
                }}
                onClear={() => {
                  setFieldValue('bonus_code', null);
                }}
                filterOption={(input, option) => makeSelectFilterOption(option?.label ?? '', input)}
                placeholder={intl.formatMessage({ id: 'deeplinks-form-bonus-code-placeholder' })}
              />
              <LuMoveVertical className="hp-mx-8" />
            </div>
          </FormItem>
        );
        break;
      case '6':
        element = (
          <FormItem
            label={intl.formatMessage({ id: 'deeplinks-form-destination-label' })}
            name='settings_open'
          >
            <div className="hp-d-flex hp-align-items-center">
              <Select
                allowClear
                name="settings_open"
                options={destinationOptions}
                placeholder={intl.formatMessage({ id: 'deeplinks-form-destination-placeholder' })}
              />
              <LuMoveVertical className="hp-mx-8" />
            </div>
          </FormItem>
        );
        break;
      case '7':
        element = (
          <FormItem
            label={intl.formatMessage({ id: 'deeplinks-form-external-destination-label' })}
            name='external_link'
          >
            <div className="hp-d-flex hp-align-items-center">
              <Input
                name="external_link"
                placeholder={intl.formatMessage({ id: 'deeplinks-form-external-destination-placeholder' })}
              />
              <LuMoveVertical className="hp-mx-8" />
            </div>
          </FormItem>
        );
        break;
      default:
        element = null;
    }

    return element;
  };

  return (
    <Formik
      enableReinitialize
      isSubmitting
      initialValues={{
        count: 9999999,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        const data = sortDeeplinkFields(values, items);

        await onSubmit(prepareDeeplink(data));
        resetForm();
        setItems(initOrder);
      }}
      onReset={async (values) => {
        onCancel(values);
        setItems(initOrder);
      }}
    >
      {({ isValid, dirty, values, setFieldValue }) => {
        return (
          <Form
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
          >
            <DragDropContext
              onDragEnd={handleDragEnd}
            >
              <Droppable droppableId="items">
                {(provided) => {
                  return (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {items.map((item, index) => {
                        return (
                          <Draggable draggableId={item} index={index} key={item}>
                            {(provided) => {
                              return (
                              // eslint-disable-next-line react/jsx-props-no-spreading
                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                  {getFormElement(item, values, categories, setFieldValue)}
                                </div>
                              );
                            }}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  );
                }}
              </Droppable>
            </DragDropContext>

            <FormItem
              label={intl.formatMessage({ id: 'deeplinks-form-open-limit-label' })}
              name='count'
            >
              <Input name="count" />
            </FormItem>
            <FormItem
              label={intl.formatMessage({ id: 'deeplinks-form-code-label' })}
              name='custom_code'
            >
              <Input name="custom_code" placeholder={intl.formatMessage({ id: 'deeplinks-form-custom-code-placeholder' })} />
            </FormItem>

            <Col span={24}>
              <div className="hp-d-flex hp-align-items-center hp-text-color-primary-1 hp-mb-8">
                <PiWarningCircleLight size={20} className="hp-mr-4" />
                <p className="hp-mb-0"><IntlMessages id="deeplinks-form-warning" /></p>
              </div>
            </Col>

            <Row gutter={[ 16, 16 ]} justify='end'>
              <Col>
                <ResetButton disabled={false}>
                  <IntlMessages id='ui-general-reset' />
                </ResetButton>
              </Col>

              <Col>
                <SubmitButton
                  type="primary"
                  loading={isSubmitting}
                  disabled={!isValid || !dirty}
                >
                  <IntlMessages id="ui-general-save" />
                </SubmitButton>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

FormCreateDeeplink.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default FormCreateDeeplink;
