import React from 'react';
import { Popconfirm, Row, Tag, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { PiPencil } from 'react-icons/pi';
import { RiErrorWarningLine } from 'react-icons/ri';
import { Delete } from 'react-iconly';
import { Eye } from 'iconsax-react';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import getFormattedDate from '../../../../lib/getFormattedDate';
import { HasRights } from '../../../../components/has-rights/HasRights';
import { Permissions } from '../../../../const/permissions';
import urlPageCategoryDetails from '../../../../urls/urlPageCategoryDetails';



export const SOURCE_CATEGORIES = 'categories';
export const SEARCH_VALUES = [ 'name' ];
export const SEARCH_FILTER_VALUES = [ 'client_id', 'name', 'status', 'created_from', 'created_to' ];

export const getCategoriesTableColumns = (handleOpenSidebarOnEdit, handleDelete) => [
  {
    title: <IntlMessages id="ui-general-id" />,
    dataIndex: 'client_id',
    align: 'center',
    width: '120px',
    sorter: true,
  },
  {
    title: <IntlMessages id="ui-general-priority" />,
    dataIndex: 'priority',
    align: 'center',
    width: '120px',
    sorter: true,
  },
  {
    title: <IntlMessages id="ui-general-status" />,
    dataIndex: 'status',
    align: 'center',
    width: '140px',
    sorter: true,
    render: (value) => (<Tag color={value ? 'success' : 'error'}><IntlMessages id={value ? 'ui-general-on' : 'ui-general-off'} /></Tag>),
  },
  {
    title: <IntlMessages id="ui-general-name" />,
    dataIndex: 'name',
    align: 'center',
    sorter: true,
    render: (value) => <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{value}</div>,
  },
  {
    title: <IntlMessages id="ui-general-created-at" />,
    dataIndex: 'created_at',
    width: '150px',
    align: 'center',
    sorter: true,
    render: (value) => getFormattedDate(value),
  },
  {
    title: <IntlMessages id="ui-general-action" />,
    key: 'action',
    width: '130px',
    align: 'center',
    render: (item) => {
      return (
        <Row className="da-h-100" align="middle" justify="center">
          {HasRights([ Permissions.CONTENT.CATEGORIES.VIEW ], true) && (
            <Tooltip placement="top" title={<IntlMessages id="ui-general-view" />}>
              <div className="hp-text-right">
                <Link to={urlPageCategoryDetails({ categoryId: item.id })}>
                  <Eye
                    size={20}
                    className="hp-cursor-pointer hp-transition hp-hover-text-color-warning-1 hp-text-color-black-80 hp-m-4"
                  />
                </Link>
              </div>
            </Tooltip>
          )}

          {HasRights([ Permissions.CONTENT.CATEGORIES.EDIT ]) && (
            <Tooltip placement="top" title={<IntlMessages id="ui-general-edit" />}>
              <div className="hp-text-right">
                <PiPencil
                  onClick={() => handleOpenSidebarOnEdit(item.id)}
                  size={20}
                  className="hp-cursor-pointer hp-transition hp-hover-text-color-warning-1 hp-text-color-black-80 hp-m-4"
                />
              </div>
            </Tooltip>
          )}

          {HasRights([ Permissions.CONTENT.CATEGORIES.DELETE ]) && item.is_removable && (
            <Popconfirm
              title={<IntlMessages id="dictionaries-table-delete-btn-confirm-message" />}
              placement="top"
              onConfirm={() => {
                handleDelete(item.id);
              }}
              okText={<IntlMessages id="ui-general-yes" />}
              cancelText={<IntlMessages id="ui-general-no" />}
              icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
              okButtonProps={{ danger: true }}
            >
              <Tooltip placement="top" title={<IntlMessages id="ui-general-delete" />}>
                <div className="hp-text-right">
                  <Delete
                    size={20}
                    className="hp-cursor-pointer hp-transition hp-hover-text-color-danger-1 hp-text-color-black-80 hp-m-4"
                  />
                </div>
              </Tooltip>
            </Popconfirm>
          )}
        </Row>
      );
    },
  },
];
