import { Popconfirm, Row, Tooltip } from 'antd';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import dayjs from 'dayjs';
import { Eye } from 'iconsax-react';
import { PiPencil } from 'react-icons/pi';
import { RiErrorWarningLine } from 'react-icons/ri';
import { Delete } from 'react-iconly';
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { DATE_TIME_FORMAT, EMPTY_IMAGE_PLACEHOLDER } from '../../../../const/system';
import { Permissions } from '../../../../const/permissions';
import getFormattedDate from '../../../../lib/getFormattedDate';
import { PrivateComponent } from '../../../../components/has-rights/HasRights';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import PublishedTag from '../../../../components/PublishedTag';



export const NEWS_DICT_STRUCTURE = 'news';

export const getNewsTableColumns = (
  handleView,
  handleEdit,
  handleDelete,
  handleImageCardClick,
  tableSize,
  canViewTemplateColumn,
) => {
  const templateColumn =   {
    title: <IntlMessages id="ui-general-template" />,
    align: 'center',
    dataIndex: 'template',
    sorter: true,
    width: tableSize.width * 0.15,
    render: (template) => template?.data?.title,
  };

  const columns = [
    {
      title: <IntlMessages id="ui-general-id" />,
      dataIndex: 'id',
      align: 'center',
      width: tableSize.width * 0.08,
      sorter: true,
    },
    {
      title: <IntlMessages id="ui-general-preview" />,
      dataIndex: 'preview',
      align: 'center',
      width: tableSize.width * 0.14,
      render: (value, item) => {
        const timestamp = Date.now();

        return (
          <LazyLoadImage
            onClick={() => {
              if (!isEmpty(item?.preview)) {
                handleImageCardClick(item.preview);
              }
            }}
            style={!isEmpty(item?.preview) ? { cursor: 'pointer' } : {}}
            effect='blur'
            alt="news preview"
            src={value ? `${value}?time=${timestamp}` : EMPTY_IMAGE_PLACEHOLDER}
          />
        );
      },
    },
    {
      title: <IntlMessages id="ui-general-title" />,
      dataIndex: 'title',
      align: 'center',
      sorter: true,
      width: tableSize.width * 0.16,
      render: (item, value) => {
        const rows = item.split('\n');

        return (
          rows.map((content, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index}>
              {content}
              &nbsp;
              {index === 0 && value?.is_published && dayjs(value.updated_at).isAfter(dayjs(value.published_at)) && (
                <Tooltip placement="right" title={<IntlMessages id="ui-general-unpublished-tooltip-title" />}>
                  &nbsp;
                  <ExclamationCircleOutlined className="hp-text-color-warning-1" />
                </Tooltip>
              )}
            </div>
          ))
        );
      },
    },
    {
      title: <IntlMessages id="ui-general-published" />,
      dataIndex: 'is_published',
      align: 'center',
      sorter: true,
      width: tableSize.width * 0.14,
      render: (value, item) => <PublishedTag published={Boolean(value)} publishedDate={item.published_at} />,
    },
    {
      title: <IntlMessages id="ui-general-released-at" />,
      dataIndex: 'released_at',
      align: 'center',
      sorter: true,
      width: tableSize.width * 0.115,
      render: (value) => getFormattedDate(value, DATE_TIME_FORMAT),
    },
    {
      title: <IntlMessages id="ui-general-released-to" />,
      dataIndex: 'released_to',
      align: 'center',
      sorter: true,
      width: tableSize.width * 0.115,
      render: (value) => getFormattedDate(value, DATE_TIME_FORMAT),
    },
    {
      title: <IntlMessages id="ui-general-action" />,
      key: 'action',
      align: 'center',
      width: tableSize.width * 0.11,
      render: (item) => {
        return (
          <Row className="da-h-100" align="middle" justify="center">
            <PrivateComponent allowedPermissions={[ Permissions.CONTENT.NEWS.FIND ]}>
              <Tooltip placement="top" title={<IntlMessages id="ui-general-view" />}>
                <div className="hp-text-right">
                  <Eye
                    onClick={() => {
                      handleView(item.id);
                    }}
                    size={20}
                    className="hp-cursor-pointer hp-transition hp-hover-text-color-primary-1 hp-text-color-black-80 hp-m-4"
                  />
                </div>
              </Tooltip>
            </PrivateComponent>
            <PrivateComponent allowedPermissions={[ Permissions.CONTENT.NEWS.EDIT ]}>
              <Tooltip placement="top" title={<IntlMessages id="ui-general-edit" />}>
                <div className="hp-text-right">
                  <PiPencil
                    onClick={() => {
                      handleEdit(item.id);
                    }}
                    size={20}
                    className="hp-cursor-pointer hp-transition hp-hover-text-color-warning-1 hp-text-color-black-80 hp-m-4"
                  />
                </div>
              </Tooltip>
            </PrivateComponent>
            <PrivateComponent allowedPermissions={[ Permissions.CONTENT.NEWS.DELETE ]}>
              {!item.is_published ? (
                <Popconfirm
                  title={<IntlMessages id="dynamic-news-table-delete-btn-confirm-message" />}
                  placement="top"
                  onConfirm={() => {
                    handleDelete(item.id);
                  }}
                  okText={<IntlMessages id="ui-general-yes" />}
                  cancelText={<IntlMessages id="ui-general-no" />}
                  icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
                  okButtonProps={{ danger: true }}
                >
                  <Tooltip placement="top" title={<IntlMessages id="ui-general-delete" />}>
                    <div className="hp-text-right">
                      <Delete
                        size={20}
                        className="hp-cursor-pointer hp-transition hp-hover-text-color-danger-1 hp-text-color-black-80 hp-m-4"
                      />
                    </div>
                  </Tooltip>
                </Popconfirm>
              ) : (
                <Tooltip placement="top" title={<IntlMessages id="dynamic-news-delete-notification" />}>
                  <div className="hp-text-right">
                    <Delete
                      style={{ opacity: 0.4 }}
                      disabled
                      size={20}
                      className="hp-cursor-pointer hp-transition hp-text-color-black-80 hp-m-4"
                    />
                  </div>
                </Tooltip>
              )}
            </PrivateComponent>
          </Row>
        );
      },
    },
  ];

  if (canViewTemplateColumn) {
    columns.splice(3, 0, templateColumn);
  }

  return columns;
};
