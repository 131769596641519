export const LOADING_TIP_PLACEHOLDER = 'Loading. Please wait...';
export const DATE_TIME_FORMAT = 'HH:mm, DD MMM YYYY';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const EMPTY_VALUE_PLACEHOLDER = '—';
export const EMPTY_IMAGE_PLACEHOLDER = '/img/no-photo-available.png';
export const EMPTY_IMAGE_PREVIEW = '/img/no-pictures.png';
export const DEFAULT_SIDEBAR_WIDTH = 600;

export const dateFormat = 'YYYY-MM-DD HH:mm';
export const requiredMessage = 'This field is a required';

export const targetPlatformOptions = [ {
  label: 'gp',
  value: 'gp',
}, {
  label: 'ios',
  value: 'ios',
}, {
  label: 'amazon',
  value: 'amazon',
} ];
