import React, { useEffect, useState } from 'react';
import isEmpty from "lodash/isEmpty";
import { EMPTY_VALUE_PLACEHOLDER } from "../../const/system";



const Colorpicker = ({
 name = '',
 defaultValue = '#ffffff',
 onChange,
 disabled = false,
 }) => {
  const [ color, setColor ] = useState(defaultValue);

  useEffect(() => {
    setColor(!isEmpty(defaultValue) ? defaultValue : '#ffffff');
  }, [ defaultValue ]);

  const handleColorChange = (event) => {
    setColor(event.target.value);
    onChange(event.target.value);
  };

  return (
    <div className="color-picker__wrapper">
      <input
        name={name}
        disabled={disabled}
        type="color"
        className="color-picker__input"
        id={name}
        value={color}
        onChange={handleColorChange}
      />
      <label
        htmlFor={name}
        className={`color-picker__display ${!disabled ? 'color-picker__display_pointer' : ''}`}
        style={{ backgroundColor: color, width: "32px" }}
      />
      <span className='color-picker__text'>{!isEmpty(color) ? color.toUpperCase() : EMPTY_VALUE_PLACEHOLDER}</span>
    </div>
  );
};


export default Colorpicker;
