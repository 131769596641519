import React from 'react';
import PropTypes from 'prop-types';
import { useApp } from '../../app/context/AppContext';
import Error403 from "../errors/403";


// Used for wrapping separate components
export const HasRights = (allowedPermissions, isMultipleAllowance) => {
  const { userPermissions } = useApp();

  if (allowedPermissions.length === 0) {
    return true;
  }

  return isMultipleAllowance ?
    allowedPermissions.every((permission) => userPermissions.includes(permission) === true) :
    userPermissions.some((permission) => allowedPermissions.includes(permission));
};

export const PrivateComponent = ({ allowedPermissions, children, isMultipleAllowance }) => {
  const isAllowed = HasRights(allowedPermissions, isMultipleAllowance);

  if (!isAllowed) {
    return null;
  }

  return <>{ children }</>;
};


// Used for wrapping on routes or whole pages
export const PrivateRoute = ({
  allowedPermissions = [],
  children = null,
  isMultipleAllowance = false,
}) => {
  const isAllowed = HasRights(allowedPermissions, isMultipleAllowance);

  return isAllowed ? children : (
    <Error403 />
  );
};


PrivateRoute.propTypes = {
  allowedPermissions: PropTypes.array,
  isMultipleAllowance: PropTypes.bool,
  isPagePermissions: PropTypes.bool,
  children: PropTypes.any,
};
