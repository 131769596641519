import React, { useState } from 'react';
import { Card, Col, Row, Spin } from 'antd';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import { DATE_FORMAT } from '../../../../const/system';
import BreadCrumbs from '../../../../layout/components/breadcrumbs';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import useCheckMobileScreen from '../../../../app/hooks/useCheckMobileScreen';
import getPriorityUpdateButtonTooltipMessage from '../../../../lib/getPriorityUpdateButtonTooltipMessage';
import PrioritiesTitle from '../../../../components/PrioritiesTitle';
import FeaturePriorityList from '../../components/FeaturePriorityList';
import PriorityFilter from '../../../../components/PriorityFilter';
import { hasRights, Permissions } from '../../../../const/permissions';
import { makeNotification, notificationTypes } from '../../../../lib/makeNotification';
import { useGetFeaturesPriorityQuery, useUpdateFeaturesPriorityMutation } from '../../api/featuresApiSlice';



const PageFeaturesPriority = () => {
  const intl = useIntl();
  const [ featuresOrder, setFeaturesOrder ] = useState([]);
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const searchFields = [ 'date' ];
  const isMobile = useCheckMobileScreen();

  const { data: featuresPriority = { data: [], pagination: {} }, isFetching } = useGetFeaturesPriorityQuery({
    queryParams: searchQueryParams.get('search') ? `search=${searchQueryParams.get('search')}&include=featured_source` : 'include=featured_source',
  });

  const [ updateFeaturesPriority, { isLoading: isUpdatingFeaturesPriority } ] = useUpdateFeaturesPriorityMutation();


  const applyFilter = (values) => {
    const queryParams = {};

    Array.from(searchQueryParams.entries())
      .forEach(([ key, value ]) => {
        queryParams[key] = key === 'page' ? 1 : value;
      });

    queryParams.search = Object.entries(values)
      .map(([ field, value ]) => {
        if (searchFields.includes(field)) {
          if ((isArray(value) && !value.length) || !value) {
            return '';
          }

          return isString(value) ? `${field}:${value.trim()}` : `${field}:${value}`;
        }
        return '';
      })
      .filter((item) => item !== '')
      .join(';');

    setFeaturesOrder([]);
    setSearchParams(queryParams);
  };

  const today = dayjs().format(DATE_FORMAT);
  const search = searchQueryParams.get('search');
  const date = search ? search.split(':')[1] : today;
  const hasNoOrderChanges = !featuresOrder.length;
  const isPrevPriority = dayjs(date).isBefore(dayjs().subtract(2, 'day'));

  const onFeaturesOrderChange = (order) => {
    setFeaturesOrder(order);
  };

  const submitFeaturesPriority = () => {
    const order = featuresOrder.map((item) => {
      const position = Number(item) - 1;

      return featuresPriority.data[position]?.id;
    });

    updateFeaturesPriority({ order, date })
      .unwrap()
      .then(() => {
        makeNotification(
          notificationTypes.success,
          intl.formatMessage({ id: 'ui-general-success' }),
          intl.formatMessage({ id: 'features-priority-updated-successfully' }),
        );
        setFeaturesOrder([]);
      })
      .catch((error) => {
        makeNotification(
          notificationTypes.error,
          intl.formatMessage({ id: 'ui-general-error' }),
          <>
            <p className="hp-mb-4">{intl.formatMessage({ id: 'features-priority-updated-failed' })}</p>
            {error?.data?.message && <p>{ error.data.message }</p>}
          </>,
        );
      });
  };

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs breadCrumbActive={<IntlMessages id='features-breadcrumbs-priority' />} />
      </Row>

      <Row gutter={[ 16, 16 ]} style={{ flexDirection: isMobile ? 'column-reverse' : 'row' }}>
        <Col sm={24} md={16}>
          <Card
            className="hp-border-color-black-40 hp-card-6"
            title={(
              <PrioritiesTitle
                isDisabled={hasNoOrderChanges || isPrevPriority}
                hasPermission={hasRights([ Permissions.FEATURED.FEATURES.PRIORITY.UPDATE ])}
                title={intl.formatMessage({ id: 'features-priority-title' })}
                onAction={submitFeaturesPriority}
                tooltipMessage={getPriorityUpdateButtonTooltipMessage(hasNoOrderChanges, isPrevPriority)}
              />
            )}
          >
            <Spin spinning={isFetching || isUpdatingFeaturesPriority} tip={intl.formatMessage({ id: 'ui-general-loading' })}>
              <FeaturePriorityList
                data={featuresPriority?.data}
                isLoading={isFetching || isUpdatingFeaturesPriority}
                selectedDate={date}
                onOrderChange={onFeaturesOrderChange}
              />
            </Spin>
          </Card>
        </Col>
        <Col sm={24} md={8}>
          <Card
            title={intl.formatMessage({ id: 'features-priority-filter-title' })}
            className="hp-border-color-black-40 hp-card-6"
          >
            <PriorityFilter
              onSubmit={applyFilter}
              initialValues={searchQueryParams.get('search') ? { date } : {}}
              onCancel={() => {}}
              isSubmitting={isFetching || isUpdatingFeaturesPriority}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};


export default PageFeaturesPriority;
