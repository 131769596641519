const imageTypes = {
  sharp: 'sharp',
  cargo: 'cargo',
  blend: 'blend',
  chop: 'chop',
  bee_chop: 'bee chop',
};

export {
  imageTypes,
};
