import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';



const PreviewImages = ({ icon, preview }) => {
  return (
    <>
      <Col xs={24} sm={24} md={7} lg={7} xl={7}>
        <img className="preview-image" alt="icon" src={icon} />
      </Col>
      <Col xs={24} sm={24} md={7} lg={7} xl={7}>
        <img className="preview-image" alt="preview" src={preview} />
      </Col>
    </>
  );
};

PreviewImages.propTypes = {
  icon: PropTypes.string.isRequired,
  preview: PropTypes.string.isRequired,
};

export default PreviewImages;
