import React from 'react';
import { Form, FormItem, Input, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { useIntl } from 'react-intl';
import Yup from '../../../../vendor/yup';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';



const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(4, 'Min 4 symbols required')
    .max(50, 'Max 50 symbols required')
    .matches(/^[a-zA-Z\s]+$/, 'Only alphabets are allowed for this field ')
    .required('Name field is required'),
  code: Yup.string()
    .matches(/^[a-zA-Z]+$/, 'Only alphabets are allowed for this field ')
    .min(3, 'Min 3 symbol required')
    .max(3, 'Max 3 symbols required')
    .nullable()
    .required('Code field is required'),
});

const FormCreateEditLanguage = ({
  onSubmit,
  initialValues,
  isSubmitting,
  formErrors,
}) => {
  const intl = useIntl();

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      isSubmitting={isSubmitting}
      initialValues={initialValues}
      initialErrors={formErrors}
      onSubmit={(values) => {
        onSubmit({ ...values, name: values.name.trim(), code: values.code.trim() });
      }}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { isValid, dirty } = props;

        return (
          <Form layout="vertical" style={{ width: '100%' }}>
            <FormItem
              className='ant-form-item-col'
              name="name"
              label={<IntlMessages id="ui-general-name" />}
              required
            >
              <Input
                name='name'
                placeholder={intl.formatMessage({ id: 'form-languages-name-placeholder' })}
              />
            </FormItem>

            <FormItem
              className='ant-form-item-col'
              name="code"
              label={<IntlMessages id="languages-code-field" />}
              required
            >
              <Input
                name='code'
                placeholder={intl.formatMessage({ id: 'form-languages-code-placeholder' })}
              />
            </FormItem>

            <Row gutter={[ 16, 16 ]} justify='center'>
              <Col>
                <SubmitButton
                  loading={isSubmitting}
                  disabled={!isValid || !dirty}
                >
                  <IntlMessages id='ui-general-submit' />
                </SubmitButton>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

FormCreateEditLanguage.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  formErrors: PropTypes.object,
  isSubmitting: PropTypes.bool.isRequired,
};

FormCreateEditLanguage.defaultProps = {
  formErrors: {},
};

export default FormCreateEditLanguage;
