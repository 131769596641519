const pushIcons = {
  'push-icons-breadcrumbs': 'Пуш иконки',
  'push-icons-preview-title': 'Просмотр пуш иконки',
  'push-icons-table-content-column': 'Контент',
  'push-icons-table-folder-column': 'Папка',
  'push-icons-table-message-column': 'Сообщение',
  'push-icons-table-link-column': 'Ссылка',
  'push-icons-create-title': 'Создать пуш иконку',
  'push-icons-update-title': 'Изменить пуш сообщение',
  'push-icons-search': 'Поиск по id',
  'push-icon-create-form-dropzone-label': 'Файл',
  'push-icon-create-form-dropzone-description': 'Нажмите или перетащите файл в эту область для загрузки. Имя файла должно быть i.(png | jpg | jpeg).',
  'push-icon-create-form-title-label': 'Заголовок',
  'push-icon-create-form-text-label': 'Текст',
  'push-icon-create-form-folder-label': 'Папка',
  'push-icons-delete-item-confirmation': 'Вы уверены, что хотите удалить пуш иконку?',
  'push-icons-deleting-failed': 'Ошибка при удалении пуш иконки',
  'push-icons-deleting-success': 'Пуш иконка удалена успешно',
  'push-icons-updating-success': 'Пуш сообщение обновлено успешно',
  'push-icons-updating-failed': 'Ошибка при обновлении пуш сообщения',
};

export default pushIcons;
