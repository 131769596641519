import React, { useEffect, useState } from 'react';
import { Card, Col, Modal, Row, Space, Table } from 'antd';
import { RiAddLine, RiCloseFill } from 'react-icons/ri';
import { PiDownload, PiUpload } from 'react-icons/pi';
import { Filter } from 'iconsax-react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import isString from 'lodash/isString';
import { useIntl } from 'react-intl';
import BreadCrumbs from '../../../../layout/components/breadcrumbs';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { PrivateComponent } from '../../../../components/has-rights/HasRights';
import { Permissions } from '../../../../const/permissions';
import urlPageDictionaryCreate from '../../../../urls/urlPageDictionaryCreate';
import urlPageDictionariesImport from '../../../../urls/urlPageDictionariesImport';
import urlPageFeatures from '../../../../urls/urlPageFeatures';
import { getDictionariesTableColumns } from './PageDictionaries.const';
import { makeNotification, notificationTypes } from '../../../../lib/makeNotification';
import { useGetLanguagesQuery } from '../../../Language/api/languagesApiSlice';
import basePagination from '../../../../const/pagination';
import ActionButton from '../../../../layout/components/action-button';
import useToggle from '../../../../app/hooks/useToggle';
import Sidebar from '../../../../components/sidebar/Sidebar';
import useQueryParams from '../../../../app/hooks/useQueryParams';
import FormFilterDictionary from '../../forms/FormFilterDictionary';
import getQueryParams from '../../../../lib/getQueryParams';
import PreviewDictionaryTranslation from '../../components/PreviewDictionaryTranslation/PreviewDictionaryTranslation';
import useDownload from '../../../../app/hooks/useDownload';
import FormGenerateTemplate from '../../forms/FormGenerateTemplate';
import getFeatureSourceTranslation from '../../../Featured/utils/getFeatureSourceTranslation';
import useResetFilter from '../../../../app/hooks/useResetFilter';
import {
  useDeleteDictionaryMutation,
  useGetDictionariesFilterQuery,
  useGetDictionariesQuery,
  useUpdateDictionaryMutation,
} from '../../api/dictionariesApiSlice';



const PageDictionaries = () => {
  const intl = useIntl();
  const [ filterOpen, toggleFilter ] = useToggle();
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const navigationSource = searchQueryParams.get('source') ?? null;
  const [ openModal, toggleModal ] = useToggle();
  const [ openExportTemplateModal, toggleExportTemplateModal ] = useToggle();
  const [ previewDictionary, setPreviewDictionary ] = useState({});
  const navigate = useNavigate();
  const searchFields = [ 'name', 'source', 'field', 'field_value', 'version', 'created_from', 'created_to' ];
  const {
    pagination,
    searchParams,
    handleChangeTableParams,
  } = useQueryParams({ searchFields, isFilter: true });
  const [ initFilterValues, setInitFilterValues ] = useState({});
  const { download: downloadTemplate, isLoading: isDownloadingTemplate } = useDownload({ method: 'POST' });
  const resetFilter = useResetFilter(searchQueryParams, setSearchParams, setInitFilterValues, navigationSource);

  const { data = { data: [], pagination: {} }, isLoading } = useGetDictionariesQuery({
    queryParams: `${searchParams.toString()}&searchJoin=and&include=structure,translations`,
  });
  const { data: filterData = { fields: [], sources: [], versions: [] } } = useGetDictionariesFilterQuery({
    queryParams: navigationSource ? `source=${navigationSource}` : '',
  });
  const { data: languages = { languages: [] } } = useGetLanguagesQuery({
    queryParams: 'limit=0',
  });

  const [ deleteDictionary, { isLoading: isLoadingDeleteDictionary } ] = useDeleteDictionaryMutation();
  const [ updateDictionary, { isLoading: isLoadingUpdateDictionary } ] = useUpdateDictionaryMutation();

  useEffect(() => {
    const queryParams = getQueryParams(window.location.search);

    if (queryParams.search) {
      const initValues = {};

      queryParams.search.split(';').forEach((item) => {
        const [ key, value ] = item.split(':');

        if (key === 'created_to') {
          initValues[key] = value.substring(0, 10);
        } else {
          initValues[key] = value;
        }
      });

      setInitFilterValues(initValues);
    }
  }, []);

  useEffect(() => {
    if (!searchParams.toString()) {
      setInitFilterValues({
        reset: true, // ugly hack
      });
    }
  }, [ searchParams ]);

  const handleDelete = (id) => {
    deleteDictionary(id)
      .unwrap()
      .then(() => {
        makeNotification(
          notificationTypes.success,
          intl.formatMessage({ id: 'ui-general-success' }),
          intl.formatMessage({ id: 'dictionaries-deleted-successfully' }),
        );
      })
      .catch((error) => {
        makeNotification(
          notificationTypes.error,
          intl.formatMessage({ id: 'ui-general-error' }),
          error?.data?.message && <p>{ error.data.message }</p>,
        );
      });
  };

  const handleView = (dictionary) => {
    setPreviewDictionary(dictionary);
    toggleModal();
  };

  const handleChangeStatus = (id, values) => {
    updateDictionary({ dictionaryId: id, values });
  };

  const applyFilter = (values) => {
    const queryParams = {};

    Array.from(searchQueryParams.entries())
      .forEach(([ key, value ]) => {
        queryParams[key] = key === 'page' ? 1 : value;
      });

    queryParams.search = Object.entries(values)
      .map(([ field, value ]) => {
        if (searchFields.includes(field)) {
          if (!value) {
            return '';
          }
          return isString(value) ? `${field}:${value.trim()}` : `${field}:${value}`;
        }
        return '';
      })
      .filter((item) => item !== '')
      .join(';');

    if (navigationSource && !queryParams.search.includes(`source:${navigationSource}`)) {
      queryParams.search += `;source:${navigationSource}`;
    }

    setSearchParams(queryParams);
    toggleFilter();
  };

  const { download } = useDownload();

  const onDownload = async (dictionary_id) => {
    download(`dictionaries/${dictionary_id}/exports`);
  };

  const onDownloadTemplate = (values) => {
    downloadTemplate('dictionaries/templates/exports', values);
    toggleExportTemplateModal();
  };

  return (
    <>
      <Modal
        title={<IntlMessages id="dictionaries-translation-preview" />}
        width="60%"
        centered
        destroyOnClose
        visible={openModal}
        onCancel={toggleModal}
        footer={null}
        closeIcon={
          <RiCloseFill className="remix-icon text-color-black-100" size={24} />
        }
      >
        <PreviewDictionaryTranslation
          dictionaryDetails={previewDictionary}
          languages={languages?.languages}
        />
      </Modal>

      <Modal
        title={<IntlMessages id='dictionaries-export-template' />}
        width={700}
        centered
        destroyOnClose
        visible={openExportTemplateModal}
        onCancel={toggleExportTemplateModal}
        footer={null}
        closeIcon={
          <RiCloseFill className="remix-icon text-color-black-100" size={24} />
        }
      >
        <FormGenerateTemplate
          onSubmit={onDownloadTemplate}
          isSubmitting={isDownloadingTemplate}
        />
      </Modal>

      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={navigationSource ? (
            <Link to={urlPageFeatures({ featureSource: navigationSource })}>
              <IntlMessages id={getFeatureSourceTranslation(navigationSource)} />
            </Link>
          ) : null}
          breadCrumbActive={<IntlMessages id='dictionaries-breadcrumbs' />}
        />

        <Col>
          <Space>
            <PrivateComponent allowedPermissions={[ Permissions.ADMINISTRATION.DICTIONARIES.FILES.IMPORT ]}>
              <ActionButton
                title={<IntlMessages id="dictionaries-import-title" />}
                icon={<PiUpload className="btn-icon-mr-1" />}
                onClick={() => navigate(urlPageDictionariesImport({ source: navigationSource ?? null }))}
              />
            </PrivateComponent>
            <PrivateComponent allowedPermissions={[ Permissions.ADMINISTRATION.DICTIONARIES.FILES.EXPORT_TEMPLATE ]}>
              <ActionButton
                title={<IntlMessages id="dictionaries-export-template" />}
                icon={<PiDownload className="btn-icon-mr-1" />}
                onClick={toggleExportTemplateModal}
              />
            </PrivateComponent>
            <PrivateComponent allowedPermissions={[ Permissions.ADMINISTRATION.DICTIONARIES.LIST.CREATE ]}>
              <ActionButton
                title={<IntlMessages id="ui-general-create" />}
                icon={<RiAddLine className="btn-icon-mr-1" />}
                onClick={() => navigate(urlPageDictionaryCreate({ source: navigationSource ?? null }))}
              />
            </PrivateComponent>
            <ActionButton
              title=""
              icon={<Filter size={18} />}
              onClick={toggleFilter}
            />
          </Space>
        </Col>

        <Sidebar
          title={<IntlMessages id='dictionaries-filter-title' />}
          visible={filterOpen}
          toggleSidebar={toggleFilter}
        >
          <FormFilterDictionary
            initialValues={initFilterValues}
            filterOptions={filterData}
            onSubmit={applyFilter}
            onReset={resetFilter}
            isSubmitting={false}
            showSourceFilter={!navigationSource}
          />
        </Sidebar>
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <Table
              sticky
              loading={isLoading || isLoadingDeleteDictionary || isLoadingUpdateDictionary}
              rowKey="id"
              columns={getDictionariesTableColumns(handleDelete, handleView, handleChangeStatus, onDownload, navigationSource)}
              dataSource={data.data}
              onChange={handleChangeTableParams}
              pagination={{
                ...basePagination,
                current: pagination.page,
                pageSize: pagination.limit,
                total: data.pagination?.total,
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PageDictionaries;
