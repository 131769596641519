import React, { useState } from 'react';
import { Card, Col, Row } from 'antd';
import { Link, useParams } from 'react-router-dom';
import isObject from 'lodash/isObject';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import urlPageRBACRoles from '../../../../../urls/urlPageRBACRoles';
import FormCreateEditRole from '../../../forms/FormCreateEditRole';
import FormEditPermissions from '../../../forms/FormEditPermissions';
import {
  useGetRolePermissionsQuery,
  useGetRoleQuery,
  useUpdateRoleMutation,
  useUpdateRolePermissionsMutation,
} from '../../../api/rolesApiSlice';
import useToggle from '../../../../../app/hooks/useToggle';
import { useGetPermissionsQuery } from '../../../api/permissionsApiSlice';
import { HasRights } from '../../../../../components/has-rights/HasRights';
import { Permissions } from '../../../../../const/permissions';



const PageRoleDetails = () => {
  const { roleId } = useParams();
  const [ isActiveFormEditRole, toggleEditRoleActive ] = useToggle();
  const [ isActiveEditPermissionsForm, toggleEditPermissionsActive ] = useToggle();
  const [ roleDetailsErrors, setCreateRoleDetailsErrors ] = useState({});
  const [ rolePermissionsErrors, setCreateRolePermissionsErrors ] = useState({});

  const { data: rolesDetails = {}, isLoading } = useGetRoleQuery(roleId);
  const { data: permissionsList = [], isLoading: isLoadingPermissionsList } = useGetPermissionsQuery({
    queryParams: 'limit=0',
  });
  const { data: rolePermissions = [], isLoading: isLoadingRolePermissionsList } = useGetRolePermissionsQuery(roleId);
  const [ updateRole, { isLoading: isLoadingUpdateRole } ] = useUpdateRoleMutation();
  const [ updateRolePermissions, { isLoading: isLoadingUpdateRolePermissions } ] = useUpdateRolePermissionsMutation();

  const rolePermissionsIds = rolePermissions?.map((item) => item.id);

  const handleSubmitUpdateRoleForm = async (values) => {
    try {
      await updateRole({ values, roleId }).unwrap().then(() => {
        toggleEditRoleActive();
      });
    } catch (error) {
      if (isObject(error?.data?.errors)) {
        setCreateRoleDetailsErrors(error.data.errors);
      }
    }
  };

  const handleSubmitFormEditPermissions = async (values) => {
    try {
      await updateRolePermissions({ values, roleId }).unwrap().then(() => {
        toggleEditPermissionsActive();
      });
    } catch (error) {
      if (isObject(error?.data?.errors)) {
        setCreateRolePermissionsErrors(error.data.errors);
      }
    }
  };

  const handleSetRoleDetailsEdit = () => {
    toggleEditRoleActive();
    setCreateRoleDetailsErrors({});
  };


  const handleSetRolePermissionsEdit = () => {
    toggleEditPermissionsActive();
    setCreateRolePermissionsErrors({});
  };

  return (
    <>
      <Row gutter={32}>
        <Col className="hp-mb-32" span={24}>
          <BreadCrumbs
            breadCrumbParent={<Link to={urlPageRBACRoles()}><IntlMessages id='roles-breadcrumbs' /></Link>}
            breadCrumbActive={<IntlMessages id='role-details-breadcrumbs' />}
          />
        </Col>
      </Row>

      {HasRights([ Permissions.ADMINISTRATION.ROLES.ROLE_DETAILS.VIEW ]) && (
        <Row gutter={[ 16, 16 ]}>
          <Col md={12} span={24} className="hp-profile-content-list hp-mt-32 hp-ml-24 hp-pb-sm-0 hp-pb-120">
            <Card
              title={<IntlMessages id="role-details-card-header" />}
              loading={isLoadingUpdateRole || isLoading}
              style={{ marginBottom: '10px' }}
            >
              <FormCreateEditRole
                initialValues={rolesDetails}
                formErrors={roleDetailsErrors}
                isRoleEdit
                handleSetFormEdit={handleSetRoleDetailsEdit}
                isEditDisabled={!isActiveFormEditRole}
                onSubmit={handleSubmitUpdateRoleForm}
                isSubmitting={isLoadingUpdateRole}
              />
            </Card>
          </Col>

          <Col md={12} span={24} className="hp-profile-content-list hp-mt-32 hp-ml-24 hp-pb-sm-0 hp-pb-120">
            <Card
              title={<IntlMessages id="role-permissions-card-header" />}
              loading={isLoadingUpdateRolePermissions || isLoadingPermissionsList || isLoadingRolePermissionsList}
              style={{ marginBottom: '10px' }}
            >
              <FormEditPermissions
                initialValues={{ permissions_ids: rolePermissionsIds }}
                formErrors={rolePermissionsErrors}
                permissionsOptions={permissionsList.permissions}
                onSubmit={handleSubmitFormEditPermissions}
                isSubmitting={isLoadingUpdateRolePermissions}
                handleSetFormEdit={handleSetRolePermissionsEdit}
                isDisabled={!isActiveEditPermissionsForm}
              />
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export default PageRoleDetails;
