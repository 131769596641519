import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, Card, Table, Input, Space, Typography } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { Search } from 'react-iconly';
import { Filter } from 'iconsax-react';
import { useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import urlPageBonusDetails from '../../../../urls/urlPageBonusDetails';
import urlPageBonusEdit from '../../../../urls/urlPageBonusEdit';
import basePagination from '../../../../const/pagination';
import { Permissions } from '../../../../const/permissions';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { BONUSES_SOURCE, getBonusesTableColumns } from './PageBonuses.const';
import { makeNotification, notificationTypes } from '../../../../lib/makeNotification';
import { PrivateComponent } from '../../../../components/has-rights/HasRights';
import mkRandomCode from '../../utils/mkRandomCode';
import useElementSize from '../../../../app/hooks/useElementSize';
import useQueryParams from '../../../../app/hooks/useQueryParams';
import useToggle from '../../../../app/hooks/useToggle';
import getQueryParams from '../../../../lib/getQueryParams';
import getConfigurations from '../../../../lib/getConfigurations';
import DocumentationAlert from '../../../../components/DocumentationAlert/DocumentationAlert';
import BreadCrumbs from '../../../../layout/components/breadcrumbs';
import ActionButton from '../../../../layout/components/action-button';
import Sidebar from '../../../../components/sidebar/Sidebar';
import FormBonusesFilter from '../../forms/FormBonusesFilter';
import FormCreateEditBonus from '../../forms/FormCreateEditBonus';
import {
  useGetBonusesQuery,
  useCreateBonusMutation,
  useDeleteBonusMutation,
} from '../../api/bonusesApiSlice';



const { Title } = Typography;

const PageBonuses = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [ elementRef, size ] = useElementSize();
  const [ isVisibleFilterSidebar, toggleFilterSidebar ] = useToggle();
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const [ initFilterValues, setInitFilterValues ] = useState({});
  const [ searchField, setSearchField ] = useState('');
  const [ initialCodeValue, setInitialRandomCode ] = useState('');
  const [ isOpenedCreateBonusSidebar, toggleCreateBonusSidebar ] = useToggle();
  const [ bonusInitialErrors, setBonusInitialErrors ] = useState({});

  const searchFields = [ 'title' ];
  const searchFilterFields = [ 'id', 'code', 'is_published', 'title', 'released_at', 'released_to' ];

  const source = BONUSES_SOURCE;
  const bonusesConfig = getConfigurations(source);

  const {
    pagination,
    search,
    setSearchTerm,
    searchParams,
    handleChangeTableParams,
  } = useQueryParams({ searchFields, searchFilterFields, isFilter: true });

  const { data = { data: [], pagination: {} }, isFetching } = useGetBonusesQuery({
    queryParams: searchParams.toString(),
  });

  const [ deleteBonus, { isLoading: isDeletingBonus } ] = useDeleteBonusMutation();
  const [ createBonus, { isLoading: isCreatingBonus } ] = useCreateBonusMutation();

  useEffect(() => {
    setSearchField(search);
  }, []);


  const applyFilter = (values) => {
    const queryParams = {};

    Array.from(searchQueryParams.entries())
      .forEach(([ key, value ]) => {
        queryParams[key] = key === 'page' ? 1 : value;
      });

    queryParams.search = Object.entries(values)
      .map(([ field, value ]) => {
        if (searchFilterFields.includes(field)) {
          if ((isArray(value) && !value.length) || value === null) {
            return '';
          }
          return isString(value) ? `${field}:${value.trim()}` : `${field}:${value}`;
        }
        return '';
      })
      .filter((item) => item !== '')
      .join(';');

    setSearchField('');
    setSearchParams(queryParams);
    toggleFilterSidebar();
  };

  const resetFilter = () => {
    const queryParams = {};

    Array.from(searchQueryParams.entries())
      .forEach(([ key, value ]) => {
        if (key === 'search') {
          return;
        }

        queryParams[key] = key === 'page' ? 1 : value;
      });

    setSearchField('');
    setSearchParams(queryParams);
    setInitFilterValues({});
  };

  useEffect(() => {
    const queryParams = getQueryParams(window.location.search);

    if (queryParams.search) {
      const initValues = {};

      queryParams.search.split(';').forEach((item) => {
        const [ key, value ] = item.split(':');

        if (value !== '') {
          initValues[key] = value;
        }
      });

      setInitFilterValues(initValues);
    }
  }, []);


  useEffect(() => {
    if (!searchParams.toString()) {
      setInitFilterValues({
        reset: true, // ugly hack
      });
    }
  }, [ searchParams ]);


  const handleSearch = useCallback((event) => {
    setSearchField(event.target.value);
    setSearchTerm(event.target.value);
  }, []);

  const handleViewDetails = (bonusId) => {
    navigate(urlPageBonusDetails({ bonusId }));
  };

  const handleCreate = (values, resetForm) => {
    createBonus({ values })
      .unwrap()
      .then((response) => {
        toggleCreateBonusSidebar();
        setInitialRandomCode('');
        resetForm();
        makeNotification(
          notificationTypes.success,
          intl.formatMessage({ id: 'ui-general-success' }),
          intl.formatMessage({ id: 'ui-general-created-successfully' }),
        );
        navigate(urlPageBonusEdit({ bonusId: response?.data?.id }));
      })
      .catch((error) => {
        makeNotification(
          notificationTypes.error,
          intl.formatMessage({ id: 'ui-general-error' }),
          <>
            <p className="hp-mb-4">
              {intl.formatMessage({ id: 'ui-general-created-failed' })}
            </p>
            {error?.data?.message && <p>{ error.data.message }</p>}
          </>,
        );
        setBonusInitialErrors({ fakeField: Date.now(), ...error?.data?.errors } || {});
      });
  };

  const handleDelete = (bonusId) => {
    deleteBonus(bonusId)
      .unwrap()
      .then(() => {
        makeNotification(
          notificationTypes.success,
          intl.formatMessage({ id: 'ui-general-success' }),
          intl.formatMessage({ id: 'ui-general-deleted-successfully' }),
        );
      })
      .catch((error) => {
        makeNotification(
          notificationTypes.error,
          intl.formatMessage({ id: 'ui-general-error' }),
          <>
            <p className="hp-mb-4">{intl.formatMessage({ id: 'ui-general-delete-failed' })}</p>
            {error?.data?.message && <p>{ error.data.message }</p>}
          </>,
        );
      });
  };

  const handleEdit = (bonusId) => {
    navigate(urlPageBonusEdit({ bonusId }));
  };

  const isLoading = isFetching || isDeletingBonus || isCreatingBonus;

  return (
    <>
      <DocumentationAlert link='https://x-flow-ltd.atlassian.net/wiki/spaces/HCD/pages/878477340/Bonuses' />
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs breadCrumbActive={<IntlMessages id='bonuses-breadcrumbs' />} />

        <Col md={16} span={24}>
          <Row
            gutter={[ 32, 32 ]}
            justify="end"
            align="middle"
          >
            <Col sm={12} span={24}>
              <Input
                allowClear={!isEmpty(searchField)}
                placeholder={intl.formatMessage({ id: 'ui-general-search-by-title' })}
                prefix={<Search set="curved" size={16} className="hp-text-color-black-80" />}
                value={searchField}
                onChange={handleSearch}
              />
            </Col>
            <Col>
              <Space>
                <PrivateComponent allowedPermissions={[ Permissions.CONTENT.BONUSES.CREATE ]}>
                  <ActionButton
                    onClick={() => {
                      toggleCreateBonusSidebar();
                      setBonusInitialErrors({});
                      setInitialRandomCode(mkRandomCode());
                    }}
                    title={<IntlMessages id='ui-general-create' />}
                  />
                </PrivateComponent>
                <ActionButton
                  title=""
                  icon={<Filter size={18} />}
                  onClick={toggleFilterSidebar}
                />
              </Space>
            </Col>
          </Row>
        </Col>

        <Sidebar
          key={source}
          title={<Title level={5}>{intl.formatMessage({ id: 'bonuses-filter-title' })}</Title>}
          visible={isVisibleFilterSidebar}
          toggleSidebar={toggleFilterSidebar}
        >
          <FormBonusesFilter
            initialValues={initFilterValues}
            isSubmitting={false}
            onCancel={resetFilter}
            onSubmit={applyFilter}
          />
        </Sidebar>

        <Sidebar
          title={<IntlMessages id='bonus-create-sidebar' />}
          visible={isOpenedCreateBonusSidebar}
          toggleSidebar={toggleCreateBonusSidebar}
        >
          <FormCreateEditBonus
            initialValues={{ code: initialCodeValue }}
            initialErrors={bonusInitialErrors}
            onSubmit={handleCreate}
            onCancel={() => {}}
            isSubmitting={isCreatingBonus}
          />
        </Sidebar>
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <div ref={elementRef}>
              <Table
                sticky
                scroll={{ x: 860 }}
                loading={isLoading}
                rowKey="id"
                columns={
                  getBonusesTableColumns(
                    handleViewDetails,
                    handleEdit,
                    handleDelete,
                    bonusesConfig,
                    size,
                  )
                }
                dataSource={data.data}
                onChange={handleChangeTableParams}
                pagination={{
                  ...basePagination,
                  current: pagination.page,
                  pageSize: pagination.limit,
                  total: data.pagination?.total,
                }}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PageBonuses;
