import React, { useState } from 'react';
import { Card, Col, Row } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import isObject from 'lodash/isObject';
import { useIntl } from 'react-intl';
import BreadCrumbs from '../../../../layout/components/breadcrumbs';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import urlPagePresets from '../../../../urls/urlPagePresets';
import FormCreateEditPresets from '../../forms/FormCreateEditPresets';
import { useCreatePresetMutation } from '../../api/presetsApiSlice';
import { makeNotification, notificationTypes } from '../../../../lib/makeNotification';
import transformErrors from '../../../../lib/transformErrors';



const PagePresetsCreate = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [ errors, setFormErrors ] = useState({});
  const [ createPreset, { isLoading: isCreatingPreset } ] = useCreatePresetMutation();

  const onSubmit = (values) => {
    createPreset({ values })
      .unwrap()
      .then(() => {
        makeNotification(
          notificationTypes.success,
          intl.formatMessage({ id: 'ui-general-success' }),
          intl.formatMessage({ id: 'presets-created-successfully' }),
        );

        navigate(urlPagePresets());
      })
      .catch((error) => {
        if (isObject(error?.data?.errors)) {
          const formikInitialErrors = transformErrors(error?.data?.errors);

          setFormErrors(formikInitialErrors);
        }

        let message = '';

        // Todo: remove after implementing validation in templates for validation rules.
        if (error?.data?.file?.includes('Validation/Concerns/ValidatesAttributes.php') || error?.data?.file?.includes('Illuminate/Validation/Validator.php')) {
          message = (
            <>
              <p>{intl.formatMessage({ id: 'dynamic-news-template-validation-error' })}</p>
              <p>{error?.data?.message}</p>
            </>
          );
        }

        makeNotification(
          notificationTypes.error,
          intl.formatMessage({ id: 'ui-general-error' }),
          <div className="hp-mb-4">
            {intl.formatMessage({ id: 'presets-created-failed' })}
            {message}
          </div>,
        );
      });
  };

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={
            <Link to={urlPagePresets()}>
              <IntlMessages id='presets-breadcrumbs' />
            </Link>
          }
          breadCrumbActive={<IntlMessages id='ui-general-create' />}
        />
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <FormCreateEditPresets
              initialErrors={errors}
              isSubmitting={isCreatingPreset}
              onSubmit={onSubmit}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PagePresetsCreate;
