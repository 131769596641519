import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConfigProvider } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import AppLocale from './languages';
import Router from './router/Router';
import Config from './configs/appConfig';
import { useRefreshUserMutation } from './domains/Auth/api/authApiSlice';
import { refreshUserData } from './domains/Auth/features/authSlice';
import { useApp } from './app/context/AppContext';



const App = () => {
  const { userData } = useApp();
  const dispatch = useDispatch();
  const [ refreshUser ] = useRefreshUserMutation();
  const customise = useSelector((state) => state.customise);
  const currentAppLocale = AppLocale[customise.language];
  const commitHash = Config.LAST_COMMIT_HASH;
  const storedAppVersion = localStorage.getItem('app_version');
  const versionHash = !isEmpty(storedAppVersion) ? JSON.parse(storedAppVersion) : '';


  useEffect(() => {
    document.querySelector('html').setAttribute('lang', customise.language);
  }, [ customise ]);

  useEffect(() => {
    if ((commitHash !== versionHash) && !isEmpty(userData)) {
      refreshUser().unwrap().then((payload) => {
        dispatch(refreshUserData(payload));
      });
    }
  }, [ commitHash ]);

  return (
    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd} direction={customise.direction}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </ConfigProvider>
    </IntlProvider>
  );
};

export default App;
