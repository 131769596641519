import Config from '../configs/appConfig';



// const isLocal = document.location.hostname === 'localhost';
// const isDev = Config.VITE_APP_ENVIRONMENT === 'develop' || isLocal;
// const isQa = Config.VITE_APP_ENVIRONMENT === 'staging' || isLocal;
const isProd = Config.VITE_APP_ENVIRONMENT === 'production';


export const FEATURE_SHOW_TEST_MODE = !isProd;
