import React from 'react';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';



export const statusOptions = [ {
  label: <IntlMessages id="ui-general-on" />,
  value: '1',
}, {
  label: <IntlMessages id="ui-general-off" />,
  value: '0',
} ];
