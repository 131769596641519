const languages = {
  'languages-breadcrumbs': 'Languages',
  'languages-search-by': 'Search by name or code...',

  'languages-code-field': 'Code',
  'languages-table-delete-btn-confirm-message': 'Are you sure to delete language?',

  'form-languages-name-placeholder': 'English',
  'form-languages-code-placeholder': 'ENG',
};

export default languages;
