import capitalize from 'lodash/capitalize';
import { imageTypes } from '../../../../const/imageTypes';
import { imageStatuses } from '../../../../const/imageStatuses';



const imageTypeOptions = Object.entries(imageTypes)
  .map(([ key, value ]) => {
    return { label: capitalize(value), value: key };
  });

const imageStatusOptions = Object.entries(imageStatuses)
  .map(([ key, value ]) => {
    return { label: capitalize(key), value };
  });

export {
  imageStatusOptions,
  imageTypeOptions,
};
