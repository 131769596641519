export const statusOptions = [ {
  label: 'On',
  value: 'on',
}, {
  label: 'Off',
  value: 'off',
} ];

export const typeOptions = [ {
  label: 'External link',
  value: 'external link',
}, {
  label: 'App action',
  value: 'app action',
} ];
