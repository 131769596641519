import isEmpty from 'lodash/isEmpty';
import { useGetAllVersionsStructuresQuery } from '../../domains/Structures/api/structuresApiSlice';
import { useGetDictionariesQuery } from '../../domains/Dictionary/api/dictionariesApiSlice';



const useDictionaryOptions = (source, currentDictionaryId, unused = false) => {
  let dictionaryOptions;

  const { data: structures, isLoading: isStructuresLoading } = useGetAllVersionsStructuresQuery({
    queryParams: `search=source:${source}`,
  });

  let params = `search=status:ready;structure_id:${structures?.data?.map((structure) => structure.id)}&limit=0`;

  if (unused) {
    params += '&unused=true';
  }

  if (currentDictionaryId) {
    params += `&current_id=${currentDictionaryId}`;
  }

  const { data: dictionaries, isLoading: isDictionariesLoading } = useGetDictionariesQuery({
    queryParams: params,
  }, { skip: isStructuresLoading });

  if (dictionaries?.data) {
    dictionaryOptions = dictionaries.data.map((dictionary) => {
      return {
        value: dictionary.id,
        label: `${dictionary.name}`,
      };
    });
  }

  const showWarning = dictionaryOptions && isEmpty(dictionaryOptions);

  return [ dictionaryOptions, isDictionariesLoading, showWarning ];
};

export default useDictionaryOptions;
