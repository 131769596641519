import isFunction from 'lodash/isFunction';



const useResetFilter = (searchParams, setSearchParams, setFilterValues, navigationSource = null) => {
  return (callback = null) => {
    const queryParams = {};

    Array.from(searchParams.entries())
      .forEach(([ key, value ]) => {
        if (key === 'search') {
          if (navigationSource) {
            queryParams[key] = `source:${navigationSource}`;
          }

          return;
        }

        queryParams[key] = key === 'page' ? 1 : value;
      });

    setSearchParams(queryParams);
    setFilterValues({});

    if (isFunction(callback)) {
      callback();
    }
  };
};

export default useResetFilter;
