import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Modal, Popconfirm, Row, Space, Table, Tag } from 'antd';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { RiCloseFill, RiEditLine, RiErrorWarningLine } from 'react-icons/ri';
import { MdFileDownloadDone } from 'react-icons/md';
import { useIntl } from 'react-intl';
import { PiDownload, PiUpload } from 'react-icons/pi';
import { useGetLanguagesQuery } from '../../../Language/api/languagesApiSlice';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import {
  getDataSource,
  getTranslationListTableColumns,
  handleSelectPrevTranslation,
  handleUpdateTranslationValue,
} from './PageTranslationsDetails.const';
import urlPageDictionaryEdit from '../../../../urls/urlPageDictionaryEdit';
import urlPageDictionaries from '../../../../urls/urlPageDictionaries';
import urlPageFeatures from '../../../../urls/urlPageFeatures';
import { DICTIONARY_STATUS } from '../../../Dictionary/pages/PageDictionaries/PageDictionaries.const';
import { makeNotification, notificationTypes } from '../../../../lib/makeNotification';
import { PrivateComponent } from '../../../../components/has-rights/HasRights';
import { Permissions } from '../../../../const/permissions';
import BreadCrumbs from '../../../../layout/components/breadcrumbs';
import TranslationCell from '../../components/TranslationCell';
import useToggle from '../../../../app/hooks/useToggle';
import ActionButton from '../../../../layout/components/action-button';
import useDownload from '../../../../app/hooks/useDownload';
import FormImportDictionaries from '../../../Dictionary/forms/FormImportDictionaries';
import getFeatureSourceTranslation from '../../../Featured/utils/getFeatureSourceTranslation';
import {
  useGetDictionaryQuery, useImportDictionaryMutation,
  useSaveDictionaryTranslationsMutation, useUpdateDictionaryMutation,
} from '../../../Dictionary/api/dictionariesApiSlice';



const PageTranslationsDetails = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const [ searchQueryParams ] = useSearchParams();
  const navigationSource = searchQueryParams.get('source') ?? null;
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [ translationData, setTranslationData ] = useState([]);
  const [ isSelecting, toggleSelecting ] = useToggle();
  const [ isActiveImportModal, toggleImportModal ] = useToggle();
  const timestampRef = useRef(Date.now()).current; // ugly hack for force rerender
  const [ item, setItem ] = useState(0); // ugly hack for force rerender

  const { data: languageData = { data: [] }, isLoading: isLangsLoading } = useGetLanguagesQuery({
    queryParams: 'search=status:enabled&limit=0',
  });
  const [ saveDictionaryTranslations, { isLoading: isTranslationsLoading } ] = useSaveDictionaryTranslationsMutation();
  const [ updateDictionary, { isLoading: isLoadingUpdateDictionary } ] = useUpdateDictionaryMutation();
  const { data: dictionaryDetails = { translations: { data: [] } }, isFetching } = useGetDictionaryQuery({ id, include: 'translations,structure,structure.fields.translations', sessionId: timestampRef + item });
  const [ importDictionary, { isLoading: isImportingDictionary } ] = useImportDictionaryMutation();

  const structure = isFetching ? {} : dictionaryDetails.structure?.data;

  const availableLanguages = isLangsLoading || isFetching ? [] :
    languageData.languages.filter((lng) => structure.language_ids.indexOf(lng.id) !== -1);

  const isReady = dictionaryDetails.id ? dictionaryDetails.status === DICTIONARY_STATUS.READY : true;

  useEffect(() => {
    if (dictionaryDetails.translations.data) {
      setTranslationData(dictionaryDetails.translations.data);
    }
  }, [ isFetching ]);

  const handleImportDictionary = (values) => {
    const formData = new FormData();

    formData.append('file', values.file);

    importDictionary({ id, formData })
      .unwrap()
      .then(() => {
        setItem((prevState) => prevState + 1);
        makeNotification(
          notificationTypes.success,
          intl.formatMessage({ id: 'ui-general-success' }),
          'Dictionary imported successfully',
        );
      })
      .catch((error) => {
        makeNotification(
          notificationTypes.error,
          intl.formatMessage({ id: 'ui-general-error' }),
          <>
            <p className="hp-mb-4">{intl.formatMessage({ id: 'dictionaries-import-failed' })}</p>
            {error?.data?.message && <p>{ error.data.message }</p>}
          </>,
        );
      })
      .finally(() => {
        toggleImportModal();
      });
  };

  const findTranslation = (field) => {
    return translationData
      .find((tr) => tr.structure_field_id === field.field_id && tr.language_id === field.language_id);
  };

  const handleChange = (val, field) => handleUpdateTranslationValue(val, field, setTranslationData);

  const handleSelect = (dictionary_id, field) => {
    toggleSelecting();
    handleSelectPrevTranslation(dictionary_id, field, dispatch, setTranslationData, toggleSelecting);
  };

  const handleSave = () => {
    saveDictionaryTranslations({ id, values: { data: translationData } })
      .unwrap()
      .then(() => {
        makeNotification(
          notificationTypes.success,
          intl.formatMessage({ id: 'ui-general-success' }),
          intl.formatMessage({ id: 'translations-updated-successfully' }),
        );
      });
  };

  const handleMakeReady = () => {
    updateDictionary({ dictionaryId: id, values: { status: DICTIONARY_STATUS.READY } });
    dictionaryDetails.status = DICTIONARY_STATUS.READY;
  };

  const handleRenderTranslationCell = (language, field) => {
    const translation = findTranslation({ field_id: field.id, language_id: language.id });

    return (
      <TranslationCell
        field={{
          id: null,
          text: translation?.text ?? '',
          language_id: language.id,
          field_id: field.id,
          label: field.field,
          dictionary_id: dictionaryDetails.id,
        }}
        availableLanguages={availableLanguages}
        dictionaryDetails={dictionaryDetails}
        language={language}
        min={field.validation.min}
        max={field.validation.max || 1000}
        handleChange={handleChange}
        handleSelect={handleSelect}
      />
    );
  };

  const { download } = useDownload();

  const onDownload = async (dictionary_id) => {
    download(`dictionaries/${dictionary_id}/exports`);
  };

  const dictionaryName = () => {
    if (!dictionaryDetails.id) {
      return null;
    }

    return (
      <div className='hp-d-flex-center'>
        {`${dictionaryDetails.name} (v${dictionaryDetails?.structure?.data?.version})`}
        &nbsp;
        <Tag color={isReady ? 'success' : ''}>
          <IntlMessages id={isReady ? 'dictionaries-status-ready' : 'dictionaries-status-draft'} />
        </Tag>
      </div>
    );
  };

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={navigationSource ? (
            <Link to={urlPageFeatures({ featureSource: navigationSource })}>
              <IntlMessages id={getFeatureSourceTranslation(navigationSource)} />
            </Link>
          ) : null}
          breadCrumbParent2={<Link to={urlPageDictionaries({ source: navigationSource })}><IntlMessages id='dictionaries-breadcrumbs' /></Link>}
          breadCrumbActive={<IntlMessages id='translations-breadcrumbs' />}
        />

        <Col>
          <Space>
            <PrivateComponent allowedPermissions={[ Permissions.ADMINISTRATION.DICTIONARIES.FILES.EXPORT ]}>
              <ActionButton
                title={<IntlMessages id="dictionaries-export-title" />}
                icon={<PiDownload className="btn-icon-mr-1" />}
                onClick={() => onDownload(id)}
              />
            </PrivateComponent>
            <PrivateComponent allowedPermissions={[ Permissions.ADMINISTRATION.DICTIONARIES.FILES.IMPORT ]}>
              <ActionButton
                title={<IntlMessages id="dictionaries-import-title" />}
                icon={<PiUpload className="btn-icon-mr-1" />}
                onClick={toggleImportModal}
              />
            </PrivateComponent>
            <PrivateComponent allowedPermissions={[ Permissions.ADMINISTRATION.DICTIONARIES.DETAILS.VIEW ]}>
              <ActionButton
                title={<IntlMessages id="translations-edit-button" />}
                icon={<RiEditLine className="btn-icon-mr-1" />}
                onClick={() => navigate(urlPageDictionaryEdit({ id, source: navigationSource }))}
              />
            </PrivateComponent>
            {!isReady &&
              <PrivateComponent allowedPermissions={[ Permissions.ADMINISTRATION.DICTIONARIES.DETAILS.EDIT ]}>
                <Popconfirm
                  title={
                    <div>
                      <IntlMessages id='dictionaries-update-status-confirm-message' />
                      <br />
                      <span className='hp-input-description'><IntlMessages id='dictionaries-update-status-confirm-message-danger' /></span>
                    </div>
                  }
                  onConfirm={handleMakeReady}
                  placement='bottomRight'
                  okText={<IntlMessages id='ui-general-yes' />}
                  cancelText={<IntlMessages id='ui-general-no' />}
                  icon={<RiErrorWarningLine className='remix-icon hp-text-color-primary-1' />}
                >
                  <ActionButton
                    disabled={isFetching}
                    title={<IntlMessages id='ui-dictionaries-status-ready' />}
                    icon={<MdFileDownloadDone className="btn-icon-mr-1" />}
                    onClick={() => {}}
                  />
                </Popconfirm>
              </PrivateComponent>}
          </Space>
        </Col>
      </Row>

      <Modal
        title={<IntlMessages id="translations-import-title" />}
        width={520}
        centered
        destroyOnClose
        visible={isActiveImportModal}
        onCancel={toggleImportModal}
        footer={null}
        closeIcon={
          <RiCloseFill className="remix-icon text-color-black-100" size={24} />
        }
      >
        <FormImportDictionaries
          warningText={<IntlMessages id="translations-import-warning" />}
          onSubmit={handleImportDictionary}
          isSubmitting={isImportingDictionary}
        />
      </Modal>

      <Card
        title={dictionaryName()}
        className="hp-border-color-black-40 hp-card-6"
      >
        <Table
          size="small"
          bordered
          loading={isLangsLoading || isFetching || isTranslationsLoading || isLoadingUpdateDictionary || isSelecting}
          rowKey="field"
          className="top-content-table"
          columns={getTranslationListTableColumns(
            availableLanguages,
            handleRenderTranslationCell,
          )}
          dataSource={!isFetching && !isLangsLoading ? getDataSource(dictionaryDetails, structure, languageData.languages) : []}
          pagination={false}
          scroll={{ x: 320 + availableLanguages.length * 220 }}
        />
      </Card>

      <Row gutter={[ 16, 16 ]} justify='end' className="hp-mt-8">
        <Col>
          <Button
            type="secondary"
            ghost
            onClick={() => {
              navigate(state?.key === 'prev_dictionary_edit' ? -2 : -1);
            }}
          >
            <IntlMessages id='ui-general-back' />
          </Button>
        </Col>
        <Col>
          <Button type="primary" ghost onClick={handleSave}>
            <IntlMessages id='ui-general-save' />
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default PageTranslationsDetails;
