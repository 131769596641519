import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import getFullFieldName from '../../domains/Content/utils/getFullFieldName';
import isFieldUsingInPreset from '../../lib/isFieldUsingInPreset';



const useModifiedDynamicDataErrors = (currentTemplateId, template, initialErrors, language) => {
  return useMemo(() => {
    if (!currentTemplateId || isEmpty(template) || !initialErrors?.data) {
      return initialErrors;
    }

    const modifiedErrors = { ...initialErrors };

    template?.fields?.data.filter((field) => isFieldUsingInPreset(field))
      .forEach((field) => {
        const fieldName = getFullFieldName(field);

        if (modifiedErrors.data[fieldName]?.value) {
          const regex = /(The\s)(.*?)(\sfield)/;
          const replacement = field.title_en;

          modifiedErrors.data[fieldName].value = modifiedErrors.data[fieldName].value
            .replace(regex, `$1${replacement}$3`);
        }
      });

    return modifiedErrors;
  }, [ currentTemplateId, template, initialErrors, language ]);
};

export default useModifiedDynamicDataErrors;
