import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Col,
  Row,
  Tooltip,
  Spin,
  Typography,
  Pagination,
  Modal,
  Card,
  Descriptions,
  Tag,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import { RiCloseFill } from 'react-icons/ri';
import { MdKeyboardReturn } from 'react-icons/md';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import urlPageCategories from '../../../../urls/urlPageCategories';
import urlPageTranslationDetails from '../../../../urls/urlPageTranslationDetails';
import { SOURCE_CATEGORIES } from '../PageCategories/PageCategories.const';
import { DATE_TIME_FORMAT, EMPTY_VALUE_PLACEHOLDER, LOADING_TIP_PLACEHOLDER } from '../../../../const/system';
import { useGetCategoriesQuery, useGetCategoryQuery } from '../../api/categoriesApiSlice';
import { HasRights } from '../../../../components/has-rights/HasRights';
import { Permissions } from '../../../../const/permissions';
import useDictionaryOptions from '../../../../app/hooks/useDictionaryOptions';
import useQueryParams from '../../../../app/hooks/useQueryParams';
import useToggle from '../../../../app/hooks/useToggle';
import getFormattedDate from '../../../../lib/getFormattedDate';
import BreadCrumbs from '../../../../layout/components/breadcrumbs';
import ActionButton from '../../../../layout/components/action-button';
import ContentCard from '../../components/ContentCard';
import TotalLabel from '../../../../components/TotalLabel';
import PreviewImageCard from '../../components/PreviewImageCard';
import { useGetImagesQuery } from '../../api/imagesApiSlice';



const { Title } = Typography;

const PageCategoryDetails = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { categoryId } = useParams();
  const [ previewModalOpen, togglePreviewModal ] = useToggle();
  const [ previewImage, setPreviewImage ] = useState({});
  const [ dictionaryOptions = [], isStructuresLoading ] = useDictionaryOptions(SOURCE_CATEGORIES, null);
  const indexPageSearchParams = localStorage.getItem('indexSearchParams');

  const {
    data: { data: category } = { data: {} },
    isFetching: isCategoryFetching,
  } = useGetCategoryQuery(categoryId);

  const categoryDictionaryId = category?.dictionary?.data?.id;
  const categoryDictionary = dictionaryOptions?.find((dictionaryOption) => dictionaryOption.value === categoryDictionaryId)?.label;

  const {
    pagination: pagination,
    searchParams,
    handleChangeTableParams,
  } = useQueryParams({
    paginationInit: {
      page: 1,
      limit: 12,
    } });

  const { data: {
    data: categories = [],
  } = { data: [], pagination: {} }, isFetching: isCategoriesFetching } = useGetCategoriesQuery({
    queryParams: 'limit=0',
  });

  const { data: {
    data: images = [],
    pagination: dataPagination = {},
  } = { data: [], pagination: {} }, isFetching: isImagesFetching } = useGetImagesQuery({
    queryParams: `${searchParams.toString()}&search=category:${categoryId}`,
  });

  const showTotal = (total) => <TotalLabel total={total} />;

  const handleCardClick = (item) => {
    setPreviewImage(item);
    togglePreviewModal();
  };

  const handlePreviewModalClose = () => {
    setPreviewImage({});
    togglePreviewModal();
  };

  const isMainInfoLoading = isStructuresLoading || isCategoryFetching || isImagesFetching || isCategoriesFetching;

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={
            <Link to={urlPageCategories({ indexPageSearchParams })}>
              <IntlMessages id='categories-breadcrumbs' />
            </Link>
          }
          breadCrumbActive={category.name}
        />

        <Col>
          <ActionButton
            icon={<MdKeyboardReturn className="hp-mr-4" />}
            onClick={() => {
              navigate(urlPageCategories());
            }}
            title={<IntlMessages id='ui-general-back-to-list' />}
          />
        </Col>
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6" title={category?.name}>
            <Spin spinning={isMainInfoLoading} tip={LOADING_TIP_PLACEHOLDER}>
              <Descriptions
                layout="vertical"
                bordered
                className="hp-mb-32"
                column={{ xxl: 4, sm: 3, xs: 2 }}
              >
                <Descriptions.Item
                  label={(
                    <>
                      <IntlMessages id="ui-general-id" />
                      <Tooltip className="hp-ml-8" placement="topRight" title={<IntlMessages id="categories-create-form-field-client-id-help" />}>
                        <InfoCircleOutlined />
                      </Tooltip>
                    </>
                  )}
                >
                  {category?.client_id}
                </Descriptions.Item>
                <Descriptions.Item label={<IntlMessages id="categories-create-form-field-name" />}>
                  {category?.name || EMPTY_VALUE_PLACEHOLDER}
                </Descriptions.Item>
                <Descriptions.Item label={<IntlMessages id="ui-general-status" />}>
                  <Tag color={category?.status ? 'success' : 'error'}>
                    <IntlMessages id={category?.status ? 'ui-general-on' : 'ui-general-off'} />
                  </Tag>
                </Descriptions.Item>
                <Descriptions.Item label={<IntlMessages id="categories-create-form-field-dictionary" />}>
                  {categoryDictionaryId ? (
                    <Link to={urlPageTranslationDetails({ id: categoryDictionaryId })}>
                      {categoryDictionary || EMPTY_VALUE_PLACEHOLDER}
                    </Link>
                  ) : (
                    <div>
                      {categoryDictionary || EMPTY_VALUE_PLACEHOLDER}
                    </div>
                  )}
                </Descriptions.Item>
                <Descriptions.Item label={<IntlMessages id="categories-create-form-field-description" />}>
                  {category?.description || EMPTY_VALUE_PLACEHOLDER}
                </Descriptions.Item>
                <Descriptions.Item label={<IntlMessages id="categories-preview-priority" />}>
                  {category?.priority || EMPTY_VALUE_PLACEHOLDER}
                </Descriptions.Item>
                <Descriptions.Item label={<IntlMessages id="categories-preview-created-at" />}>
                  {getFormattedDate(category?.created_at, DATE_TIME_FORMAT)}
                </Descriptions.Item>
                {HasRights([ Permissions.CONTENT.CATEGORIES.OPTIONS.EDIT ]) && (
                  <Descriptions.Item
                    label={(
                      <>
                        <IntlMessages id="categories-create-form-field-options" />
                        <Tooltip className="hp-ml-8" placement="topRight" title={<IntlMessages id="categories-create-form-field-options-help" />}>
                          <InfoCircleOutlined />
                        </Tooltip>
                      </>
                    )}
                  >
                    {category?.options || EMPTY_VALUE_PLACEHOLDER}
                  </Descriptions.Item>
                )}
              </Descriptions>
            </Spin>
          </Card>
        </Col>
      </Row>

      <Row className='hp-mt-32'>
        <Col span={24}>
          <Title level={4}>
            <IntlMessages id="categories-view-images" />
          </Title>
        </Col>

        <Modal
          width={1200}
          title={intl.formatMessage({ id: 'content-image-preview-card-title' })}
          centered
          visible={previewModalOpen}
          onCancel={handlePreviewModalClose}
          footer={false}
          closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
        >
          <PreviewImageCard
            data={previewImage}
            categories={categories}
            onModalClose={handlePreviewModalClose}
          />
        </Modal>

        <Col span={24}>
          <Spin style={{ minHeight: '400px' }} spinning={isImagesFetching} tip={LOADING_TIP_PLACEHOLDER}>
            {!isEmpty(images) && (
              <Row gutter={[ 16, 32 ]} style={{ width: '100%' }}>
                {images.map((item) => {
                  return (
                    <Col className='gutter-row' key={item.id} xs={24} sm={12} md={8} lg={6} xxl={4}>
                      <ContentCard
                        data={item}
                        onClick={handleCardClick}
                        allowImageClick
                      />
                    </Col>
                  );
                })}
                <Col span={24}>
                  {dataPagination.total && (
                    <Pagination
                      total={dataPagination.total}
                      showTotal={showTotal}
                      pageSize={pagination.limit}
                      current={pagination.page}
                      pageSizeOptions={[ '12', '24', '48' ]}
                      onChange={(page, size) => {
                        handleChangeTableParams({
                          current: page,
                          pageSize: size,
                        }, {}, {});
                      }}
                    />
                  )}
                </Col>
              </Row>
            )}
            {!isImagesFetching && isEmpty(images) && (
              <IntlMessages id="categories-view-images-empty" />
            )}
          </Spin>
        </Col>
      </Row>
    </>
  );
};

export default PageCategoryDetails;
