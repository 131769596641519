import React from 'react';
import { Form, FormItem, Select, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Alert, Button, Col, Spin } from 'antd';
import Yup from '../../../../vendor/yup';
import makeSelectFilterOption from '../../../../lib/makeSelectFilterOption';



const { Option } = Select;

const FormCreateEditRoleButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const FormCreateEditRoleButtonWr = styled.div`
  width: 150px;
  padding: 0 10px 12px 10px;
  margin-top: 20px;
`;


const validationSchema = Yup.object().shape({
  developer_id: Yup.number().nullable(),
});


const FormTestersMode = ({
  onSubmit,
  initialValues,
  isSubmitting,
  formErrors = [],
  usersList = [],
  isActiveTestMode,
  handleStopTestMode,
}) => {
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      isSubmitting={isSubmitting}
      initialValues={initialValues}
      initialErrors={formErrors}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { isValid, values, resetForm, dirty } = props;

        return (
          <Spin spinning={isSubmitting} tip="Applying data. Please wait...">
            <Form layout="vertical" style={{ width: '100%' }}>
              <Alert
                className='hp-mb-24'
                type="warning"
                showIcon
                closable
                message="Note:"
                description='Users test mode is sharing between browser tabs. You can stop test manually or it will be automatically stopped after application re-login.'
              />

              <Col span={24}>
                <FormItem
                  label="User:"
                  name='developer_id'
                >
                  <Select
                    showSearch
                    disabled={isSubmitting}
                    name="developer_id"
                    style={{ width: '100%' }}
                    placeholder="Select user you want to mask with"
                    value={values.developer_id}
                    optionFilterProp="children"
                    filterOption={(input, option) => makeSelectFilterOption(option.children, input)}
                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                  >
                    {usersList.map((user) => (
                      <Option key={user.id} value={user.id}>
                        {user.name}
                      </Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>

              <FormCreateEditRoleButtonsRow>
                {isActiveTestMode && (
                  <FormCreateEditRoleButtonWr>
                    <Button
                      style={{ width: '100%' }}
                      danger
                      type='secondary'
                      disabled={isSubmitting}
                      onClick={() => {
                        resetForm();
                        handleStopTestMode();
                      }}
                    >
                      Stop test
                    </Button>
                  </FormCreateEditRoleButtonWr>
                )}
                <FormCreateEditRoleButtonWr>
                  <SubmitButton
                    style={{ width: '100%' }}
                    disabled={!isValid || !dirty || isSubmitting}
                  >
                    Run test
                  </SubmitButton>
                </FormCreateEditRoleButtonWr>
              </FormCreateEditRoleButtonsRow>
            </Form>
          </Spin>
        );
      }}
    </Formik>
  );
};

FormTestersMode.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleStopTestMode: PropTypes.func.isRequired,
  usersList: PropTypes.array,
  isActiveTestMode: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  formErrors: PropTypes.array,
  values: PropTypes.shape({
    developer_id: PropTypes.number,
  }),
  isSubmitting: PropTypes.bool.isRequired,
};

export default FormTestersMode;
