import moment from 'moment/moment';
import isEmpty from 'lodash/isEmpty';
import {
  TYPE_ARRAY,
  TYPE_BOOLEAN,
  TYPE_COLOR,
  TYPE_DATE,
  TYPE_DICTIONARY,
  TYPE_DOUBLE,
  TYPE_IMAGE,
  TYPE_INTEGER,
  TYPE_STRING,
} from '../../../Constructors/const/templates';
import Yup from '../../../../vendor/yup';
import { targetValidation } from '../../../../const/targetValidation';
import { requiredMessage } from '../../../../const/system';



export const NEWS_TEMPLATE_CONTENT_TYPE = 'news';

export const fieldTypeToValidationRule = {
  [TYPE_STRING]: {
    value: Yup.string().required(requiredMessage),
  },
  [TYPE_BOOLEAN]: {
    value: Yup.boolean().oneOf([ false, true ]),
  },
  [TYPE_INTEGER]: {
    value: Yup.number().required(requiredMessage),
  },
  [TYPE_DOUBLE]: {
    value: Yup.number().required(requiredMessage),
  },
  [TYPE_DICTIONARY]: {
    value: Yup.number().required(requiredMessage),
  },
  [TYPE_ARRAY]: {
    value: Yup.array().required(requiredMessage),
  },
  [TYPE_IMAGE]: {
    value: Yup.mixed().required(requiredMessage),
  },
  [TYPE_COLOR]: {
    value: Yup.string().required(requiredMessage),
  },
  [TYPE_DATE]: {
    value: Yup.string().required(requiredMessage),
  },
};

export const mkValidationSchema = (initialValues = {}, dynamicData = {}) => Yup.lazy((values) => {
  return Yup.object().shape({
    status: Yup.bool().test(
      'status',
      'Status can not be On until template, title, published at, published to and any of required fields are empty',
      (value) => {

        if (!value) {
          return true;
        }

        return values?.template_id && values?.title
        && values?.published_at && values?.published_to;
      },
    ),
    template_id: Yup.number().required('Template is required'),
    title: Yup.string().nullable().max(2000),
    released_at: !values.is_published ? Yup.date().nullable() :
      Yup.string().required('Required to specify released at date')
        .test(
          'test_day_in_the_past',
          'Released at date must be same or after than yesterday',
          (value) => {
            if (!isEmpty(initialValues) && moment(value).isSame(moment(initialValues.released_at))) {
              return true;
            }

            return !moment(value).isBefore(moment(new Date())
              .subtract(1, 'days').startOf('day')
              .utc(false));
          },
        )
        .test(
          'test_date_after',
          'Released at date must be same or before than released to',
          (value) => {
            if (!isEmpty(values?.released_to) && !isEmpty(value)) {
              return moment(values?.released_to).isSameOrAfter(value);
            }
            return true;
          },
        ),
    released_to: !values.is_published ? Yup.date().nullable() :
      Yup.string().required('Required to specify released to date')
        .test(
          'test_day_in_the_past',
          'Released to date must be same or after than yesterday',
          (value) => {
            if (!isEmpty(initialValues) && moment(value).isSame(moment(initialValues.released_to))) {
              return true;
            }

            return !moment(value).isBefore(moment(new Date())
              .subtract(1, 'days').startOf('day')
              .utc(false));
          },
        )
        .test(
          'test_date_before',
          'Released to date must be same or after than released at',
          (value) => {
            if (!isEmpty(values?.released_at) && !isEmpty(value)) {
              return moment(values?.released_at).isSameOrBefore(value);
            }
            return true;
          },
        ),
    is_published: Yup.bool().nullable(),
    target: targetValidation,
    ...dynamicData,
  });
});
