const getFullFieldName = (object, name = '', separator = ' -> ') => {
  let fullName = object.name;

  if (name !== '') {
    fullName = `${fullName}${separator}${name}`;
  }

  if (object.parent && object.parent.data) {
    return getFullFieldName(object.parent.data, fullName, separator);
  }
  return fullName;

};

export default getFullFieldName;
