const dictionaries = {
  'dictionaries-breadcrumbs': 'Dictionaries',
  'dictionaries-breadcrumbs-import': 'Import',
  'dictionaries-breadcrumbs-import-success': 'Success',
  'dictionaries-filter-favorite-label': 'Favorite',
  'dictionaries-status-ready': 'Ready',
  'dictionaries-status-draft': 'Draft',
  'ui-dictionaries-create': 'Create dictionary',
  'ui-dictionaries-label-field': 'Label',
  'ui-dictionaries-source-field': 'Source',
  'ui-dictionaries-version-field': 'Version',
  'ui-dictionaries-tags-field': 'Tags',
  'form-dictionaries-name-placeholder': 'Name',
  'form-dictionaries-source-placeholder': 'Select a source',
  'form-dictionaries-structure-placeholder': 'Select a structure version',
  'form-dictionaries-source-field': 'Source',
  'form-dictionaries-structure-field': 'Structure version',
  'form-dictionaries-label-field': 'Label',
  'form-dictionaries-validation-field': 'Validation rules',
  'form-dictionaries-upgrade-field': 'Upgrade minor version to latest',
  'form-dictionaries-created-from-field': 'Created from',
  'form-dictionaries-created-to-field': 'Created to',
  'form-dictionaries-field-field': 'Field',
  'form-dictionaries-field-value-field': 'Field value',
  'form-dictionaries-field-placeholder': 'Select a field',
  'dictionaries-create-title': 'Dictionary details',
  'dictionaries-preview-structure-description': 'Description',
  'dictionaries-preview-structure-fields': 'Fields',
  'dictionaries-preview-structure-languages': 'Languages',
  'dictionaries-update-status-confirm-message': 'Are you sure you want to make the dictionary available for publication?',
  'dictionaries-update-status-confirm-message-danger': 'After confirming the action, all empty fields will be filled with values from the English language',
  'dictionaries-new-version-available': 'New minor version is available',
  'dictionaries-filter-title': 'Dictionaries filter',
  'translations-edit-button': 'Edit dictionary',
  'ui-dictionaries-status-ready': 'Mark as Ready',
  'dictionaries-missed-translations': 'No translations have been added yet.',
  'dictionaries-translation-preview': 'Preview dictionary translations',
  'dictionaries-import-title': 'Import',
  'dictionaries-export-title': 'Export',
  'dictionaries-export-template': 'Template export',
  'dictionaries-form-file-label': 'File',
  'form-dictionaries-version-placeholder': 'Select a version',
  'form-dictionaries-version-label': 'Version',
  'dictionaries-import-failed': 'Dictionaries import failed',
  'dictionaries-imported-list': 'Imported dictionaries',
  'dictionaries-structure-label': 'Structure',
  'dictionaries-import-success': 'Dictionaries imported successfully',
  'dictionaries-import-card-title': 'Import dictionaries',
  'dictionaries-import-page-failed': 'Oops! Something went wrong!',
  'dictionaries-table-delete-btn-confirm-message': 'Are you sure to delete dictionary?',
  'dictionaries-deleted-successfully': 'Dictionary deleted',
  'dictionaries-empty-notification': 'Unable to view dictionary details, there is no created dictionary for current data',
};

export default dictionaries;
