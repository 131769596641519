import React, { useCallback, useState } from 'react';
import { Card, Col, Input, Modal, Row, Spin, Table } from 'antd';
import { RiAddLine, RiCloseFill } from 'react-icons/ri';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import { Search } from 'react-iconly';
import { useIntl } from 'react-intl';
import useQueryParams from '../../../../app/hooks/useQueryParams';
import useToggle from '../../../../app/hooks/useToggle';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import {
  useCreateLanguageMutation,
  useGetLanguagesQuery, useUpdateLanguageMutation,
} from '../../api/languagesApiSlice';
import { getLanguageListTableColumns } from './PageLanguages.const';
import BreadCrumbs from '../../../../layout/components/breadcrumbs';
import FormCreateEditLanguage from '../../forms/FormCreateEditLanguage';
import { HasRights } from '../../../../components/has-rights/HasRights';
import { Permissions } from '../../../../const/permissions';
import ActionButton from '../../../../layout/components/action-button';
import basePagination from '../../../../const/pagination';



const PageLanguages = () => {
  const intl = useIntl();
  const [ isActiveLanguageModal, toggleModal ] = useToggle();
  const [ createLanguageErrors, setFormErrors ] = useState({});
  const [ modalInitValues, setModalInitValues ] = useState({});

  const {
    pagination,
    search,
    setSearchTerm,
    searchParams,
    handleChangeTableParams,
  } = useQueryParams({ searchFields: [ 'name', 'code' ] });

  const { data = { data: [], pagination: {} }, isLoading } = useGetLanguagesQuery({
    queryParams: searchParams.toString(),
  });

  const [ updateLanguage, { isLoading: isUpdateLanguageLoading } ] = useUpdateLanguageMutation();
  const [ createLanguage, { isLoading: isCreateLanguageLoading } ] = useCreateLanguageMutation();


  const onOpenCreateModal = () => {
    setModalInitValues({});
    toggleModal();
  };

  const onOpenUpdateModal = (data) => {
    setModalInitValues(data);
    toggleModal();
  };

  const onCancelModal = () => {
    toggleModal();
    setFormErrors({});
  };

  const handleSubmitForm = async (values) => {
    try {
      if (!modalInitValues?.id) {
        await createLanguage(values).unwrap().then(() => {
          toggleModal();
        });
      } else {
        await updateLanguage({ id: modalInitValues.id,
          values: {
            name: values.name,
            code: values.code,
          } }).unwrap().then(() => {
          toggleModal();
        });
      }
    } catch (error) {
      if (isObject(error?.data?.errors)) {
        setFormErrors(error.data.errors);
      }
    }
  };

  const handleStatusChange = (id, values) => {
    updateLanguage({ id, values });
  };

  const handleSearch = useCallback((event) => {
    setSearchTerm(event.target.value);
  }, []);


  return (
    <>
      <Modal
        title={<IntlMessages id="ui-general-create" />}
        width={416}
        centered
        destroyOnClose
        visible={isActiveLanguageModal}
        onCancel={onCancelModal}
        footer={null}
        closeIcon={
          <RiCloseFill className="remix-icon text-color-black-100" size={24} />
        }
      >
        <Spin spinning={isCreateLanguageLoading || isUpdateLanguageLoading}>
          <FormCreateEditLanguage
            initialValues={modalInitValues}
            formErrors={createLanguageErrors}
            onSubmit={handleSubmitForm}
            onCancel={onCancelModal}
            isSubmitting={isCreateLanguageLoading}
          />
        </Spin>
      </Modal>

      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs breadCrumbActive={<IntlMessages id='languages-breadcrumbs' />} />

        <Col md={15} span={24}>
          <Row justify="end" align="middle" gutter={[ 16, 16 ]}>
            <Col md={12} span={24}>
              <Input
                allowClear={!isEmpty(search)}
                placeholder={intl.formatMessage({ id: 'languages-search-by' })}
                prefix={<Search set="curved" size={16} className="hp-text-color-black-80" />}
                value={search}
                onChange={handleSearch}
              />
            </Col>

            {HasRights([ Permissions.ADMINISTRATION.LANGUAGES.LANGUAGES_LIST.CREATE ]) && (
              <Col>
                <ActionButton
                  title={<IntlMessages id="ui-general-create" />}
                  icon={<RiAddLine />}
                  onClick={onOpenCreateModal}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>

      <Row gutter={32}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <Table
              sticky
              loading={isLoading}
              rowKey="id"
              columns={getLanguageListTableColumns(handleStatusChange, onOpenUpdateModal)}
              dataSource={data.languages}
              onChange={handleChangeTableParams}
              pagination={{
                ...basePagination,
                current: pagination.page,
                pageSize: pagination.limit,
                total: data.pagination?.total,
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};


export default PageLanguages;
