import React from 'react';
import { Popconfirm, Row, Tag, Tooltip } from 'antd';
import { PiPencil } from 'react-icons/pi';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Delete } from 'react-iconly';
import { Eye } from 'iconsax-react';
import { RiErrorWarningLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { PrivateComponent } from '../../../../components/has-rights/HasRights';
import { Permissions } from '../../../../const/permissions';
import getFormattedDate from '../../../../lib/getFormattedDate';
import urlPageTranslationDetails from '../../../../urls/urlPageTranslationDetails';
import { DATE_TIME_FORMAT, EMPTY_IMAGE_PLACEHOLDER, EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';



export const NEWS_STATUS_MAP = {
  ON: 'on',
  OFF: 'off',
};


export const getNewsTableColumns = (handleView, handleEdit, handeDelete, languages, tableSize) => [
  {
    title: <IntlMessages id="ui-general-id" />,
    dataIndex: 'id',
    align: 'center',
    width: tableSize.width * 0.09,
    sorter: true,
  },
  {
    title: <IntlMessages id="ui-general-status" />,
    dataIndex: 'status',
    align: 'center',
    sorter: true,
    width: tableSize.width * 0.1,
    render: (value) => (<Tag color={value === NEWS_STATUS_MAP.ON ? 'success' : 'warning'}>{value}</Tag>),
  },
  {
    title: <IntlMessages id="news-table-content-column" />,
    dataIndex: 'icon',
    align: 'center',
    width: tableSize.width * 0.14,
    render: (value) => {
      return (
        <LazyLoadImage
          effect='blur'
          alt="news preview"
          src={value ?? EMPTY_IMAGE_PLACEHOLDER}
        />
      );
    },
  },
  {
    title: <IntlMessages id="news-table-dictionary-column" />,
    align: 'center',
    width: tableSize.width * 0.29,
    render: (item) => {
      if (item?.dictionary?.data?.id) {
        const lang = languages?.find((lang) => lang.code === 'ENG');

        if (!lang) {
          return null;
        }

        return (
          <Link to={urlPageTranslationDetails({ id: item?.dictionary?.data?.id })} >
            {item?.dictionary?.data?.structure?.data?.fields?.data?.map((field) => {
              const translation = item?.dictionary?.data?.translations.data.find((item) => {
                return item.structure_field_id === field.id && lang.id === item.language_id;
              });

              return (
                <p key={field.id} className="text-start hp-mb-0">
                  {translation?.text}
                </p>
              );
            })}
          </Link>
        );
      }

      if (item.legacy_dictionary) {
        return (
          <div className="text-start">
            <p className="hp-mb-4">
              {item.legacy_dictionary?.translation_english?.title}
              <br />
              {item.legacy_dictionary?.translation_english?.description}
            </p>
          </div>
        );
      }

      return <div className="text-start">{EMPTY_VALUE_PLACEHOLDER}</div>;
    },
  },
  {
    title: <IntlMessages id="ui-general-published-from" />,
    dataIndex: 'published_at',
    align: 'center',
    sorter: true,
    width: tableSize.width * 0.125,
    render: (value) => getFormattedDate(value, DATE_TIME_FORMAT),
  },
  {
    title: <IntlMessages id="ui-general-published-to" />,
    dataIndex: 'published_to',
    align: 'center',
    sorter: true,
    width: tableSize.width * 0.125,
    render: (value) => getFormattedDate(value, DATE_TIME_FORMAT),
  },
  {
    title: <IntlMessages id="ui-general-action" />,
    key: 'action',
    align: 'center',
    width: tableSize.width * 0.13,
    render: (item) => {
      return (
        <Row className="da-h-100" align="middle" justify="center">
          <PrivateComponent allowedPermissions={[ Permissions.CONTENT.NEWS.FIND ]}>
            <Tooltip placement="top" title={<IntlMessages id="ui-general-view" />}>
              <div className="hp-text-right">
                <Eye
                  onClick={() => {
                    handleView(item);
                  }}
                  size={20}
                  className="hp-cursor-pointer hp-transition hp-hover-text-color-primary-1 hp-text-color-black-80 hp-m-4"
                />
              </div>
            </Tooltip>
          </PrivateComponent>
          <PrivateComponent allowedPermissions={[ Permissions.CONTENT.NEWS.EDIT ]}>
            {!item.id_dictionary && (
              <Tooltip placement="top" title={<IntlMessages id="ui-general-edit" />}>
                <div className="hp-text-right">
                  <PiPencil
                    onClick={() => {
                      handleEdit(item);
                    }}
                    size={20}
                    className="hp-cursor-pointer hp-transition hp-hover-text-color-warning-1 hp-text-color-black-80 hp-m-4"
                  />
                </div>
              </Tooltip>
            )}
          </PrivateComponent>
          <PrivateComponent allowedPermissions={[ Permissions.CONTENT.NEWS.DELETE ]}>
            {item.status === NEWS_STATUS_MAP.OFF && (
              <Popconfirm
                title={<IntlMessages id="news-table-delete-btn-confirm-message" />}
                placement="top"
                onConfirm={() => {
                  handeDelete(item.id);
                }}
                okText={<IntlMessages id="ui-general-yes" />}
                cancelText={<IntlMessages id="ui-general-no" />}
                icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
                okButtonProps={{ danger: true }}
              >
                <Tooltip placement="top" title={<IntlMessages id="ui-general-delete" />}>
                  <div className="hp-text-right">
                    <Delete
                      size={20}
                      className="hp-cursor-pointer hp-transition hp-hover-text-color-danger-1 hp-text-color-black-80 hp-m-4"
                    />
                  </div>
                </Tooltip>
              </Popconfirm>
            )}
          </PrivateComponent>
        </Row>
      );
    },
  },
];
