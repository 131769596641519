const imageStatuses = {
  release_on: 'release_on',
  ready_for_release: 'ready_for_release',
  bonus: 'bonus',
  archive: 'archive',
  achievement: 'achievement',
  pack: 'pack',
  daily: 'daily',
  dismissed: 'dismissed',
};

export {
  imageStatuses,
};
