import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AutoComplete, Button, Dropdown, Input, Menu } from 'antd';
import { DownOutlined, FullscreenOutlined } from '@ant-design/icons';
import uniqueId from 'lodash/uniqueId';
import isUndefined from 'lodash/isUndefined';
import FieldProgress from '../FieldProgress/FieldProgress';
import useDebounce from '../../../../app/hooks/useDebounce';
import { useGetTranslationsQuery } from '../../../Dictionary/api/dictionariesApiSlice';



const AutocompleteTranslationField = ({
  field,
  progress,
  min,
  max,
  handleExpand,
  availableLanguages,
  dictionaryDetails,
  handleUpdateValue,
  handleSelect,
}) => {
  const [ search, setSearch ] = useState('');
  const searchQuery = useDebounce(search.trim(), 300);
  const [ prevTranslationData, setPrevTranslationData ] = useState([]);
  const [ options, setOptions ] = useState([]);

  const { data } = useGetTranslationsQuery({
    queryParams: `search=language_id:1;by_structure_field:${field.field_id};language_id:${field.language_id};text:${searchQuery}&searchJoin=and&limit=10`,
  }, { skip: isUndefined(searchQuery) });

  const key = `${field.language_id}:${field.structure_field_id}`;
  const prevTranslations = prevTranslationData.find((el) => el.id === field.field_id) ?? {};

  useEffect(() => {
    const structure = dictionaryDetails?.structure?.data ?? {};

    setPrevTranslationData(structure.fields.data.map((el) => {
      const translations = el.translations.data
        .filter((tr) => tr.language_id === availableLanguages.find((lng) => lng.code === 'ENG')?.id)
        .filter((tr) => tr.dictionary_id !== dictionaryDetails.id);

      return {
        loading: false,
        data: translations,
        id: el.id,
      };
    }));
  }, [ availableLanguages, dictionaryDetails ]);

  useEffect(() => {
    setOptions(data?.data.map((item) => {
      return {
        value: item.text,
        id: item.dictionary_id,
      };
    }));
  }, [ data?.data ]);

  const onSearch = useCallback((searchText) => {
    setSearch(searchText);
  }, [ search ]);

  const onSelect = useCallback((data) => {
    const selectedValue = options.find((item) => item.value === data);

    handleSelect(selectedValue.id, field);
  }, [ field ]);

  const onChange = useCallback((value) => {
    handleUpdateValue(value, field);
  }, [ field ]);

  const getText = (text) => {
    if (!text) {
      return <span />;
    }

    return (
      <span
        dangerouslySetInnerHTML={{
          __html: text.replaceAll(search, `<span style='color:#0010f7;font-weight:bold;'>${search}</span>`),
        }}
      />
    );
  };

  const dropdownMenu = (
    <div className='ant-dropdown-menu ant-dropdown-menu-bordered' >
      <Menu
        selectedKeys={[]}
      >
        {prevTranslations?.data?.map((tr, index) => (
          <Menu.Item
            key={uniqueId()}
            onClick={() => {
              handleSelect(tr.dictionary_id, field);
            }}
            className="translation-suggestion-item"
          >
            {index + 1}
            {'. '}
            { getText(tr.text) }
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );

  return (
    <>
      <Input.Group compact>
        <AutoComplete
          style={{ width: 'calc(100% - 30px)' }}
          options={options}
          onSelect={onSelect}
          onSearch={onSearch}
          onChange={onChange}
          value={field.text}
          key={key}
        >
          <Input
            style={{ borderTopRightRadius: '0px', borderBottomRightRadius: '0px' }}
            suffix={(<FullscreenOutlined onClick={() => handleExpand(field)} style={{ color: 'rgba(0,0,0,.45)' }} />)}
          />
        </AutoComplete>

        <Dropdown
          overlay={dropdownMenu}
          placement="bottomRight"
          trigger='click'
          disabled={!prevTranslations?.data?.length}
        >
          <Button
            style={{ width: '30px' }}
            icon={<DownOutlined />}
          />
        </Dropdown>
      </Input.Group>

      {progress && (min || max) ? (
        <FieldProgress text={field.text} min={min} max={max} />
      ) : ''}
    </>
  );
};


AutocompleteTranslationField.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.any,
    field_id: PropTypes.number,
    language_id: PropTypes.number,
    structure_field_id: PropTypes.number,
    dictionary_id: PropTypes.number,
    text: PropTypes.string,
  }).isRequired,
  prevTranslations: PropTypes.shape({
    id: PropTypes.number,
    data: PropTypes.array,
  }),
  progress: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  handleExpand: PropTypes.func,
  handleUpdateValue: PropTypes.func,
  handleSearch: PropTypes.func,
  handleSelect: PropTypes.func,
  dictionaryDetails: PropTypes.object,
  availableLanguages: PropTypes.array,
};

AutocompleteTranslationField.defaultProps = {
  progress: true,
  min: 0,
  max: 0,
};

export default AutocompleteTranslationField;
