import React from 'react';
import { Outlet } from 'react-router-dom';
import { Button, Col, Drawer, Dropdown, Menu, Modal, Row, Spin } from 'antd';
import { RiCloseFill, RiMenuFill, RiMore2Line } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import MenuProfile from '../../components/MenuProfile';
import BreadCrumbs from '../../../../layout/components/breadcrumbs';
import useToggle from '../../../../app/hooks/useToggle';
import FormUploadAvatar from '../../forms/FormUploadAvatar/FormUploadAvatar';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { LOADING_TIP_PLACEHOLDER } from '../../../../const/system';
import { useUploadAvatarMutation } from '../../api/profileApiSlice';
import { refreshUserData, selectUserData } from '../../../Auth/features/authSlice';
import { PrivateComponent } from '../../../../components/has-rights/HasRights';
import { Permissions } from '../../../../const/permissions';



const PageProfile = () => {
  const [ isDrawerActive, toggleDrawer ] = useToggle();
  const [ isUploadAvatarModalActive, toggleUploadAvatarModal ] = useToggle();
  const { user: profile } = useSelector(selectUserData);
  const [ uploadAvatar, { isLoading } ] = useUploadAvatarMutation();
  const dispatch = useDispatch();
  const intl = useIntl();

  const rateMenu = (
    <Menu>
      <Menu.Item key="0" onClick={toggleUploadAvatarModal}>
        <IntlMessages id="profile-change-user-avatar" />
      </Menu.Item>
    </Menu>
  );

  const moreBtn = () => {
    return (
      <div>
        <PrivateComponent allowedPermissions={[ Permissions.ADMINISTRATION.USERS.USER_DETAILS.UPLOAD_AVATAR ]}>
          <Dropdown overlay={rateMenu} placement="bottomLeft">
            <Button
              type="text"
              icon={<RiMore2Line className="hp-text-color-black-100 hp-text-color-dark-0" size={24} />}
            />
          </Dropdown>
        </PrivateComponent>
      </div>
    );
  };

  const handleUploadAvatarForm = async (data) => {
    const formData = new FormData();

    formData.append('avatar', data.avatar);

    try {
      const user = await uploadAvatar({
        userId: profile?.id,
        formData,
      }).unwrap();

      dispatch(refreshUserData(user));

      toggleUploadAvatarModal();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  return (
    <Spin spinning={false} tip={LOADING_TIP_PLACEHOLDER}>
      <Row gutter={[ 32, 32 ]} className="hp-mb-32">
        <Modal
          title={intl.formatMessage({ id: 'form-upload-user-avatar-placeholder' })}
          width={416}
          centered
          visible={isUploadAvatarModalActive}
          onCancel={toggleUploadAvatarModal}
          footer={null}
          closeIcon={
            <RiCloseFill className="remix-icon text-color-black-100" size={24} />
          }
        >
          <FormUploadAvatar
            onSubmit={handleUploadAvatarForm}
            onCancel={toggleUploadAvatarModal}
            isSubmitting={isLoading}
          />
        </Modal>

        <Drawer
          title={moreBtn()}
          className="hp-profile-mobile-menu"
          placement="left"
          width={300}
          closable
          onClose={toggleDrawer}
          visible={isDrawerActive}
          closeIcon={
            <RiCloseFill
              className="remix-icon hp-text-color-black-80"
              size={24}
            />
          }
        >
          <MenuProfile
            profile={profile}
            onCloseDrawer={toggleDrawer}
          />
        </Drawer>

        <Col span={24}>
          <Row gutter={[ 32, 32 ]} justify="space-between">
            <BreadCrumbs breadCrumbActive={intl.formatMessage({ id: 'profile-title' })} />
          </Row>
        </Col>

        <Col span={24}>
          <Row className="hp-profile-mobile-menu-btn hp-bg-color-black-0 hp-bg-color-dark-100 hp-border-radius hp-py-12 hp-px-sm-8 hp-px-24 hp-mb-16">
            <Button
              className="-d-flex-justify-center"
              type="text"
              icon={
                <RiMenuFill
                  size={24}
                  className="remix-icon hp-text-color-black-80 hp-text-color-dark-30"
                />
              }
              onClick={toggleDrawer}
            />
          </Row>

          <Row className="hp-bg-color-black-0 hp-bg-color-dark-100 hp-border-radius hp-pr-sm-16 hp-pr-32">
            <MenuProfile
              moreBtn={moreBtn()}
              profile={profile}
            />

            <Col
              flex="1 1"
              className="hp-px-24 hp-py-sm-24 hp-py-32 hp-pb-24 hp-overflow-hidden"
            >
              <Outlet />
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default PageProfile;
