import React from 'react';
import { useDictionaries } from '../../providers/DictionariesProvider';
import PreviewStructure from '../../../Structures/components/PreviewStructure';



const PreviewDictionaryStructure = () => {
  const { languages, selectedStructure: structure } = useDictionaries();

  if (!structure?.source) {
    return null;
  }

  return (
    <PreviewStructure
      languages={languages}
      structure={structure}
    />
  );
};

export default PreviewDictionaryStructure;
