import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';
import { buildValidationRules } from './buildValidationRules';
import { TYPE_BOOLEAN, TYPE_COLOR, TYPE_DOUBLE, TYPE_INTEGER } from '../const/templates';



export const buildTemplateField = (values, wasCheckboxTouched) => {
  const validation = buildValidationRules(values);

  const node = {
    type: values.type,
    validation,
  };

  //@TODO: Rewrite func, add check and set not empty fields
  node.id = values?.id;
  if (values.hint_en) {
    node.hint_en = values.hint_en;
  }

  if (values.hint_ru) {
    node.hint_ru = values.hint_ru;
  }

  if (values.title_en) {
    node.title_en = values.title_en;
  }

  if (values.title_ru) {
    node.title_ru = values.title_ru;
  }

  node.use_in_preset = values.use_in_preset;

  if (values.subtype) {
    node.subtype = values.subtype;
  }

  if (values.value) {
    node.value = values.value;
  }

  if (!isNull(values.default_value)) {
    let defaultValue = values.default_value;

    if (values.type === TYPE_BOOLEAN && !wasCheckboxTouched) {
      defaultValue = null;
    }

    if (values.type === TYPE_COLOR) {
      defaultValue = isEmpty(defaultValue) ? '#ffffff' : defaultValue;
    }

    if (values.default_value) {
      if (values.type === TYPE_DOUBLE) {
        defaultValue = parseFloat(values.default_value);
      }

      if (values.type === TYPE_INTEGER) {
        defaultValue = parseInt(values.default_value, 10);
      }
    }

    node.default_value = defaultValue;
  } else {
    node.default_value = null;
  }

  return node;
};
