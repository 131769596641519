import React from 'react';
import { Formik } from 'formik';
import { Alert, Col, Collapse, DatePicker, Divider, Row, Spin, Tooltip, Typography } from 'antd';
import { Form, FormItem, Input, ResetButton, Select, SubmitButton, Switch } from 'formik-antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useGetPresetsQuery } from '../../../Presets/api/presetsApiSlice';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { targetPlatformOptions } from '../../../../const/system';
import { countries } from '../../../../const/countries';
import { DEFAULT_RELEASE_TIME } from '../../../Featured/forms/FormEditFeature/FormEditFeature.const';
import { templatesOptions } from '../../../Content/forms/FormDynamicNewsFilter/FormDynamicNewsFilter.const';
import isDateDisabled from '../../../../lib/isDateDisabled';
import useDatepickerHandler from '../../../../app/hooks/useDatepickerHandler';
import PresetPreview from '../../../../components/PresetPreview';
import mkMainAndOptionalTemplateFields from '../../../Constructors/utils/mkMainAndOptionalTemplateFields';
import CountrySelector from '../../../../components/CountrySelector';
import applyPreset from '../../../../lib/applyPreset';
import disableDateBeforeYesterday from '../../../../lib/disableDateBeforeYesterday';
import mkTemplateDynamicFormData from '../../../Content/utils/mkTemplateDynamicFormData';
import DynamicFormDataFields from '../../../Content/components/DynamicFormDataFields';



const { Title } = Typography;
const { Panel } = Collapse;


const FormEditPopup = ({
  isLoading,
  initialErrors,
  initialValues,
  templateFields,
  onSubmit,
  isSubmitting,
  validationSchema,
  templateOptions,
  popupsConfig = {},
  isEnabledTemplateStatus = false,
}) => {
  const intl = useIntl();
  const { onBlur } = useDatepickerHandler();

  if (!isEmpty(initialErrors)) {
    window.scrollTo(0, 0);
  }

  const {
    data: { data: presets } = { data: [] },
  } = useGetPresetsQuery({
    queryParams: `search=template_id:${initialValues?.template_id}`,
  }, { skip: !initialValues?.template_id });


  const { mainFields, optionalFields } = mkMainAndOptionalTemplateFields(templateFields);
  const defaultConfigUtcValue = popupsConfig?.publish?.enabled_utc0_field ?? false;
  const isDisabledUtcField = !popupsConfig?.publish?.show_utc0_field;
  const useDatePickerTime = popupsConfig?.publish?.use_time ?? false;
  const hasReleaseRange = popupsConfig?.release_range ?? false;

  return (
    <Formik
      enableReinitialize
      isSubmitting
      initialErrors={initialErrors}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        onSubmit(mkTemplateDynamicFormData(values, templateFields), resetForm);
      }}
    >
      {({ isValid, values, setFieldValue, dirty, setFieldTouched, resetForm }) => {
        const hasUnpublishedChanges = !moment(values?.published_at).isSame(moment(values?.updated_at));
        const preset = presets?.find((preset) => Number(preset.id) === Number(values.preset_id));

        return (
          <Form layout="vertical" >
            <Spin spinning={isSubmitting || isLoading}>
              <Title className="hp-mb-24" level={4}>{intl.formatMessage({ id: 'popups-breadcrumbs' })}</Title>

              <Row gutter={16}>
                <Col md={12} xs={24}>
                  <Row gutter={16}>
                    <Col span={24}>
                      <FormItem
                        name='title'
                        required
                        label={<IntlMessages id="ui-general-title" />}
                      >
                        <Input
                          name="title"
                          placeholder={intl.formatMessage({ id: 'ui-general-specify-value' })}
                        />
                      </FormItem>
                    </Col>

                    {hasReleaseRange ? (
                      <>
                        <Col span={24}>
                          <FormItem
                            label={intl.formatMessage({ id: 'ui-general-released-at' })}
                            name='released_at'
                            required={values?.is_published}
                          >
                            <DatePicker
                              name='released_at'
                              showToday
                              showTime={useDatePickerTime
                                ? { defaultValue: moment(DEFAULT_RELEASE_TIME, 'HH:mm:ss') }
                                : false}
                              disabledDate={(date) => isDateDisabled(
                                date,
                                [],
                                !isEmpty(values?.released_to)
                                && date.isAfter(moment(values?.released_to)),
                              )}
                              value={values?.released_at ? moment(values.released_at).utc(false) : null}
                              onChange={async (date, dateString) => {
                                await setFieldValue('released_at', !isEmpty(dateString) ? moment(dateString).utc(true) : '');
                              }}
                              onBlur={async ({ target }) => {
                                await setFieldTouched('released_at', true);
                                await onBlur('released_at', !isEmpty(target.value) ? moment(target.value).utc(true) : '', setFieldValue);
                              }}
                            />
                          </FormItem>
                        </Col>
                        <Col span={24}>
                          <FormItem
                            label={intl.formatMessage({ id: 'ui-general-released-to' })}
                            name='released_to'
                          >
                            <DatePicker
                              name='released_to'
                              showTime={useDatePickerTime
                                ? { defaultValue: moment(DEFAULT_RELEASE_TIME, 'HH:mm:ss') }
                                : false}
                              disabledDate={(date) => isDateDisabled(
                                date,
                                [],
                                !isEmpty(values?.released_at) && date.isBefore(moment(values?.released_at)),
                              )}
                              value={values?.released_to ? moment(values.released_to).utc(false) : null}
                              onChange={async (date, dateString) => {
                                await setFieldValue('released_to', !isEmpty(dateString) ? moment(dateString).utc(true) : '');
                              }}
                              onBlur={async ({ target }) => {
                                await setFieldTouched('released_to', true);
                                await onBlur('released_to', !isEmpty(target.value) ? moment(target.value).utc(true) : '', setFieldValue);
                              }}
                            />
                          </FormItem>
                        </Col>
                      </>
                    ) : (
                      <Col span={24}>
                        <FormItem
                          label={intl.formatMessage({ id: 'ui-general-released-at' })}
                          name='released_at'
                          required={values?.is_published}
                        >
                          <DatePicker
                            name='released_at'
                            showToday
                            showTime={useDatePickerTime
                              ? { defaultValue: moment(DEFAULT_RELEASE_TIME, 'HH:mm:ss') }
                              : false}
                            disabledDate={disableDateBeforeYesterday}
                            value={values?.released_at ? moment(values.released_at) : null}
                            onChange={async (date, dateString) => {
                              await setFieldValue('released_at', dateString);
                            }}
                            onBlur={async ({ target }) => {
                              await setFieldTouched('released_at', true);
                              await onBlur('released_at', target.value, setFieldValue);
                            }}
                          />
                        </FormItem>
                      </Col>
                    )}

                    <Col span={24}>
                      <FormItem
                        label={intl.formatMessage({ id: 'ui-general-template' })}
                        name='template_id'
                        required
                      >
                        <Select
                          loading={false}
                          allowClear
                          disabled
                          name="template_id"
                          options={templateOptions}
                          placeholder={intl.formatMessage({ id: 'ui-general-choose-template' })}
                        />
                      </FormItem>
                    </Col>

                    <Col span={24}>
                      <FormItem
                        label={intl.formatMessage({ id: 'ui-general-preset' })}
                        extra={intl.formatMessage({ id: 'presets-template-hint' })}
                        name='preset_id'
                      >
                        <Select
                          loading={false}
                          allowClear
                          name="preset_id"
                          onChange={async (value) => {
                            await applyPreset(value, values, setFieldValue, presets, templateFields);
                          }}
                          options={templatesOptions(presets)}
                          placeholder={intl.formatMessage({ id: 'ui-general-choose-preset' })}
                        />
                      </FormItem>
                    </Col>

                    <Col span={24}>
                      <FormItem
                        name='use_utc_zero_time'
                        label={
                          <Row className='w-full' justify="space-between" align="middle">
                            <IntlMessages id="features-use-utc" />
                            <Tooltip
                              placement="top"
                              title={
                                <>
                                  <IntlMessages id="features-use-utc-info" />
                                  <div>
                                    <Link to='https://x-flow-ltd.atlassian.net/wiki/spaces/HCD/pages/734461960/Use+UTC+0' target="_blank">
                                      <u><IntlMessages id="ui-general-documentation-link" /></u>
                                    </Link>
                                  </div>
                                </>
                              }
                            >
                              <InfoCircleOutlined className='hp-ml-8' />
                            </Tooltip>
                          </Row>
                        }
                      >
                        <Switch
                          name="use_utc_zero_time"
                          defaultChecked={defaultConfigUtcValue}
                          disabled={isDisabledUtcField}
                        />
                      </FormItem>
                    </Col>
                  </Row>
                </Col>
                <Col md={12} xs={24}>
                  <PresetPreview src={preset?.preview} />
                </Col>
              </Row>

              <Divider />
              <Title className="hp-mb-24" level={4}><IntlMessages id="ui-general-template-data" /></Title>
              {templateFields.length ? (
                <Row>
                  <Col span={12}>
                    <DynamicFormDataFields
                      setFieldValue={setFieldValue}
                      fields={mainFields}
                      values={values}
                      span={24}
                    />
                    {!!optionalFields.length && (
                      <Collapse>
                        <Panel
                          key="1"
                          header={
                            <Title level={5} className='collapse-item-title'>
                              <IntlMessages id="ui-general-additional-parameters" />
                            </Title>
                          }
                        >
                          <DynamicFormDataFields
                            setFieldValue={setFieldValue}
                            fields={optionalFields}
                            values={values}
                            span={24}
                          />
                        </Panel>
                      </Collapse>
                    )}
                  </Col>
                </Row>
              ) : (
                <Row gutter={16} className="hp-d-flex-justify-center"><Spin spinning /></Row>
              )}

              <Divider />
              <Title className="hp-mb-24" level={4}><IntlMessages id="features-form-collapse-title-targets" /></Title>

              <Row gutter={[ 16, 16 ]}>
                <Col span={12}>
                  <FormItem
                    label={intl.formatMessage({ id: 'ui-general-min-version' })}
                    name='target.min_version'
                  >
                    <Input
                      name="target.min_version"
                      placeholder={intl.formatMessage({ id: 'dynamic-news-create-form-targets-min-version-placeholder' })}
                    />
                  </FormItem>
                </Col>

                <Col span={12}>
                  <FormItem
                    label={intl.formatMessage({ id: 'ui-general-max-version' })}
                    name='target.max_version'
                  >
                    <Input
                      name="target.max_version"
                      placeholder={intl.formatMessage({ id: 'dynamic-news-create-form-targets-max-version-placeholder' })}
                    />
                  </FormItem>
                </Col>

                <Col span={24}>
                  <FormItem
                    label={intl.formatMessage({ id: 'ui-general-platform' })}
                    name='target.platform'
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      name="target.platform"
                      options={targetPlatformOptions}
                      placeholder={intl.formatMessage({ id: 'dynamic-news-create-form-targets-platform-placeholder' })}
                    />
                  </FormItem>
                </Col>

                <Col span={24}>
                  <FormItem
                    label={intl.formatMessage({ id: 'ui-general-countries' })}
                    name='target.countries'
                    required
                  >
                    <CountrySelector
                      onSelectAll={async () => {
                        await setFieldValue(
                          'target.countries',
                          countries.map((country) => country.value).sort(),
                        );
                      }}
                      onSelectAllDisabled={values?.target?.countries?.length === countries.length}
                      setFieldValue={setFieldValue}
                      name='target.countries'
                      placeholder={intl.formatMessage({ id: 'dynamic-news-create-form-targets-countries-placeholder' })}
                    />
                  </FormItem>
                </Col>
              </Row>

              {(dirty || hasUnpublishedChanges) && (
                <Alert
                  message={<IntlMessages id="features-edit-has-changes" />}
                  description={
                    <>
                      <Row justify='end' className='hp-mt-32'>
                        <Col>
                          <Row>
                            <IntlMessages id="features-edit-publish-to-hc" />
                            <Switch disabled={!isEnabledTemplateStatus} name="is_published" className='hp-ml-16' />
                          </Row>
                        </Col>
                      </Row>
                      {!isEnabledTemplateStatus && (
                        <Row>
                          <div className='error-text'>
                            <IntlMessages id='features-disabled-template-text-error' />
                          </div>
                        </Row>
                      )}
                    </>
                  }
                  type="info"
                  showIcon
                />
              )}

              <Row gutter={[ 16, 16 ]} className='hp-mt-16' justify='end'>
                <Col>
                  <ResetButton
                    disabled={false}
                    onClick={() => {
                      resetForm(initialValues);
                    }}
                  >
                    <IntlMessages id='ui-general-reset' />
                  </ResetButton>
                </Col>

                <Col>
                  <SubmitButton
                    type="primary"
                    loading={isSubmitting}
                    disabled={!isValid}
                  >
                    <IntlMessages id="ui-general-save" />
                  </SubmitButton>
                </Col>
              </Row>
            </Spin>
          </Form>
        );
      }}
    </Formik>
  );
};

FormEditPopup.propTypes = {
  initialValues: PropTypes.object.isRequired,
  initialErrors: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  templateFields: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  isEnabledTemplateStatus: PropTypes.bool,
  popupsConfig: PropTypes.object,
  templateOptions: PropTypes.array,
};


export default FormEditPopup;
