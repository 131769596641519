const news = {
  'news-breadcrumbs': 'Old news',
  'news-table-content-column': 'Content',
  'news-table-dictionary-column': 'Dictionary',
  'news-created-successfully': 'News created',
  'news-created-failed': 'Create news failed',
  'news-updated-successfully': 'News updated',
  'news-updated-failed': 'Update news failed',
  'news-deleted-successfully': 'News deleted',
  'news-deleted-failed': 'Delete news failed',
  'news-table-delete-btn-confirm-message': 'Are you sure you want to delete this news?',
  'news-preview-title': 'Preview news',
  'news-create-title': 'Create old news',
  'news-update-title': 'Edit old news',
  'news-filter-title': 'Filter old news',
  'news-form-id-placeholder': 'News id',
  'news-form-translations-label': 'Translations',
  'news-form-status-placeholder': 'Choose status',
  'news-form-upload-icons-placeholder': 'Upload Icons (2 files)',
  'news-form-type-label': 'Type',
  'news-form-type-placeholder': 'Choose type',
  'news-create-form-target-label': 'Scheme/Url',
  'news-create-form-target-placeholder': '#target',
  'news-create-form-dictionary-label': 'Dictionary',
  'news-create-form-dictionary-placeholder': 'Choose dictionary',
  'news-create-form-icons-label': 'Icons',
  'news-create-form-type-label': 'Type',
  'news-create-form-type-placeholder': 'Choose type',
  'news-create-form-upload-description-label': 'Click or drag 2 files to this area for uploading',
};

export default news;
