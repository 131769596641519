import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Card, Col, Input, Modal, Row, Space, Table, Typography } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { Search } from 'react-iconly';
import { RiAddLine, RiCloseFill } from 'react-icons/ri';
import { Filter } from 'iconsax-react';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import { useSearchParams } from 'react-router-dom';
import useToggle from '../../../../app/hooks/useToggle';
import useQueryParams from '../../../../app/hooks/useQueryParams';
import { makeNotification, notificationTypes } from '../../../../lib/makeNotification';
import BreadCrumbs from '../../../../layout/components/breadcrumbs/index';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import ActionButton from '../../../../layout/components/action-button';
import Sidebar from '../../../../components/sidebar/Sidebar';
import {
  useCreateNewsMutation,
  useDeleteNewsMutation,
  useGetNewsQuery,
  useUpdateNewsMutation,
} from '../../api/newsApiSlice';
import { getNewsTableColumns } from './PageNews.const';
import FormCreateNews from '../../forms/FormCreateNews';
import NewsPreview from '../../components/NewsPreview';
import FormNewsFilter from '../../forms/FormNewsFilter';
import getQueryParams from '../../../../lib/getQueryParams';
import { useGetLanguagesQuery } from '../../../Language/api/languagesApiSlice';
import FormUpdateNews from '../../forms/FormUpdateNews';
import useElementSize from '../../../../app/hooks/useElementSize';
import basePagination from '../../../../const/pagination';



const { Title } = Typography;

const PageNews = () => {
  const intl = useIntl();
  const [ elementRef, size ] = useElementSize();
  const [ isVisibleCreateSidebar, toggleCreateSidebar ]  = useToggle();
  const [ isVisibleUpdateSidebar, toggleUpdateSidebar ]  = useToggle();
  const [ isVisibleFilterSidebar, toggleFilterSidebar ]  = useToggle();
  const [ isVisiblePreviewNews, togglePreviewModal ] = useToggle();
  const [ currentNews, setCurrentNews ] = useState({});
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const searchFields = [ 'title' ];
  const searchFilterFields = [ 'id', 'title', 'status', 'type', 'published_from', 'published_to' ];
  const [ initFilterValues, setInitFilterValues ] = useState({});
  const [ searchField, setSearchField ] = useState('');

  const {
    pagination,
    search,
    setSearchTerm,
    searchParams,
    handleChangeTableParams,
  } = useQueryParams({ searchFields, searchFilterFields, isFilter: true });

  const { data = { data: [], pagination: {} }, isFetching } = useGetNewsQuery({
    queryParams: `${searchParams.toString()}`,
  });

  const { data: languages = { languages: [] } } = useGetLanguagesQuery({
    queryParams: 'limit=0',
  });

  const [ createNews, { isLoading: isCreatingNews } ] = useCreateNewsMutation();
  const [ updateNews, { isLoading: isUpdatingNews } ] = useUpdateNewsMutation();
  const [ deleteNews, { isLoading: isDeletingNews } ] = useDeleteNewsMutation();

  useEffect(() => {
    setSearchField(search);
  }, []);

  const applyFilter = (values) => {
    const queryParams = {};

    Array.from(searchQueryParams.entries())
      .forEach(([ key, value ]) => {
        queryParams[key] = key === 'page' ? 1 : value;
      });

    queryParams.search = Object.entries(values)
      .map(([ field, value ]) => {
        if (searchFilterFields.includes(field)) {
          if ((isArray(value) && !value.length) || !value) {
            return '';
          }

          return isString(value) ? `${field}:${value.trim()}` : `${field}:${value}`;
        }
        return '';
      })
      .filter((item) => item !== '')
      .join(';');

    setSearchField('');
    setSearchParams(queryParams);
    toggleFilterSidebar();
  };

  const resetFilter = () => {
    const queryParams = {};

    Array.from(searchQueryParams.entries())
      .forEach(([ key, value ]) => {
        if (key === 'search') {
          return;
        }

        queryParams[key] = key === 'page' ? 1 : value;
      });

    setSearchField('');
    setSearchParams(queryParams);
    setInitFilterValues({});
  };

  useEffect(() => {
    const queryParams = getQueryParams(window.location.search);

    if (queryParams.search) {
      const initValues = {};

      queryParams.search.split(';').forEach((item) => {
        const [ key, value ] = item.split(':');

        if (value !== '') {
          initValues[key] = value;
        }
      });

      setInitFilterValues(initValues);
    }
  }, []);

  useEffect(() => {
    if (!searchParams.toString()) {
      setInitFilterValues({
        reset: true, // ugly hack
      });
    }
  }, [ searchParams ]);

  const handleSearch = useCallback((event) => {
    setSearchField(event.target.value);
    setSearchTerm(event.target.value);
  }, []);

  const handleCreateNews = (data, resetForm) => {
    createNews(data)
      .unwrap()
      .then(() => {
        toggleCreateSidebar();
        makeNotification(
          notificationTypes.success,
          intl.formatMessage({ id: 'ui-general-success' }),
          intl.formatMessage({ id: 'news-created-successfully' }),
        );
        resetForm();
      })
      .catch((error) => {
        makeNotification(
          notificationTypes.error,
          intl.formatMessage({ id: 'ui-general-error' }),
          <>
            <p className="hp-mb-4">{intl.formatMessage({ id: 'news-created-failed' })}</p>
            {error?.data?.message && <p>{ error.data.message }</p>}
          </>,
        );
      });
  };

  const handleUpdateNews = (id, data) => {
    toggleUpdateSidebar();
    updateNews({ id, values: data })
      .unwrap()
      .then(() => {
        makeNotification(
          notificationTypes.success,
          intl.formatMessage({ id: 'ui-general-success' }),
          intl.formatMessage({ id: 'news-updated-successfully' }),
        );
      })
      .catch((error) => {
        makeNotification(
          notificationTypes.error,
          intl.formatMessage({ id: 'ui-general-error' }),
          <>
            <p className="hp-mb-4">{intl.formatMessage({ id: 'news-updated-failed' })}</p>
            {error?.data?.message && <p>{ error.data.message }</p>}
          </>,
        );
      });
  };

  const handleView = (news) => {
    setCurrentNews(news);
    togglePreviewModal();
  };

  const handleClosePreview = () => {
    setCurrentNews({});
    togglePreviewModal();
  };

  const handleEdit = (news) => {
    setCurrentNews(news);
    toggleUpdateSidebar();
  };

  const handleDeleteNews = (id) => {
    deleteNews(id)
      .unwrap()
      .then(() => {
        makeNotification(
          notificationTypes.success,
          intl.formatMessage({ id: 'ui-general-success' }),
          intl.formatMessage({ id: 'news-deleted-successfully' }),
        );
      })
      .catch((error) => {
        makeNotification(
          notificationTypes.error,
          intl.formatMessage({ id: 'ui-general-error' }),
          <>
            <p className="hp-mb-4">{intl.formatMessage({ id: 'news-deleted-failed' })}</p>
            {error?.data?.message && <p>{ error.data.message }</p>}
          </>,
        );
      });
  };

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs breadCrumbActive={<IntlMessages id='news-breadcrumbs' />} />

        <Col md={16} span={24}>
          <Row
            gutter={[ 32, 32 ]}
            justify="end"
            align="middle"
          >
            <Col sm={12} span={24}>
              <Input
                allowClear={!isEmpty(search)}
                placeholder={intl.formatMessage({ id: 'ui-general-search' })}
                prefix={<Search set="curved" size={16} className="hp-text-color-black-80" />}
                value={searchField}
                onChange={handleSearch}
              />
            </Col>
            <Col>
              <Space>
                <ActionButton
                  title={<IntlMessages id='ui-general-create' />}
                  icon={<RiAddLine />}
                  onClick={toggleCreateSidebar}
                />
                <ActionButton
                  title=""
                  icon={<Filter size={18} />}
                  onClick={toggleFilterSidebar}
                />
              </Space>
            </Col>
          </Row>
        </Col>

        <Modal
          title={<IntlMessages id="news-preview-title" />}
          width="65vw"
          centered
          destroyOnClose
          visible={isVisiblePreviewNews}
          onCancel={handleClosePreview}
          footer={null}
          closeIcon={
            <RiCloseFill className="remix-icon text-color-black-100" size={24} />
          }
        >
          <NewsPreview data={currentNews} languages={languages.languages} />
        </Modal>

        <Sidebar
          title={<Title level={5} >{intl.formatMessage({ id: 'news-create-title' })}</Title>}
          visible={isVisibleCreateSidebar}
          toggleSidebar={toggleCreateSidebar}
        >
          <FormCreateNews
            isSubmitting={false}
            onSubmit={handleCreateNews}
            onCancel={() => {}}
          />
        </Sidebar>

        <Sidebar
          title={
            <Title level={5} >
              {intl.formatMessage({ id: 'news-update-title' })}
              . Id:
              &nbsp;
              {currentNews?.id}
            </Title>
          }
          visible={isVisibleUpdateSidebar}
          toggleSidebar={toggleUpdateSidebar}
        >
          <FormUpdateNews
            isSubmitting={false}
            initialValues={currentNews}
            onSubmit={handleUpdateNews}
            onCancel={() => {}}
          />
        </Sidebar>

        <Sidebar
          title={<Title level={5}>{intl.formatMessage({ id: 'news-filter-title' })}</Title>}
          visible={isVisibleFilterSidebar}
          toggleSidebar={toggleFilterSidebar}
        >
          <FormNewsFilter
            initialValues={initFilterValues}
            isSubmitting={false}
            onCancel={resetFilter}
            onSubmit={applyFilter}
          />
        </Sidebar>
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <div ref={elementRef}>
              <Table
                sticky
                scroll={{ x: size.width }}
                loading={isFetching || isCreatingNews || isUpdatingNews || isDeletingNews}
                rowKey="id"
                columns={getNewsTableColumns(handleView, handleEdit, handleDeleteNews, languages.languages, size)}
                dataSource={data.data}
                onChange={handleChangeTableParams}
                pagination={{
                  ...basePagination,
                  current: pagination.page,
                  pageSize: pagination.limit,
                  total: data.pagination?.total,
                }}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PageNews;
