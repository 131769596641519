import React from 'react';
import { Form, FormItem, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Col, Row, Upload, Typography, Spin } from 'antd';
import { RiUploadCloud2Line } from 'react-icons/ri';
import { useIntl } from 'react-intl';
import { PiUpload } from 'react-icons/pi';
import Yup from '../../../../vendor/yup';
import { FILE_SIZE, SUPPORTED_FORMATS, SUPPORTED_TYPES } from './FormImportDictionaries.const';
import { LOADING_TIP_PLACEHOLDER } from '../../../../const/system';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';



const { Text } = Typography;

const validationSchema = Yup.object().shape({
  list: Yup
    .mixed()
    .required('A file is required')
    .test(
      'fileSize',
      'File size is too large and should be less than 5Mb',
      (value) => {
        if (value.length) {
          return value && value[0]?.size <= FILE_SIZE;
        }

        return true;
      },
    ),
});


const FormImportDictionaries = ({
  onSubmit,
  isSubmitting,
  warningText = null,
}) => {
  const intl = useIntl();

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      isSubmitting={isSubmitting}
      initialValues={{
        file: null,
        list: [],
      }}
      onSubmit={async (values, { resetForm, setErrors }) => {
        await onSubmit(values);
        resetForm();
        setErrors({});
      }}
    >
      {({ values, errors, isValid, dirty, setFieldValue, setErrors }) => {
        return (
          <Form layout="vertical" style={{ width: '100%' }}>
            <Spin spinning={isSubmitting} tip={LOADING_TIP_PLACEHOLDER}>
              <FormItem
                label={intl.formatMessage({ id: 'dictionaries-form-file-label' })}
                name='file'
                valuePropName="file"
                required
              >
                <Upload.Dragger
                  name="avatar"
                  accept={SUPPORTED_FORMATS}
                  type={SUPPORTED_TYPES}
                  multiple={false}
                  maxCount={1}
                  listType="picture"
                  disabled={isSubmitting}
                  fileList={values.list}
                  onChange={(event) => {
                    setFieldValue('list', event.fileList);
                  }}
                  beforeUpload={(file) => {
                    setFieldValue('file', file);
                    return false;
                  }}
                  onRemove={async () => {
                    await setFieldValue('list', []);
                    await setFieldValue('file', null);
                    setErrors({});
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <RiUploadCloud2Line className="remix-icon" />
                  </p>

                  <p className="ant-upload-text">
                    <IntlMessages id="form-upload-user-avatar-description" />
                  </p>
                </Upload.Dragger>
                <div className="hp-mt-4">
                  <Text type="danger">{errors.list ? errors.list : null}</Text>
                </div>
                <div className="hp-mt-4">
                  {warningText && <Text type="warning">{warningText}</Text>}
                </div>
              </FormItem>

              <Row gutter={[ 16, 16 ]} justify='end'>
                <Col>
                  <SubmitButton
                    loading={isSubmitting}
                    disabled={!isValid || !dirty}
                  >
                    <PiUpload className="btn-icon-mr-1" />
                    <IntlMessages id="ui-general-import" />
                  </SubmitButton>
                </Col>
              </Row>
            </Spin>
          </Form>
        );
      }}
    </Formik>
  );
};

FormImportDictionaries.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  warningText: PropTypes.any,
};

export default FormImportDictionaries;
