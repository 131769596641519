import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, Space, Card, Table, Input } from 'antd';
import { Filter } from 'iconsax-react';
import isString from 'lodash/isString';
import { useSearchParams } from 'react-router-dom';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { Search } from 'react-iconly';
import { useIntl } from 'react-intl';
import { RiAddLine } from 'react-icons/ri';
import BreadCrumbs from '../../../../layout/components/breadcrumbs';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import basePagination from '../../../../const/pagination';
import { DEFAULT_SIDEBAR_WIDTH } from '../../../../const/system';
import { makeNotification, notificationTypes } from '../../../../lib/makeNotification';
import { getCategoriesTableColumns, SEARCH_FILTER_VALUES, SEARCH_VALUES } from './PageCategories.const';
import ActionButton from '../../../../layout/components/action-button';
import Sidebar from '../../../../components/sidebar/Sidebar';
import useToggle from '../../../../app/hooks/useToggle';
import useQueryParams from '../../../../app/hooks/useQueryParams';
import useResetFilter from '../../../../app/hooks/useResetFilter';
import getQueryParams from '../../../../lib/getQueryParams';
import TotalLabel from '../../../../components/TotalLabel';
import FormCategoriesFilter from '../../forms/FormCategoriesFilter';
import FormCreateEditCategories from '../../forms/FormCreateEditCategories';
import {
  useCreateCategoryMutation,
  useDeleteCategoryMutation,
  useGetCategoriesQuery,
  useUpdateCategoryMutation,
} from '../../api/categoriesApiSlice';



const categoryInitialStateValues = { status: false };

const PageCategories = () => {
  const intl = useIntl();

  const [ searchField, setSearchField ] = useState('');
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const [ initFilterValues, setInitFilterValues ] = useState({});
  const resetFilter = useResetFilter(searchQueryParams, setSearchParams, setInitFilterValues);

  const [ categoryInitialValues, setCategoryInitialValues ] = useState(categoryInitialStateValues);
  const [ categoryInitialErrors, setCategoryInitialErrors ] = useState({});
  const [ currentCategoryId, setCurrentCategoryId ] = useState(null);

  const [ filterOpen, toggleFilter ] = useToggle();
  const [ filterOpenCreateEditSidebar, toggleCreateEditSidebar ] = useToggle();

  const [ isEdit, setIsEdit ] = useState(false);

  const {
    pagination,
    searchParams,
    search,
    setSearchTerm,
    handleChangeTableParams,
  } = useQueryParams({
    searchFields: SEARCH_VALUES,
    isFilter: true,
  });

  const { data: {
    data: categories = [],
    pagination: dataPagination = {},
  } = { data: [], pagination: {} }, isFetching } = useGetCategoriesQuery({
    queryParams: `${searchParams.toString()}&include=dictionary`,
  });

  const [ deleteCategory, { isLoading: isDeletingCategory } ] = useDeleteCategoryMutation();
  const [ createCategory, { isLoading: isCreatingCategory } ] = useCreateCategoryMutation();
  const [ updateCategory, { isLoading: isUpdatingCategory } ] = useUpdateCategoryMutation();

  const applyFilter = (values) => {
    const queryParams = {};

    Array.from(searchQueryParams.entries())
      .forEach(([ key, value ]) => {
        queryParams[key] = key === 'page' ? 1 : value;
      });

    queryParams.search = Object.entries(values)
      .map(([ field, value ]) => {
        if (SEARCH_FILTER_VALUES.includes(field)) {
          if ((isArray(value) && !value.length) || !value) {
            return '';
          }

          return isString(value) ? `${field}:${value.trim()}` : `${field}:${value}`;
        }
        return '';
      })
      .filter((item) => item !== '')
      .join(';');

    setSearchParams(queryParams);
    toggleFilter();
  };

  useEffect(() => {
    const queryParams = getQueryParams(window.location.search);

    if (queryParams.search) {
      const initValues = {};

      queryParams.search.split(';').forEach((item) => {
        const [ key, value ] = item.split(':');

        if (value !== '') {
          initValues[key] = value;
        }

        if (SEARCH_VALUES.includes(key)) {
          setSearchField(value);
          setSearchTerm(value);
        }
      });

      setInitFilterValues(initValues);
    }
  }, []);

  useEffect(() => {
    if (!searchParams.toString()) {
      setInitFilterValues({
        reset: true, // ugly hack
      });
    }

    localStorage.setItem('indexSearchParams', searchParams.toString());
  }, [ searchParams ]);

  const handleSearch = useCallback((event) => {
    setSearchField(event.target.value);
    setInitFilterValues({
      reset: true,
    });
    setSearchTerm(event.target.value);
  }, []);

  const handleOpenSidebarOnCreate = () => {
    setIsEdit(false);
    toggleCreateEditSidebar();
    setCategoryInitialValues(categoryInitialStateValues);
    setCategoryInitialErrors({});
    setCurrentCategoryId(null);
  };

  const handleOpenSidebarOnEdit = (id) => {
    const editedCategory = categories.find((category) => category.id === id);

    setCategoryInitialValues({
      client_id: editedCategory.client_id,
      name: editedCategory.name,
      dictionary_id: editedCategory?.dictionary?.data?.id,
      status: editedCategory.status,
      description: editedCategory.description,
      options: editedCategory.options,
    });

    toggleCreateEditSidebar();
    setCategoryInitialErrors({});
    setIsEdit(true);
    setCurrentCategoryId(id);
  };

  const handleSubmit = (values) => {
    const action = isEdit
      ? updateCategory({ id: currentCategoryId, payload: values })
      : createCategory({ payload: values });

    action
      .unwrap()
      .then(() => {
        toggleCreateEditSidebar();
        setCategoryInitialValues(categoryInitialStateValues);
      })
      .then(() => {
        makeNotification(
          notificationTypes.success,
          intl.formatMessage({ id: 'ui-general-success' }),
          intl.formatMessage({ id: isEdit
            ? 'categories-update-successfully'
            : 'categories-create-successfully',
          }),
        );
      })
      .catch((error) => {
        makeNotification(
          notificationTypes.error,
          intl.formatMessage({ id: 'ui-general-error' }),
          <>
            <p className="hp-mb-4">
              {intl.formatMessage({ id: isEdit
                ? 'categories-update-failed'
                : 'categories-create-failed',
              })}
            </p>
            {error?.data?.message && <p>{ error.data.message }</p>}
          </>,
        );

        setCategoryInitialErrors(error?.data?.errors);
      });
  };

  const handleDelete = (id) => {
    deleteCategory(id)
      .unwrap()
      .then(() => {
        makeNotification(
          notificationTypes.success,
          intl.formatMessage({ id: 'ui-general-success' }),
          intl.formatMessage({ id: 'categories-delete-successfully' }),
        );
      })
      .catch((error) => {
        makeNotification(
          notificationTypes.error,
          intl.formatMessage({ id: 'ui-general-error' }),
          <>
            <p className="hp-mb-4">{intl.formatMessage({ id: 'categories-delete-failed' })}</p>
            {error?.data?.message && <p>{ error.data.message }</p>}
          </>,
        );
      });
  };

  const isLoading = isFetching || isDeletingCategory || isCreatingCategory || isUpdatingCategory;
  const currentDictionaryId = categories.find((category) => category.id === currentCategoryId)
    ?.dictionary?.data?.id;

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs breadCrumbActive={<IntlMessages id='categories-breadcrumbs' />} />

        <Col>
          <Space>
            <TotalLabel total={dataPagination.total ?? 0} />
            <Col>
              <Input
                allowClear={!isEmpty(search)}
                placeholder={intl.formatMessage({ id: 'ui-general-search' })}
                prefix={<Search set="curved" size={16} className="hp-text-color-black-80" />}
                value={searchField}
                onChange={handleSearch}
              />
            </Col>
            <ActionButton
              onClick={handleOpenSidebarOnCreate}
              icon={<RiAddLine />}
              title={<IntlMessages id='ui-general-create' />}
            />
            <ActionButton
              title=""
              icon={<Filter size={18} />}
              onClick={toggleFilter}
            />
          </Space>
        </Col>

        <Sidebar
          visible={filterOpen}
          toggleSidebar={toggleFilter}
          width={DEFAULT_SIDEBAR_WIDTH}
        >
          <FormCategoriesFilter
            initialValues={initFilterValues}
            onSubmit={applyFilter}
            onCancel={resetFilter}
            isSubmitting={isLoading}
          />
        </Sidebar>

        <Sidebar
          visible={filterOpenCreateEditSidebar}
          toggleSidebar={toggleCreateEditSidebar}
          width={DEFAULT_SIDEBAR_WIDTH}
          title={<IntlMessages id={isEdit ? 'ui-general-edit' : 'ui-general-create'} />}
        >
          <FormCreateEditCategories
            initialValues={categoryInitialValues}
            onSubmit={handleSubmit}
            isSubmitting={false}
            initialErrors={categoryInitialErrors}
            handleResetErrors={() => {
              setCategoryInitialErrors({});
            }}
            currentDictionaryId={currentDictionaryId}
          />
        </Sidebar>
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <Table
              sticky
              loading={isLoading}
              rowKey="id"
              columns={getCategoriesTableColumns(handleOpenSidebarOnEdit, handleDelete)}
              dataSource={categories}
              onChange={handleChangeTableParams}
              pagination={{
                current: pagination.page,
                pageSize: pagination.limit,
                total: dataPagination?.total,
                ...basePagination,
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PageCategories;
