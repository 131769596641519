import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { omit } from 'lodash/object';
import isArray from 'lodash/isArray';
import getSearchValue from '../../lib/getSearchValue';
import useDebounce from './useDebounce';



const useQueryParams = ({ searchFields, paginationInit = { page: 1, limit: 10 }, isFilter = false } = {}) => {
  const [ searchParams, setSearchParams ] = useSearchParams();
  const initPagination = {
    page: Number.parseInt(searchParams.get('page') || paginationInit.page, 10),
    limit: Number.parseInt(searchParams.get('limit') || paginationInit.limit, 10),
  };

  if (searchParams.has('sortedBy') && (searchParams.get('sortedBy') !== 'null')) {
    initPagination.sortedBy = searchParams.get('sortedBy');
    initPagination.orderBy = searchParams.get('orderBy');
  }

  const [ pagination, setPagination ] = useState(initPagination);
  const [ searchTerm, setSearchTerm ] = useState('');
  const searchQuery = useDebounce(searchTerm.trim());

  useEffect(() => {
    setPagination((prevState) => {
      return {
        ...prevState,
        page: Number.parseInt(searchParams.get('page') || paginationInit.page, 10),
      };
    });
  }, [ searchParams ]);

  useEffect(() => {
    if (isFilter) {
      const queryParams = {};

      Array.from(searchParams.entries())
        .forEach(([ key, value ]) => {
          queryParams[key] = key === 'page' ? 1 : value;
        });

      setSearchParams(queryParams, { replace: true });
    } else {
      setSearchTerm(getSearchValue(searchParams));
    }
  }, []);

  useEffect(() => {
    const queryParams = {};

    Array.from(searchParams.entries())
      .forEach(([ key, value ]) => {
        if (!searchQuery.length && key === 'search') {
          return;
        }

        queryParams[key] = key === 'page' ? 1 : value;
      });

    if (searchQuery.length && searchFields?.length) {
      queryParams.search = searchFields.map((field) => `${field}:${searchQuery}`).join(';');
    }

    setSearchParams(queryParams, { replace: true });
  }, [ searchQuery ]);

  useEffect(() => {
    let preparedPagination = { ...pagination };

    preparedPagination = omit(preparedPagination, [ 'filters' ]);

    Object.entries(pagination.filters ?? {}).forEach(([ key, value ]) => {
      if (isArray(value)) {
        preparedPagination[`${key}[]`] = value;
      } else if (value !== null) {
        preparedPagination[key] = value;
      }
    });

    setSearchParams((prevState) => {
      let queryParams = {};

      Array.from(prevState.entries())
        .forEach(([ key, value ]) => {
          if (pagination?.filters?.hasOwnProperty(key) || pagination?.filters?.hasOwnProperty(key.replace('[]', ''))) {
            return;
          }

          queryParams[key] = value;
        });

      if (preparedPagination.sortedBy === 'null' || preparedPagination.sortedBy === 'undefined' || !preparedPagination.sortedBy) {
        preparedPagination = omit(preparedPagination, [ 'sortedBy', 'orderBy' ]);
        queryParams = omit(queryParams, [ 'sortedBy', 'orderBy' ]);
      }

      return { ...queryParams, ...preparedPagination };
    }, { replace: true });
  }, [ pagination ]);

  const onTableParamsChange = (pagination, filters, sorter) => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    let updatedParams = {
      page: pagination.current,
      limit: pagination.pageSize,
      sortedBy: sorter.order,
      orderBy: sorter.field,
      filters,
    };

    if (sorter.order === 'ascend') {
      updatedParams.sortedBy = 'asc';
    } else if (sorter.order === 'descend') {
      updatedParams.sortedBy = 'desc';
    } else if (!sorter.order) {
      updatedParams = omit(updatedParams, [ 'sortedBy', 'orderBy' ]);
    }

    setPagination(updatedParams);
  };

  return {
    pagination,
    searchParams,
    setSearchTerm,
    search: searchTerm,
    handleChangeTableParams: onTableParamsChange,
  };
};

export default useQueryParams;
