const news = {
  'news-breadcrumbs': 'Старые новости',
  'news-table-content-column': 'Контент',
  'news-table-dictionary-column': 'Словарь',
  'news-created-successfully': 'Новость создана',
  'news-created-failed': 'Ошибка при создании новости',
  'news-updated-successfully': 'Новость обновлена',
  'news-updated-failed': 'Ошибка при редактировании новости',
  'news-deleted-successfully': 'Новость удалена',
  'news-deleted-failed': 'Ошибка при удалении новости',
  'news-table-delete-btn-confirm-message': 'Вы уверены, что хотите удалить эту новость?',
  'news-preview-title': 'Просмотр новости',
  'news-create-title': 'Создать старую новость',
  'news-update-title': 'Редактировать старую новость',
  'news-filter-title': 'Фильтр старых новостей',
  'news-form-id-placeholder': 'ID новости',
  'news-form-translations-label': 'Переводы',
  'news-form-status-placeholder': 'Выберите статус',
  'news-form-upload-icons-placeholder': 'Загрузка иконок (2 файла)',
  'news-form-type-label': 'Тип',
  'news-form-type-placeholder': 'Выберите тип',
  'news-create-form-target-label': 'Схема/URL',
  'news-create-form-target-placeholder': '#target',
  'news-create-form-dictionary-label': 'Словарь',
  'news-create-form-dictionary-placeholder': 'Выберите словарь',
  'news-create-form-icons-label': 'Иконки',
  'news-create-form-type-label': 'Тип',
  'news-create-form-type-placeholder': 'Выберите тип',
  'news-create-form-upload-description-label': 'Нажмите или перетащите в эту область 2 файла для загрузки',
};

export default news;
