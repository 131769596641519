import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Col, Row, Card, Spin, Descriptions, Modal, Dropdown, Button, Space, Menu, Popconfirm } from 'antd';
import { MdKeyboardReturn, MdOutlineUnpublished } from 'react-icons/md';
import { useIntl } from 'react-intl';
import { RiCloseFill } from 'react-icons/ri';
import { DownOutlined } from '@ant-design/icons';
import { PiInfinity, PiPencilSimpleLine, PiTrashSimple } from 'react-icons/pi';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import urlPageBonuses from '../../../../urls/urlPageBonuses';
import urlPageBonusEdit from '../../../../urls/urlPageBonusEdit';
import { BONUSES_SOURCE } from '../PageBonuses/PageBonuses.const';
import { DATE_TIME_FORMAT, EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';
import { makeNotification, notificationTypes } from '../../../../lib/makeNotification';
import { Permissions } from '../../../../const/permissions';
import { PrivateComponent } from '../../../../components/has-rights/HasRights';
import getFormattedDate from '../../../../lib/getFormattedDate';
import getConfigurations from '../../../../lib/getConfigurations';
import useToggle from '../../../../app/hooks/useToggle';
import useCheckMobileScreen from '../../../../app/hooks/useCheckMobileScreen';
import BreadCrumbs from '../../../../layout/components/breadcrumbs';
import ActionButton from '../../../../layout/components/action-button';
import ContentCard from '../../components/ContentCard';
import PublishedTag from '../../../../components/PublishedTag';
import PreviewImageCard from '../../components/PreviewImageCard';
import EmptyDataPlaceholder from '../../../../components/EmptyDataPlaceholder';
import { useDeleteBonusMutation, useGetSpecificBonusQuery, useUnpublishBonusMutation } from '../../api/bonusesApiSlice';



const PageBonusDetails = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const isMobile = useCheckMobileScreen(600);
  const { bonusId } = useParams();
  const [ previewModalOpen, togglePreviewModal ] = useToggle();
  const [ previewImage, setPreviewImage ] = useState({});

  const { data: bonusDetails = {}, isLoading } = useGetSpecificBonusQuery(bonusId);
  const [ unpublishBonus, { isLoading: isUnpublishBonusLoading } ] = useUnpublishBonusMutation();
  const [ deleteBonus, { isLoading: isBonusDeleting } ] = useDeleteBonusMutation();

  const bonusConfig = getConfigurations(BONUSES_SOURCE);
  const allowUnpublishPast = bonusConfig?.publish?.allow_unpublish_past ?? true;
  const allowUpdatePast = bonusConfig?.publish?.allow_update_past ?? true;
  const releaseDate = bonusDetails?.released_at;
  const allowUnpublish = allowUnpublishPast ?
    Boolean(bonusDetails?.is_published) :
    Boolean(bonusDetails?.is_published) && dayjs().isSameOrBefore(dayjs(releaseDate), 'day');
  const allowUpdate = allowUpdatePast ? true : dayjs().isSameOrBefore(dayjs(releaseDate).subtract(-1, 'days'), 'day');

  const isLoadingData = isLoading || isUnpublishBonusLoading || isBonusDeleting;


  useEffect(() => {
    const { hash } = window.location;

    if (hash) {
      const element = document.querySelector(hash);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);


  const handleUnpublish = () => {
    unpublishBonus(bonusId)
      .unwrap()
      .then(() => {
        makeNotification(
          notificationTypes.success,
          intl.formatMessage({ id: 'ui-general-success' }),
          intl.formatMessage({ id: 'ui-general-unpublish-successfully' }),
        );
      })
      .catch((error) => {
        makeNotification(
          notificationTypes.error,
          intl.formatMessage({ id: 'ui-general-error' }),
          <div className="hp-mb-4">
            {intl.formatMessage({ id: 'ui-general-unpublish-failed' })}
            {error?.data?.message}
          </div>,
        );
      });
  };

  const handleDelete = () => {
    deleteBonus(bonusId)
      .unwrap()
      .then(() => {
        makeNotification(
          notificationTypes.success,
          intl.formatMessage({ id: 'ui-general-success' }),
          intl.formatMessage({ id: 'ui-general-deleted-successfully' }),
        );
        navigate(urlPageBonuses());
      })
      .catch((error) => {
        makeNotification(
          notificationTypes.error,
          intl.formatMessage({ id: 'ui-general-error' }),
          <>
            <p className="hp-mb-4">{intl.formatMessage({ id: 'ui-general-delete-failed' })}</p>
            {error?.data?.message && <p>{ error.data.message }</p>}
          </>,
        );
      });
  };

  const handleCardClick = (item) => {
    setPreviewImage(item);
    togglePreviewModal();
  };

  const handlePreviewModalClose = () => {
    setPreviewImage({});
    togglePreviewModal();
  };


  const actionItems = (
    <Menu>
      <PrivateComponent allowedPermissions={[ Permissions.CONTENT.BONUSES.EDIT ]}>
        {allowUpdate && (
          <Menu.Item
            key="edit"
            icon={<PiPencilSimpleLine size={18} />}
            onClick={() => navigate(urlPageBonusEdit({ bonusId }))}
          >
            <IntlMessages id="ui-general-edit" />
          </Menu.Item>
        )}
      </PrivateComponent>
      <PrivateComponent allowedPermissions={[ Permissions.CONTENT.BONUSES.UNPUBLISH ]}>
        {allowUnpublish && (
          <Menu.Item
            key="unpublish"
            icon={<MdOutlineUnpublished size={18} />}
            onClick={handleUnpublish}
          >
            <IntlMessages id="ui-general-unpublish" />
          </Menu.Item>
        )}
      </PrivateComponent>
      <PrivateComponent allowedPermissions={[ Permissions.CONTENT.BONUSES.DELETE ]}>
        {!bonusDetails.is_published &&
          <Popconfirm
            title={<IntlMessages id="bonus-table-delete-btn-confirm-message" />}
            placement="top"
            onConfirm={handleDelete}
          >
            <Menu.Item
              key="delete"
              icon={<PiTrashSimple size={18} />}
              onClick={() => {}}
            >
              <IntlMessages id="ui-general-delete" />
            </Menu.Item>
          </Popconfirm>}
      </PrivateComponent>
    </Menu>
  );

  return (
    <>
      <Modal
        width={1200}
        title={intl.formatMessage({ id: 'content-image-preview-card-title' })}
        centered
        visible={previewModalOpen}
        onCancel={handlePreviewModalClose}
        footer={false}
        closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
      >
        <PreviewImageCard
          data={previewImage}
          canUpdate={false}
          onModalClose={handlePreviewModalClose}
        />
      </Modal>

      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={
            <Link to={urlPageBonuses()}>
              <IntlMessages id='bonuses-breadcrumbs' />
            </Link>
          }
          breadCrumbActive={bonusDetails?.id ?? EMPTY_VALUE_PLACEHOLDER}
        />

        <Col md={10} span={24} className='text-end'>
          <Space size='middle'>
            <PrivateComponent
              allowedPermissions={[
                Permissions.CONTENT.BONUSES.EDIT,
                Permissions.CONTENT.BONUSES.DELETE,
                Permissions.CONTENT.BONUSES.UNPUBLISH,
              ]}
            >
              <Dropdown overlay={actionItems}>
                <Button onClick={(ev) => ev.preventDefault()}>
                  <Space size='middle'>
                    <IntlMessages id='ui-general-action' />
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </PrivateComponent>

            <ActionButton
              icon={<MdKeyboardReturn className="hp-mr-4" />}
              onClick={() => {
                navigate(urlPageBonuses());
              }}
              title={<IntlMessages id='ui-general-back-to-list' />}
            />
          </Space>
        </Col>
      </Row>

      <Spin spinning={isLoadingData} tip={intl.formatMessage({ id: 'ui-general-loading' })} >
        <Row gutter={[ 32, 32 ]} className="hp-mb-32">
          <Col span={24}>
            <Card className="hp-border-color-black-40 hp-card-6">
              <Descriptions
                title={<IntlMessages id='bonus-details-title' />}
                layout={isMobile ? 'vertical' : 'horizontal'}
                bordered
                column={1}
              >
                <Descriptions.Item style={{ minWidth: '20vw' }} label={<IntlMessages id="ui-general-id" />}>{bonusDetails.id}</Descriptions.Item>
                <Descriptions.Item label={<IntlMessages id="ui-general-code" />}>{bonusDetails?.code || EMPTY_VALUE_PLACEHOLDER}</Descriptions.Item>
                <Descriptions.Item label={<IntlMessages id="ui-general-title" />}>{bonusDetails?.title || EMPTY_VALUE_PLACEHOLDER}</Descriptions.Item>
                <Descriptions.Item label={<IntlMessages id="ui-general-description" />}>{bonusDetails?.description || EMPTY_VALUE_PLACEHOLDER}</Descriptions.Item>
                <Descriptions.Item label={<IntlMessages id="ui-general-published" />}>
                  <PublishedTag
                    published={bonusDetails?.is_published}
                    publishedDate={bonusDetails?.published_at}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={<IntlMessages id="ui-general-created-at" />}>
                  {getFormattedDate(bonusDetails?.created_at, DATE_TIME_FORMAT)}
                </Descriptions.Item>
                <Descriptions.Item label={<IntlMessages id="ui-general-updated-at" />}>
                  {getFormattedDate(bonusDetails?.updated_at, DATE_TIME_FORMAT)}
                </Descriptions.Item>
                <Descriptions.Item label={<IntlMessages id="ui-general-published-at" />}>
                  {getFormattedDate(bonusDetails?.published_at, DATE_TIME_FORMAT)}
                </Descriptions.Item>
                <Descriptions.Item label={<IntlMessages id="ui-general-released-at" />}>
                  {getFormattedDate(bonusDetails?.released_at, DATE_TIME_FORMAT)}
                </Descriptions.Item>
                <Descriptions.Item label={<IntlMessages id="ui-general-released-to" />}>
                  {!isEmpty(bonusDetails?.released_to) ? dayjs(bonusDetails?.released_to).utc().format(DATE_TIME_FORMAT) : <PiInfinity />}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
        </Row>

        <Row gutter={[ 32, 32 ]}>
          <Col>
            <Card
              id="attached-images"
              loading={isLoadingData}
              title={<IntlMessages id="achievements-attached-images" />}
            >
              <Row gutter={[ 16, 32 ]}>
                {bonusDetails?.images?.data?.length > 0 ?
                  bonusDetails?.images?.data?.map((item) => {
                    return (
                      <Col key={item.id} className="gutter-row" xs={24} sm={8} lg={6} xxl={4}>
                        <ContentCard
                          allowImageClick
                          data={item}
                          onClick={handleCardClick}
                        />
                      </Col>
                    );
                  }) : (
                    <EmptyDataPlaceholder placeholder={<IntlMessages id="bonus-no-attached-images" />} />
                  )}
              </Row>
            </Card>
          </Col>
        </Row>
      </Spin>
    </>
  );
};

export default PageBonusDetails;
