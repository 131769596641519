import React from 'react';
import { Form, FormItem, Input, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'antd';
import { useIntl } from 'react-intl';
import Yup from '../../../../vendor/yup';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';



const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Min 2 symbols required')
    .max(50, 'Max 50 symbols required')
    .required('Name field is required'),
  email: Yup.string()
    .email('Invalid email format')
    .max(191, 'Max 191 symbol required')
    .required('Email is required'),
});

const FormCreateEditUser = ({
  onSubmit,
  onCancel,
  initialValues,
  isSubmitting,
  formErrors,
  disabledEmail,
}) => {
  const intl = useIntl();

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      isSubmitting={isSubmitting}
      initialValues={initialValues}
      initialErrors={formErrors}
      onSubmit={(values) => {
        onSubmit({ ...values, name: values.name.trim() });
      }}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { isValid, setFieldValue, dirty } = props;

        return (
          <Form layout="vertical" style={{ width: '100%' }}>
            <FormItem
              className='ant-form-item-col'
              name="name"
              label={<IntlMessages id="ui-general-name" />}
              required
            >
              <Input
                name='name'
                placeholder={intl.formatMessage({ id: 'form-create-user-name-placeholder' })}
              />
            </FormItem>

            <FormItem
              className='ant-form-item-col'
              name="email"
              label={<IntlMessages id="user-details-email-field" />}
              required
            >
              <Input
                name='email'
                disabled={disabledEmail}
                onChange={(ev) => {
                  setFieldValue('email', ev.target.value.trim());
                }}
                placeholder={intl.formatMessage({ id: 'form-create-user-email-placeholder' })}
              />
            </FormItem>

            <Row gutter={[ 16, 16 ]} justify='center'>
              <Col>
                <Button type='secondary' onClick={onCancel}>
                  <IntlMessages id='ui-general-cancel' />
                </Button>
              </Col>

              <Col>
                <SubmitButton
                  loading={isSubmitting}
                  disabled={!isValid || !dirty}
                >
                  <IntlMessages id='ui-general-submit' />
                </SubmitButton>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

FormCreateEditUser.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  formErrors: PropTypes.object,
  isSubmitting: PropTypes.bool.isRequired,
  disabledEmail: PropTypes.bool,
  values: PropTypes.shape({}),
};

FormCreateEditUser.defaultProps = {
  formErrors: [],
  values: {},
  disabledEmail: false,
};

export default FormCreateEditUser;
