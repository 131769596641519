import React from 'react';
import PropTypes from 'prop-types';



const UserDetailsRow = ({ title, children }) => {
  const listTitle = 'hp-p1-body';
  const listResult = 'hp-mt-sm-4 hp-p1-body hp-text-color-black-100 hp-text-color-dark-0';

  return (
    <li className="hp-mt-18">
      <span className={listTitle}>{title}</span>
      <span className={listResult}>{children}</span>
    </li>
  );
};


UserDetailsRow.propTypes = {
  title: PropTypes.any.isRequired,
  children: PropTypes.any,
};

UserDetailsRow.defaultProps = {
  children: null,
};

export default UserDetailsRow;
