import React from 'react';
import { Tooltip } from 'antd';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import CopyToClipboard from '../../../../components/CopyToClipboard';
import { EMPTY_IMAGE_PLACEHOLDER } from '../../../../const/system';



export const getDeeplinksTableColumns = () => [
  {
    title: <IntlMessages id="ui-general-id" />,
    dataIndex: 'id',
    align: 'center',
    width: '120px',
    sorter: true,
  },
  {
    title: <IntlMessages id="deeplinks-table-preview-column" />,
    dataIndex: 'preview',
    align: 'center',
    width: '150px',
    render: (value) => (
      <LazyLoadImage
        effect='blur'
        alt="deeplink preview"
        src={value ?? EMPTY_IMAGE_PLACEHOLDER}
      />
    ),
  },
  {
    title: <IntlMessages id="deeplinks-table-short-link-column" />,
    dataIndex: 'short_link',
    align: 'center',
    width: '160px',
    render: (value) => (
      <>
        <a href={value} target="_blank" rel="noreferrer">Link</a>
        <CopyToClipboard value={value} />
      </>
    ),
  },
  {
    title: <IntlMessages id="deeplinks-table-deeplink-column" />,
    align: 'center',
    dataIndex: 'deeplink',
    render: (value) => {
      return (
        <div className="hp-d-flex hp-align-items-center">
          <Tooltip placement="top" title={value}>
            <span className="hp-text-overflow-ellipsis">{value}</span>
          </Tooltip>
          <CopyToClipboard value={value} />
        </div>
      );
    },
  },
  {
    title: <IntlMessages id="deeplinks-table-open-times-column" />,
    align: 'center',
    dataIndex: 'open_times',
    width: '160px',
  },
];
