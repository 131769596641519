import {Tag} from "antd";
import IntlMessages from "../../layout/components/lang/IntlMessages";
import React from "react";
import PropTypes from "prop-types";



const StatusTag = ({active}) => {
	return (
		<Tag color={active ? 'success' : 'error'}>
			<IntlMessages id={active ? 'ui-general-yes' : 'ui-general-no'} />
		</Tag>
	);
};

StatusTag.propTypes = {
	active: PropTypes.bool.isRequired,
}

export default StatusTag;