const dynamicNews = {
  'dynamic-news-breadcrumbs': 'News',
  'dynamic-news-preview-breadcrumbs': 'News details',
  'dynamic-news-create-breadcrumbs': 'Create news',
  'dynamic-news-update-breadcrumbs': 'Update news',
  'dynamic-news-table-delete-btn-confirm-message': 'Are you sure you want to delete this news?',
  'dynamic-news-filter-title': 'Filter news',
  'dynamic-news-form-id-placeholder': 'News ID',
  'dynamic-news-form-title-placeholder': 'News title',
  'dynamic-news-form-status-placeholder': 'Choose status',
  'dynamic-news-form-template-key-value-label': 'Key value',
  'dynamic-news-form-template-key-value-placeholder': 'Value',
  'dynamic-news-preview-targets-label': 'Targets',
  'dynamic-news-create-form-title-placeholder': 'News title',
  'dynamic-news-create-form-status-placeholder': 'News status',
  'dynamic-news-create-form-targets-min-version-placeholder': 'Minimum app version',
  'dynamic-news-create-form-targets-max-version-placeholder': 'Maximum app version',
  'dynamic-news-create-form-targets-platform-placeholder': 'Choose platform',
  'dynamic-news-create-form-targets-countries-placeholder': 'Choose countries',
  'dynamic-news-create-form-upload-description-label': 'Click or drag file to this area for uploading',
  'dynamic-news-created-successfully': 'News created',
  'dynamic-news-created-failed': 'News creation failed.',
  'dynamic-news-template-validation-error': 'Template field has error in a validation rules.',
  'dynamic-news-updated-successfully': 'News updated',
  'dynamic-news-updated-failed': 'News update failed',
  'dynamic-news-deleted-successfully': 'News deleted',
  'dynamic-news-delete-failed': 'News delete failed',
  'dynamic-news-dictionary-name': 'Dictionary',
  'dynamic-news-delete-notification': 'Unable to delete, current data is already published',
  'dynamic-news-fields-priority': 'Fields priority',
  'dynamic-news-form-main-attribute': 'Main attribute',
};

export default dynamicNews;
