import { TYPE_DICTIONARY } from '../../Constructors/const/templates';
import { useGetDictionariesQuery } from '../../Dictionary/api/dictionariesApiSlice';
import { DICTIONARY_STATUS } from '../../Dictionary/pages/PageDictionaries/PageDictionaries.const';



const useDictionariesOptions = (field) => {
  let options = [];

  if (field?.type !== TYPE_DICTIONARY) {
    return options;
  }

  const { data: {
    data: dictionaries,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = { data: [], pagination: {} }, isFetching } = useGetDictionariesQuery({
    queryParams: `search=structure_id:${field.value};status:${DICTIONARY_STATUS.READY}&limit=0`,
  });

  options = dictionaries?.map((dictionary) => {
    return {
      label: dictionary.name,
      value: `${dictionary.id}`,
    };
  });

  return [ options, isFetching ];
};

export default useDictionariesOptions;
