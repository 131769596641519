import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import Yup from '../../../../vendor/yup';
import { targetValidation } from '../../../../const/targetValidation';



export const POPUP_TEMPLATE_CONTENT_TYPE = 'popup';

export const mkValidationSchema = (
  popupConfig,
  dynamicData = {},
) => Yup.lazy((values) => Yup.object().shape({
  title: Yup.string()
    .min(3, 'Min 3 symbols required')
    .max(255, 'Max 255 symbols required'),
  template_id: Yup.string().required('Required to specify template'),
  released_at: !values.is_published ? Yup.date().nullable() :
    Yup.string().required('Required to specify released at date')
      .test(
        'test_day_in_the_past',
        'Released at date must be same or after than yesterday',
        (value) => {
          return !moment(value).isBefore(moment(new Date())
            .subtract(1, 'days').startOf('day')
            .utc(false));
        },
      )
      .test(
        'test_date_after',
        'Released at date must be same or before than released to',
        (value) => {
          if (!isEmpty(values?.released_to) && !isEmpty(value)) {
            return moment(values?.released_to).isSameOrAfter(value);
          }
          return true;
        },
      ),
  ...popupConfig?.release_range && {
    released_to: Yup.string().nullable()
      .test(
        'test_day_in_the_past',
        'Released to date must be same or after than yesterday',
        (value) => {
          return !moment(value).isBefore(moment(new Date())
            .subtract(1, 'days').startOf('day')
            .utc(false));
        },
      )
      .test(
        'test_date_before',
        'Released to date must be same or after than released at',
        (value) => {
          if (!isEmpty(values?.released_at) && !isEmpty(value)) {
            return moment(values?.released_at).isSameOrBefore(value);
          }
          return true;
        },
      ),
  },
  is_published: Yup.bool().nullable(),
  target: targetValidation,
  ...dynamicData,
}));
