import { Link } from "react-router-dom";
import appColors from "../../const/colors";
import { RiInformationLine } from "react-icons/ri";
import { Alert } from "antd";
import React from "react";
import {useIntl} from "react-intl";


const DocumentationAlert = ({ link = '#', message= '' }) => {
  const intl = useIntl();

  return (
    <Alert
      className="hp-mb-32"
      style={{ marginTop: '-20px' }}
      message={message}
      description={
        <span>
          {intl.formatMessage({ id: 'features-title-notification' })}
          &nbsp;
          <Link
            style={{ color: appColors.blueLink }}
            to={link}
            target="_blank"
          >
          https://x-flow-ltd.atlassian.net/wiki/...
        </Link>
      </span>
      }
      type="info"
      showIcon
      icon={<RiInformationLine className="remix-icon" size={24} />}
    />
  )
};

export default DocumentationAlert;
