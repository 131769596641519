import React from 'react';
import { Space, Tag } from 'antd';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';



const ContentCardTags = ({ color, items = [] }) => {
  if (!items.length) {
    return null;
  }

  return (
    <Space className="content-tags-section">
      {items.map((item) => {
        return <Tag key={item} color={color ?? 'default'}>{capitalize(item)}</Tag>;
      })}
    </Space>
  );
};

ContentCardTags.propTypes = {
  color: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ContentCardTags;
