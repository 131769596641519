const categories = {
  'categories-breadcrumbs': 'Категории',
  'categories-filter-name-label': 'Имя',
  'categories-filter-name-placeholder': 'Укажите имя',
  'categories-filter-status-placeholder': 'Выберите статус',
  'categories-filter-created-from-label': 'Создан с',
  'categories-filter-created-to-label': 'Создан по',
  'categories-delete-successfully': 'Категория удалена',
  'categories-create-successfully': 'Категория создана',
  'categories-update-successfully': 'Категория обновлена',
  'categories-delete-failed': 'Ошибка при удалении категории',
  'categories-create-failed': 'Ошибка при создании категории',
  'categories-update-failed': 'Ошибка при обновлении категории',
  'categories-create-form-field-client-id-help': 'Уникальный ID для "core" клиента для распознания категории',
  'categories-create-form-field-name': 'Название',
  'categories-create-form-field-name-placeholder': 'Введите название',
  'categories-create-form-field-description': 'Описание',
  'categories-create-form-field-description-placeholder': 'Введите описание',
  'categories-create-form-field-options': 'Доп. опции',
  'categories-create-form-field-options-placeholder': 'Введите доп. опции',
  'categories-create-form-field-options-help': 'Вы можете задать дополнительные параметры, такие как конфигурация таргета (min_app_version, core_version и пр.) в JSON формате',
  'categories-create-form-field-dictionary': 'Словарь',
  'categories-create-form-field-dictionary-placeholder': 'Выберите словарь',
  'categories-view-images': 'Изображения',
  'categories-view-images-empty': 'Нет изображений',
  'categories-preview-priority': 'Приоритет',
  'categories-preview-created-at': 'Дата создания',
  'categories-priorities': 'Приоритеты',
  'categories-priorities-title': 'Перетащите категории чтобы установить нужный приоритет',
  'categories-priorities-empty': 'Категории отсутствуют',
  'categories-priorities-updated-successfully': 'Приоритет категорий успешно обновлен',
  'categories-priorities-updated-failed': 'Во время обновления приоритета категорий произошла ошибка',
  'categories-priorities-disabled-action': 'Перетащите категории для последующего обновления приоритетов',
};

export default categories;
