import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { FullscreenOutlined } from '@ant-design/icons';



const TranslationInput = ({ field, handleExpand, handleUpdateValue, style }) => {
  const key = `${field.language_id}:${field.structure_field_id}`;

  return (
    <Input
      style={style}
      value={field.text}
      key={key}
      onChange={(event) => handleUpdateValue(event.target.value, field)}
      suffix={(<FullscreenOutlined onClick={() => handleExpand(field)} style={{ color: 'rgba(0,0,0,.45)' }} />)}
    />
  );
};


TranslationInput.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.any,
    language_id: PropTypes.number,
    structure_field_id: PropTypes.number,
    dictionary_id: PropTypes.number,
    text: PropTypes.string,
  }).isRequired,
  handleExpand: PropTypes.func,
  style: PropTypes.any,
  handleUpdateValue: PropTypes.func,
};

export default TranslationInput;
