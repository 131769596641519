const popups = {
  'popups-breadcrumbs': 'Popups',
  'popups-details-title': 'Popup',
  'popups-filter-title': 'Filter popups',
  'popups-table-delete-btn-confirm-message': 'Are you sure you want to delete this popup?',
  'popups-deleted-successfully': 'Popup deleted',
  'popups-delete-failed': 'Popup delete failed',
  'popups-priority-title': 'Set correct order for popups',
  'popups-priority-filter-title': 'Select popups by date',
  'popups-priority-updated-successfully': 'Popups priority updated',
  'popups-priority-updated-failed': 'Popups priority update failed',
};

export default popups;
