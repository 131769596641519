import React from 'react';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';



export const statusOptions = [ {
  label: <IntlMessages id="ui-general-yes" />,
  value: '1',
}, {
  label: <IntlMessages id="ui-general-no" />,
  value: '0',
} ];

export const templatesOptions = (templates) => templates.map((template) => ({
  label: template.title,
  value: `${template.id}`,
}));
