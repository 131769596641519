const priority = {
  'news-priority-form-date-label': 'Дата',
  'news-priority-filter-title': 'Выберите новости по дате',
  'news-priority-title': 'Установите правильный порядок новостей',
  'news-priority-updated-successfully': 'Приоритет новостей обновлен',
  'news-priority-updated-failed': 'Ошибка при обновлении приоритета новостей',
  'news-priority-empty': 'На выбранную дату отсутствуют новости.',
};

export default priority;
