import React from 'react';
import isArray from 'lodash/isArray';
import { Col, Popconfirm, Row, Tag } from 'antd';
import { PiPencil, PiPlusCircle, PiTrashLight } from 'react-icons/pi';
import PropTypes from 'prop-types';
import isNull from 'lodash/isNull';
import { useSelector } from 'react-redux';
import IntlMessages from '../../../layout/components/lang/IntlMessages';
import {
  TEMPLATE_TYPES,
  TYPE_ARRAY,
  TYPE_BOOLEAN,
  TYPE_DICTIONARY,
  TYPE_OBJECT,
  TYPE_SELECT,
  VALIDATION_RULES_FILE,
  VALIDATION_RULES_OPTIONAL,
  VALIDATION_RULES_REQUIRED,
} from '../const/templates';



const TreeNode = ({
  hashKey,
  nodeKey,
  nodeData,
  handleCurrentNodeKey,
  handleCurrentHashKey,
  toggleSidebar,
  toggleEdit,
  handleRemoveNode,
  handleCurrentNode,
  structures,
  canEditTree = false,
}) => {
  const { language } = useSelector((state) => state.customise);

  const handleAddNode = (hashKey, nodeKey) => {
    handleCurrentNodeKey(nodeKey);
    handleCurrentHashKey(hashKey);
    toggleSidebar();
    handleCurrentNode({});
    toggleEdit(false);
  };

  const handleEditNode = (hashKey, nodeKey, nodeData) => {
    handleCurrentNode({ key: nodeKey, ...nodeData });
    handleCurrentNodeKey(nodeKey);
    handleCurrentHashKey(hashKey);
    toggleSidebar();
  };

  if (nodeData.type === TYPE_ARRAY && nodeData.default_value && !isArray(nodeData.default_value)) {
    nodeData.default_value = nodeData.default_value.split(',');
  }

  const rules = nodeData.validation
    ? nodeData.validation
      .filter((rule) => rule !== VALIDATION_RULES_REQUIRED &&
        rule !== VALIDATION_RULES_OPTIONAL &&
        rule !== VALIDATION_RULES_FILE &&
        !TEMPLATE_TYPES.includes(rule))
      .join(',')
    : null;

  let defaultValue = null;

  if (isArray(nodeData.default_value)) {
    defaultValue = nodeData.default_value.length > 0 && (
      <div>
        <span className="hp-mr-8 hp-text-color-black-60">
          <IntlMessages id="templates-create-form-field-default-value" />
        </span>
        {nodeData.default_value.map((value) => {
          return (
            <Tag className='hp-mt-4' key={value}>{value}</Tag>
          );
        })}
      </div>
    );
  } else if (nodeData.type === TYPE_DICTIONARY) {
    const structure = structures?.data?.find((structure) => structure.id === Number(nodeData.value));

    defaultValue = (
      <div>
        <span className="hp-mr-8 hp-text-color-black-60">
          <IntlMessages id="templates-create-form-field-default-value" />
        </span>
        <Tag className='hp-mt-4'>
          <IntlMessages id="templates-create-form-field-dictionary-structure" />
          {`: ${structure?.source} ${structure?.version}`}
        </Tag>
      </div>
    );
  } else if (nodeData.type === TYPE_SELECT) {
    defaultValue = (
      <div>
        <span className="hp-mr-8 hp-text-color-black-60">
          <IntlMessages id="templates-create-form-field-select" />
        </span>
        <Tag style={{ whiteSpace: 'normal' }}>
          {nodeData.value}
        </Tag>
      </div>
    );
  } else if (nodeData.type === TYPE_BOOLEAN && !isNull(nodeData.default_value)) {
    defaultValue = (
      <div>
        <span className="hp-mr-8 hp-text-color-black-60">
          <IntlMessages id="templates-create-form-field-default-value" />
        </span>
        {`${nodeData.default_value}`}
      </div>
    );
  } else if (nodeData.default_value) {
    defaultValue = (
      <div>
        <span className="hp-mr-8 hp-text-color-black-60">
          <IntlMessages id="templates-create-form-field-default-value" />
        </span>
        {nodeData.default_value}
      </div>
    );
  }

  return (
    <Row gutter={[ 16, 16 ]}>
      <Col span={24}>
        {canEditTree ? (
          <Row style={{ alignItems: 'center' }}>
            <span className="hp-mr-8">
              {`[${nodeKey}] ${nodeData.type !== TYPE_OBJECT ? nodeData[`title_${language}`] : ''}`}
            </span>
            <PiPencil
              size={21}
              className="hp-cursor-pointer hp-transition hp-hover-text-color-primary-1 hp-text-color-black-80 hp-m-4 hp-mr-16"
              onClick={() => handleEditNode(hashKey, nodeKey, nodeData)}
            />
            {nodeData.type === TYPE_OBJECT ? (
              <>
                <Popconfirm
                  title={<IntlMessages id="templates-create-form-delete-node-confirm" />}
                  onConfirm={() => handleRemoveNode(hashKey)}
                >
                  <PiTrashLight
                    size={21}
                    className="hp-cursor-pointer hp-transition hp-hover-text-color-danger-1 hp-text-color-black-80 hp-m-4 hp-mr-16"
                  />
                </Popconfirm>
                <PiPlusCircle
                  size={21}
                  className="hp-cursor-pointer hp-transition hp-hover-text-color-success-1 hp-text-color-black-80 hp-m-4 hp-mr-16"
                  onClick={() => handleAddNode(hashKey, nodeKey)}
                />
              </>
            ) : (
              <PiTrashLight
                size={21}
                className="hp-cursor-pointer hp-transition hp-hover-text-color-danger-1 hp-text-color-black-80 hp-m-4 hp-mr-16"
                onClick={() => handleRemoveNode(hashKey)}
              />
            )}
          </Row>
        ) : (
          <Row style={{ alignItems: 'center' }}>
            <span className="hp-mr-8">{`[${nodeKey}] ${nodeData.type !== TYPE_OBJECT ? nodeData[`title_${language}`] : ''}`}</span>
          </Row>
        )}
        {nodeData[`hint_${language}`] && (
          <Row className="hp-mt-8">
            <span className="hp-mr-8 hp-text-color-black-60">
              <IntlMessages id="templates-create-form-field-hint" />
            </span>
            {nodeData[`hint_${language}`]}
          </Row>
        )}
        {defaultValue && (
          <Row className="hp-mt-8">
            <Col span={24}>{defaultValue}</Col>
          </Row>
        )}
        <Row className="hp-mt-16">
          <Tag className='hp-mt-4'>
            <IntlMessages id="templates-create-form-field-type" />
            {': '}
            <IntlMessages id={`templates-create-form-field-value-${nodeData.type}`} />
          </Tag>

          {nodeData.subtype && (
            <Tag className='hp-mt-4'>
              <IntlMessages id="templates-create-form-field-subtype" />
              {': '}
              <IntlMessages id={`templates-create-form-field-value-${nodeData.subtype}`} />
            </Tag>
          )}

          {nodeData.validation && (
            <>
              {nodeData.validation.includes(VALIDATION_RULES_REQUIRED) && (
                <Tag className='hp-mt-4'>
                  <IntlMessages id="templates-create-form-field-is-required-on" />
                </Tag>
              )}
              {nodeData.validation.includes(VALIDATION_RULES_OPTIONAL) && (
                <Tag className='hp-mt-4'>
                  <IntlMessages id="templates-create-form-field-is-required-off" />
                </Tag>
              )}
              {nodeData.use_in_preset && (
                <Tag className='hp-mt-4'>
                  <IntlMessages id="templates-create-form-field-use-in-preset" />
                </Tag>
              )}
            </>
          )}

          {rules && (
            <Tag className='hp-mt-4'>
              <IntlMessages id="templates-create-form-field-validation-options" />
              {rules}
            </Tag>
          )}
        </Row>
      </Col>
    </Row>
  );
};

TreeNode.propTypes = {
  hashKey: PropTypes.string.isRequired,
  nodeKey: PropTypes.string.isRequired,
  nodeData: PropTypes.shape({
    type: PropTypes.string.isRequired,
    hint_en: PropTypes.string,
    hint_ru: PropTypes.string,
    title_en: PropTypes.string,
    title_ru: PropTypes.string,
    use_in_preset: PropTypes.bool,
    subtype: PropTypes.string,
    value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    validation: PropTypes.array,
    default_value: PropTypes.oneOfType([ PropTypes.bool, PropTypes.array, PropTypes.string, PropTypes.number ]),
  }).isRequired,
  handleCurrentNodeKey: PropTypes.func.isRequired,
  handleCurrentHashKey: PropTypes.func.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  toggleEdit: PropTypes.func.isRequired,
  handleRemoveNode: PropTypes.func.isRequired,
  handleCurrentNode: PropTypes.func.isRequired,
  structures: PropTypes.object,
  canEditTree: PropTypes.bool,
};

export default TreeNode;
