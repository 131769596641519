import moment from 'moment';
import { DATE_FORMAT } from '../const/system';



export default (date, unavailableDates = [], isDateBeforeOrAfterBoundary) => {
  return (moment(date).isBefore(moment(new Date())
    .subtract(1, 'day').startOf('day')))
    || unavailableDates.includes(moment(date).format(DATE_FORMAT))
    || isDateBeforeOrAfterBoundary;
};
