import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Row, Spin } from 'antd';
import { useIntl } from 'react-intl';
import BreadCrumbs from '../../../../layout/components/breadcrumbs';
import urlPageCategories from '../../../../urls/urlPageCategories';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import PrioritiesTitle from '../../../../components/PrioritiesTitle';
import CategoriesPriorities from '../../components/CategoriesPriorities';
import getPriorityUpdateButtonTooltipMessage from '../../../../lib/getPriorityUpdateButtonTooltipMessage';
import { hasRights, Permissions } from '../../../../const/permissions';
import { makeNotification, notificationTypes } from '../../../../lib/makeNotification';
import { useGetCategoriesPrioritiesQuery, useUpdateCategoriesPriorityMutation } from '../../api/categoriesApiSlice';



const PageCategoriesPriorities = () => {
  const intl = useIntl();
  const [ categoriesOrder, setCategoriesOrder ] = useState([]);
  const indexPageSearchParams = localStorage.getItem('indexSearchParams');
  const hasNoOrderChanges = !categoriesOrder.length;

  const { data: categoriesByPriority = {
    data: [],
    pagination: {},
  }, isFetching } = useGetCategoriesPrioritiesQuery();
  const [ updateCategoriesPriority, { isLoading: isUpdatingCategoriesPriority } ] = useUpdateCategoriesPriorityMutation();

  const handleSubmitCategoriesPriority = () => {
    const orderedItems = categoriesOrder.map((item) => {
      const position = Number(item) - 1;

      return categoriesByPriority[position]?.id;
    });

    updateCategoriesPriority({ order: orderedItems })
      .unwrap()
      .then(() => {
        makeNotification(
          notificationTypes.success,
          intl.formatMessage({ id: 'ui-general-success' }),
          intl.formatMessage({ id: 'categories-priorities-updated-successfully' }),
        );
        setCategoriesOrder([]);
      })
      .catch((error) => {
        makeNotification(
          notificationTypes.error,
          intl.formatMessage({ id: 'ui-general-error' }),
          <>
            <p className="hp-mb-4">{intl.formatMessage({ id: 'categories-priorities-updated-failed' })}</p>
            {error?.data?.message && <p>{ error.data.message }</p>}
          </>,
        );
      });
  };

  const isLoading = isFetching || isUpdatingCategoriesPriority;

  const onCategoriesOrderChange = (orderedItems) => {
    setCategoriesOrder(orderedItems);
  };

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={
            <Link to={urlPageCategories({ indexPageSearchParams })}>
              <IntlMessages id='categories-breadcrumbs' />
            </Link>
          }
          breadCrumbActive={<IntlMessages id='categories-priorities' />}
        />
      </Row>
      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card
            className="hp-border-color-black-40 hp-card-6"
            title={(
              <PrioritiesTitle
                isDisabled={hasNoOrderChanges}
                hasPermission={hasRights([ Permissions.CONTENT.CATEGORIES.PRIORITIES.EDIT ])}
                title={intl.formatMessage({ id: 'categories-priorities-title' })}
                onAction={handleSubmitCategoriesPriority}
                tooltipMessage={getPriorityUpdateButtonTooltipMessage(hasNoOrderChanges)}
              />
            )}
          >
            <Spin spinning={isLoading} tip={intl.formatMessage({ id: 'ui-general-loading' })}>
              <div>
                {categoriesByPriority.length ? (
                  <CategoriesPriorities
                    data={categoriesByPriority}
                    onOrderChange={onCategoriesOrderChange}
                  />
                ) : (
                  <Row>
                    <IntlMessages id="categories-priorities-empty" />
                  </Row>
                )}
              </div>
            </Spin>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PageCategoriesPriorities;
