import React, { useState } from 'react';
import { Card, Col, Modal, Pagination, Row } from 'antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { RiCloseFill } from 'react-icons/ri';
import { Filter } from 'iconsax-react';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import { useParams, useSearchParams } from 'react-router-dom';
import getFeatureConfiguration from '../../utils/getFeatureConfiguration';
import { useGetImagesQuery } from '../../../Content/api/imagesApiSlice';
import ContentCard from '../../../Content/components/ContentCard/index';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import useQueryParams from '../../../../app/hooks/useQueryParams';
import { makeNotification, notificationTypes } from '../../../../lib/makeNotification';
import TotalLabel from '../../../../components/TotalLabel/index';
import ActionButton from '../../../../layout/components/action-button';
import FormImagesFilter from '../../../Content/forms/FormImagesFilter';
import Sidebar from '../../../../components/sidebar/Sidebar';
import useToggle from '../../../../app/hooks/useToggle';
import useResetFilter from '../../../../app/hooks/useResetFilter';
import { useAttachImageToFeatureMutation } from '../../api/featuresApiSlice';



const FormAttachImagesToFeature = ({ featureSource }) => {
  const intl = useIntl();
  const featureConfig = getFeatureConfiguration(featureSource);
  const { featureId } = useParams();
  const imageStatuses = featureConfig?.config?.content?.statuses?.join(',') ?? null;
  const searchFields = [
    'id',
    'folder',
    'category',
    'main_category',
    'image_type',
    'tags',
    'taps_from',
    'taps_to',
    'colors_from',
    'colors_to',
    'imported_from',
    'imported_to',
    'released_from',
    'released_to',
  ];
  const [ isFilterOpen, toggleFilter ] = useToggle();
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const [ initFilterValues, setInitFilterValues ] = useState({});
  const [ confirmModalOpened, setConfirmModalOpened ] = useState(false);
  const [ selectedItemId, setSelectedItemId ] = useState(null);
  const [ attachImage ] = useAttachImageToFeatureMutation();
  const resetFilter = useResetFilter(searchQueryParams, setSearchParams, setInitFilterValues);

  const {
    pagination,
    searchParams,
    handleChangeTableParams,
  } = useQueryParams({
    searchFields,
    isFilter: true,
    paginationInit: {
      page: 1,
      limit: 12,
    } });

  const { data: {
    data: images = [],
    pagination: dataPagination = {},
  } = { data: [], pagination: {} }, isFetching: isImagesFetching } = useGetImagesQuery({
    queryParams: searchParams.toString().includes('search')
      ? `${searchParams.toString()};status:${imageStatuses}`
      : `${searchParams.toString()}&search=status:${imageStatuses}`,
  });

  const handleAttach = (imageId) => {
    attachImage({
      featureSource,
      featureId,
      payload: {
        content_image_id: imageId,
      },
    })
      .unwrap()
      .then(() => {
        makeNotification(
          notificationTypes.success,
          intl.formatMessage({ id: 'ui-general-success' }),
          intl.formatMessage({ id: 'features-edit-images-to-attach-success' }),
        );
      })
      .catch((error) => {
        makeNotification(
          notificationTypes.error,
          intl.formatMessage({ id: 'ui-general-error' }),
          <>
            <p>{intl.formatMessage({ id: 'features-edit-images-to-attach-failed' })}</p>
            {error?.data?.message && <p>{ error.data.message }</p>}
          </>,
        );
      });
  };

  const showTotal = (total) => <TotalLabel total={total} />;

  const applyFilter = (values) => {
    const queryParams = {};

    Array.from(searchQueryParams.entries())
      .forEach(([ key, value ]) => {
        queryParams[key] = key === 'page' ? 1 : value;
      });

    queryParams.search = Object.entries(values)
      .map(([ field, value ]) => {
        if (searchFields.includes(field)) {
          if ((isArray(value) && !value.length) || !value) {
            return '';
          }

          return isString(value) ? `${field}:${value.trim()}` : `${field}:${value}`;
        }
        return '';
      })
      .filter((item) => item !== '')
      .join(';');

    setSearchParams(queryParams);
    toggleFilter();
  };

  const handleContentCardClick = (item) => {
    setSelectedItemId(item.id);
    setConfirmModalOpened(true);
  };

  const handleModalConfirm = () => {
    handleAttach(selectedItemId, imageStatuses.achievement);
    setConfirmModalOpened(false);
  };

  return (
    <>
      <Sidebar
        visible={isFilterOpen}
        toggleSidebar={toggleFilter}
        width={600}
      >
        <FormImagesFilter
          initialValues={initFilterValues}
          onSubmit={applyFilter}
          onCancel={resetFilter}
          isSubmitting={false}
          excludedFields={[ 'status', 'released_from', 'released_to' ]}
        />
      </Sidebar>
      <Modal
        width={600}
        title={intl.formatMessage({ id: 'ui-general-attach-image' })}
        centered
        visible={confirmModalOpened}
        onCancel={() => {
          setConfirmModalOpened(false);
        }}
        onOk={handleModalConfirm}
        closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
      >
        <IntlMessages id="features-edit-images-to-attach-confirm-message" />
      </Modal>
      <Col span={24}>
        <Card
          loading={isImagesFetching}
          title={(
            <div className="hp-d-flex hp-d-flex-between">
              <IntlMessages id="features-edit-images-to-attach" />
              <ActionButton
                title=""
                icon={<Filter size={18} />}
                onClick={toggleFilter}
              />
            </div>
          )}
          className="hp-border-color-black-40 hp-card-6"
        >
          {!isEmpty(images) && (
            <Row gutter={[ 16, 32 ]}>
              {images?.map((item) => {
                return (
                  <Col
                    key={item.id}
                    style={{ display: 'flex', justifyContent: 'center' }}
                    xs={24}
                    sm={12}
                    md={8}
                    lg={6}
                    xxl={4}
                  >
                    <ContentCard
                      data={item}
                      useTooltip
                      allowImageClick
                      onClick={() => {
                        handleContentCardClick(item);
                      }}
                    />
                  </Col>
                );
              })}
              <Col span={24}>
                {dataPagination.total && <Pagination
                  total={dataPagination.total}
                  showTotal={showTotal}
                  pageSize={pagination.limit}
                  current={pagination.page}
                  pageSizeOptions={[ '12', '24', '48' ]}
                  onChange={(page, size) => {
                    handleChangeTableParams({
                      current: page,
                      pageSize: size,
                    }, {}, {});
                  }}
                />}
              </Col>
            </Row>
          )}
          {!isImagesFetching && isEmpty(images) && (
            <Row>
              <IntlMessages id="ui-general-no-images-found" />
            </Row>
          )}
        </Card>
      </Col>
    </>
  );
};

FormAttachImagesToFeature.propTypes = {
  featureSource: PropTypes.string,
};

export default FormAttachImagesToFeature;
