import React, { useState } from 'react';
import { Button, Card, Col, Row, Space } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import isObject from 'lodash/isObject';
import { RiArrowLeftLine } from 'react-icons/ri';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { makeNotification, notificationTypes } from '../../../../lib/makeNotification';
import urlPagePopups from '../../../../urls/urlPagePopups';
import urlPagePopupsDetails from '../../../../urls/urlPagePopupsDetails';
import transformErrors from '../../../../lib/transformErrors';
import BreadCrumbs from '../../../../layout/components/breadcrumbs';
import FormCreatePopup from '../../forms/FormCreatePopup';
import { useCreatePopupMutation } from '../../api/popupsApiSlice';



const PagePopupCreate = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [ errors, setFormErrors ] = useState({});

  const [ createPopup, { isLoading: isCreatingPopup } ] = useCreatePopupMutation();

  const onSubmit = (values) => {
    createPopup({ values })
      .unwrap()
      .then((response) => {
        makeNotification(
          notificationTypes.success,
          intl.formatMessage({ id: 'ui-general-success' }),
          intl.formatMessage({ id: 'ui-general-created-successfully' }),
        );
        navigate(urlPagePopupsDetails({ popupId: response?.data?.id }));
      })
      .catch((error) => {
        if (isObject(error?.data?.errors)) {
          const formikInitialErrors = transformErrors(error?.data?.errors);

          setFormErrors(formikInitialErrors);
        }

        let message = '';

        // Todo: remove after implementing validation in templates for validation rules.
        if (error?.data?.file?.includes('Validation/Concerns/ValidatesAttributes.php') || error?.data?.file?.includes('Illuminate/Validation/Validator.php')) {
          message = (
            <>
              <p>{intl.formatMessage({ id: 'dynamic-news-template-validation-error' })}</p>
              <p>{error?.data?.message}</p>
            </>
          );
        }

        makeNotification(
          notificationTypes.error,
          intl.formatMessage({ id: 'ui-general-error' }),
          <div className="hp-mb-4">
            {intl.formatMessage({ id: 'ui-general-created-failed' })}
            {message}
          </div>,
        );
      });
  };

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={
            <Link to={urlPagePopups()}>
              <IntlMessages id='popups-breadcrumbs' />
            </Link>
          }
          breadCrumbActive={<IntlMessages id='ui-general-create' />}
        />

        <Col md={12} span={24}>
          <Row
            gutter={[ 8, 8 ]}
            justify="end"
            align="middle"
          >
            <Col>
              <Space>
                <Link to={urlPagePopups()}>
                  <Button
                    type="primary"
                    className="hp-mr-sm-8"
                    ghost="true"
                    icon={<RiArrowLeftLine className="hp-mr-8" size={18} />}
                  >
                    <IntlMessages id='ui-general-back-to-list' />
                  </Button>
                </Link>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <FormCreatePopup
              isSubmitting={isCreatingPopup}
              initialErrors={errors}
              onSubmit={onSubmit}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PagePopupCreate;
