import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useSearchParams } from 'react-router-dom';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import dayjs from 'dayjs';
import { Card, Col, Row, Spin } from 'antd';
import { hasRights, Permissions } from '../../../../const/permissions';
import { makeNotification, notificationTypes } from '../../../../lib/makeNotification';
import BreadCrumbs from '../../../../layout/components/breadcrumbs';
import urlPageDynamicNews from '../../../../urls/urlPageDynamicNews';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import useCheckMobileScreen from '../../../../app/hooks/useCheckMobileScreen';
import getPriorityUpdateButtonTooltipMessage from '../../../../lib/getPriorityUpdateButtonTooltipMessage';
import DynamicNewsPriority from '../../components/DynamicNewsPriority';
import PrioritiesTitle from '../../../../components/PrioritiesTitle';
import PriorityFilter from '../../../../components/PriorityFilter';
import { DATE_FORMAT } from '../../../../const/system';
import { useGetDynamicNewsPriorityQuery, useUpdateDynamicNewsPriorityMutation } from '../../api/dynamicNewsApiSlice';



const PageDynamicNewsPriority = () => {
  const intl = useIntl();
  const [ dynamicNewsOrder, setDynamicNewsOrder ] = useState([]);
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const searchFields = [ 'date' ];
  const isMobile = useCheckMobileScreen();

  const { data: news = { data: [], pagination: {} }, isFetching } = useGetDynamicNewsPriorityQuery({
    queryParams: searchQueryParams.get('search') ? `search=${searchQueryParams.get('search')}` : '',
  });

  const today = dayjs().format(DATE_FORMAT);
  const search = searchQueryParams.get('search');
  const date = search ? search.split(':')[1] : today;
  const hasNoOrderChanges = !dynamicNewsOrder.length;

  const [ updateNewsPriority, { isLoading: isUpdatingNewsPriority } ] = useUpdateDynamicNewsPriorityMutation();

  const applyFilter = (values) => {
    const queryParams = {};

    Array.from(searchQueryParams.entries())
      .forEach(([ key, value ]) => {
        queryParams[key] = key === 'page' ? 1 : value;
      });

    queryParams.search = Object.entries(values)
      .map(([ field, value ]) => {
        if (searchFields.includes(field)) {
          if ((isArray(value) && !value.length) || !value) {
            return '';
          }

          return isString(value) ? `${field}:${value.trim()}` : `${field}:${value}`;
        }
        return '';
      })
      .filter((item) => item !== '')
      .join(';');

    setDynamicNewsOrder([]);
    setSearchParams(queryParams);
  };

  const onDynamicNewsOrderChange = (order) => {
    setDynamicNewsOrder(order);
  };

  const submitDynamicNewsPriority = () => {
    const order = dynamicNewsOrder.map((item) => {
      const position = Number(item) - 1;

      return news.data[position]?.id;
    });

    updateNewsPriority({ order, date }).unwrap()
      .then(() => {
        makeNotification(
          notificationTypes.success,
          intl.formatMessage({ id: 'ui-general-success' }),
          intl.formatMessage({ id: 'news-priority-updated-successfully' }),
        );
        setDynamicNewsOrder([]);
      })
      .catch((error) => {
        makeNotification(
          notificationTypes.error,
          intl.formatMessage({ id: 'ui-general-error' }),
          <>
            <p className="hp-mb-4">{intl.formatMessage({ id: 'news-priority-updated-failed' })}</p>
            {error?.data?.message && <p>{ error.data.message }</p>}
          </>,
        );
      });
  };

  const isLoading = isFetching || isUpdatingNewsPriority;

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >

        <BreadCrumbs
          breadCrumbParent={<Link to={urlPageDynamicNews()}><IntlMessages id='dynamic-news-breadcrumbs' /></Link>}
          breadCrumbActive={<IntlMessages id='ui-general-priority' />}
        />
      </Row>

      <Row gutter={[ 16, 16 ]} style={{ flexDirection: isMobile ? 'column-reverse' : 'row' }}>
        <Col sm={24} md={16}>
          <Card
            className="hp-border-color-black-40 hp-card-6"
            title={(
              <PrioritiesTitle
                isDisabled={hasNoOrderChanges}
                hasPermission={hasRights([ Permissions.CONTENT.NEWS.PRIORITY ])}
                title={intl.formatMessage({ id: 'news-priority-title' })}
                onAction={submitDynamicNewsPriority}
                tooltipMessage={getPriorityUpdateButtonTooltipMessage(hasNoOrderChanges)}
              />
            )}
          >
            <Spin spinning={isLoading} tip={intl.formatMessage({ id: 'ui-general-loading' })}>
              <DynamicNewsPriority
                news={news?.data}
                isLoading={isLoading}
                onOrderChange={onDynamicNewsOrderChange}
              />
            </Spin>
          </Card>
        </Col>
        <Col sm={24} md={8}>
          <Card
            title={intl.formatMessage({ id: 'news-priority-filter-title' })}
            className="hp-border-color-black-40 hp-card-6"
          >
            <PriorityFilter
              onSubmit={applyFilter}
              onCancel={() => {}}
              isSubmitting={isLoading || isUpdatingNewsPriority}
              initialValues={searchQueryParams.get('search') ? { date } : {}}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PageDynamicNewsPriority;
