import React, { useState } from 'react';
import { Button, Card, Col, Divider, Modal, Row, Spin, Tag } from 'antd';
import { RiCloseFill } from 'react-icons/ri';
import { Link, useParams } from 'react-router-dom';
import isObject from 'lodash/isObject';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import urlPageRBACUsers from '../../../../../urls/urlPageRBACUsers';
import {
  useGetUserQuery,
  useUpdateUserMutation,
  useUpdateUserPermissionsMutation,
  useUpdateUserRolesMutation,
  useUpdateUserStatusMutation,
} from '../../../api/usersApiSlice';
import { LOADING_TIP_PLACEHOLDER } from '../../../../../const/system';
import { useGetRolesQuery } from '../../../api/rolesApiSlice';
import { useGetPermissionsQuery } from '../../../api/permissionsApiSlice';
import { Permissions } from '../../../../../const/permissions';
import { HasRights } from '../../../../../components/has-rights/HasRights';
import useToggle from '../../../../../app/hooks/useToggle';
import FormCreateEditUser from '../../../forms/FormCreateEditUser';
import FormEditUserRoles from '../../../forms/FormEditUserRoles';
import FormEditUserPermissions from '../../../forms/FormEditUserPermissions';
import UserDetailsCard from '../../../components/UserDetailsCard';
import { refreshUserData } from '../../../../Auth/features/authSlice';
import { useApp } from '../../../../../app/context/AppContext';
import RoleTag from '../../../components/RoleTag';



const PageUserDetails = () => {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const { userId: profileId } = useApp();
  const {
    data: userDetails = {
      permissions: { data: [] },
      roles: { data: [] },
    }, isLoading,
  } = useGetUserQuery({
    userId,
    queryParams: {
      include: 'roles,permissions',
    },
  });
  const { data: roles = [], isLoading: isLoadingRolesOptions } = useGetRolesQuery({
    queryParams: 'limit=0',
  });
  const { data: permissionsList = [], isLoading: isLoadingPermissionsOptions } = useGetPermissionsQuery({
    queryParams: 'limit=0',
  });
  const [ updateUser, { isLoading: isUpdateUserLoading } ] = useUpdateUserMutation();
  const [ updateUserStatus, { isLoading: isUpdateUserStatusLoading } ] = useUpdateUserStatusMutation();
  const [ updateUserRoles, { isLoading: isUpdateUserRolesLoading } ] = useUpdateUserRolesMutation();
  const [ updateUserPermissions, { isLoading: isUpdateUserPermissionsLoading } ] = useUpdateUserPermissionsMutation();

  const [ isEditUserModalActive, toggleEditUserModal ] = useToggle();
  const [ isEditUserRolesModalActive, toggleEditUserRolesModal ] = useToggle();
  const [ isEditUserPermissionsModalActive, toggleEditUserPermissionsModal ] = useToggle();
  const [ updateUserErrors, setUpdateUserErrors ] = useState({});
  const [ updateUserRolesErrors, setUpdateUserRolesErrors ] = useState({});
  const [ updateUserPermissionsErrors, setUpdateUserPermissionsErrors ] = useState({});

  const userRoles = userDetails.roles?.data ?? [];
  const userPermissions = userDetails.permissions?.data ?? [];
  const userPermissionsIds = userDetails.permissions?.data?.map((item) => item.id) ?? [];
  const userRolesIds = userDetails.roles?.data?.map((item) => item.id) ?? [];
  const dividerClass = 'hp-border-color-black-40 hp-border-color-dark-80 hp-mb-32 hp-mt-32';


  const handleUpdateUserData = async (values) => {
    try {
      const user = await updateUser({
        userId,
        data: values,
      }).unwrap();

      if (parseInt(userId, 10) === parseInt(profileId, 10)) {
        dispatch(refreshUserData({
          data: {
            ...userDetails,
            ...user.data,
          },
        }));
      }

      toggleEditUserModal();
    } catch (error) {
      if (isObject(error?.data?.errors)) {
        setUpdateUserErrors(error.data.errors);
      }
    }
  };

  const handleUpdateUserRoles = async (values) => {
    try {
      await updateUserRoles({ userId, values }).unwrap();
      toggleEditUserRolesModal();
    } catch (error) {
      if (isObject(error?.data?.errors)) {
        setUpdateUserRolesErrors(error.data.errors);
      }
    }
  };

  const handleUpdateUserPermissions = async (values) => {
    try {
      await updateUserPermissions({ userId, values }).unwrap();
      toggleEditUserPermissionsModal();
    } catch (error) {
      if (isObject(error?.data?.errors)) {
        setUpdateUserPermissionsErrors(error.data.errors);
      }
    }
  };

  const handleChangeStatus = () => {
    updateUserStatus({
      userId,
      data: { is_active: !userDetails.is_active },
    });
  };

  const hideUserDetailsModal = () => {
    toggleEditUserModal();
    setUpdateUserErrors({});
  };

  const hideUserRolesModal = () => {
    toggleEditUserRolesModal();
    setUpdateUserRolesErrors({});
  };

  const hideUserPermissionsModal = () => {
    toggleEditUserPermissionsModal();
    setUpdateUserPermissionsErrors({});
  };

  const editUserDetailsInitialValues = {
    name: userDetails.name,
    email: userDetails.email,
  };

  const userRolesList = () => {
    if (isEmpty(userRoles)) {
      return <IntlMessages id='user-details-roles-placeholder' />;
    }

    return userRoles.map((role) => <RoleTag key={role.id} role={role} />);
  };

  return (
    <>
      <Row gutter={32}>
        <Col className="hp-mb-32" span={24}>
          <BreadCrumbs
            breadCrumbParent={<Link to={urlPageRBACUsers()}><IntlMessages id='users-breadcrumbs' /></Link>}
            breadCrumbActive={<IntlMessages id='user-details-breadcrumbs' />}
          />
        </Col>
      </Row>

      <Col span={24} className="hp-mb-24">
        <Spin spinning={isLoading || isUpdateUserStatusLoading}>
          <Card>
            <div>
              <Modal
                title={userDetails?.name}
                width={450}
                centered
                visible={isEditUserModalActive}
                onCancel={hideUserDetailsModal}
                footer={null}
                closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
              >
                <Spin spinning={isLoading} tip={LOADING_TIP_PLACEHOLDER}>
                  <FormCreateEditUser
                    isEditUser
                    initialValues={editUserDetailsInitialValues}
                    formErrors={updateUserErrors}
                    disabledEmail
                    onSubmit={handleUpdateUserData}
                    onCancel={hideUserDetailsModal}
                    isSubmitting={isUpdateUserLoading}
                  />
                </Spin>
              </Modal>

              <Modal
                title={<IntlMessages id='ui-general-roles' />}
                width={450}
                centered
                visible={isEditUserRolesModalActive}
                onCancel={hideUserRolesModal}
                footer={null}
                closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
              >
                <Spin spinning={isUpdateUserRolesLoading || isLoadingRolesOptions} tip={LOADING_TIP_PLACEHOLDER}>
                  <FormEditUserRoles
                    initialValues={{ roles_ids: userRolesIds }}
                    rolesList={roles.roles}
                    isSubmitting={isUpdateUserRolesLoading}
                    formErrors={updateUserRolesErrors}
                    onCancel={hideUserRolesModal}
                    onSubmit={handleUpdateUserRoles}
                  />
                </Spin>
              </Modal>

              <Modal
                title={<IntlMessages id='ui-general-permissions' />}
                width={450}
                centered
                visible={isEditUserPermissionsModalActive}
                onCancel={hideUserPermissionsModal}
                footer={null}
                closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
              >
                <Spin
                  spinning={isUpdateUserPermissionsLoading || isLoadingPermissionsOptions}
                  tip={LOADING_TIP_PLACEHOLDER}
                >
                  <FormEditUserPermissions
                    initialValues={{ permissions_ids: userPermissionsIds }}
                    permissionsList={permissionsList.permissions}
                    formErrors={updateUserPermissionsErrors}
                    isSubmitting={isUpdateUserPermissionsLoading}
                    onCancel={hideUserPermissionsModal}
                    onSubmit={handleUpdateUserPermissions}
                  />
                </Spin>
              </Modal>

              <UserDetailsCard
                userDetails={userDetails}
                handleChangeStatus={handleChangeStatus}
                toggleEditUserModal={toggleEditUserModal}
              />

              {HasRights([ Permissions.ADMINISTRATION.USERS.USER_DETAILS.GET_USER_ROLES ]) && (
                <>
                  <Divider className={dividerClass} />
                  <Row justify="space-between">
                    <Col md={12}>
                      <h3><IntlMessages id='ui-general-roles' /></h3>
                      <p className="hp-p1-body hp-mb-0">
                        {userRolesList()}
                      </p>
                    </Col>

                    {(HasRights([
                      Permissions.ADMINISTRATION.USERS.USER_DETAILS.UPDATE_USER_ROLES,
                      Permissions.ADMINISTRATION.ROLES.ROLES_LIST.VIEW_PAGE,
                    ], true) ||
                      HasRights([
                        Permissions.ADMINISTRATION.USERS.USER_DETAILS.UPDATE_USER_ROLES,
                        Permissions.ADMINISTRATION.USERS.USER_DETAILS.EDIT_OWN_ROLE,
                        Permissions.ADMINISTRATION.ROLES.ROLES_LIST.VIEW_PAGE,
                      ], true)) && (
                      <Col className="hp-mt-md-24">
                        <Button
                          type="primary"
                          onClick={toggleEditUserRolesModal}
                        >
                          <IntlMessages id='ui-general-edit' />
                        </Button>
                      </Col>
                    )}
                  </Row>
                </>
              )}

              {HasRights([ Permissions.ADMINISTRATION.USERS.USER_DETAILS.GET_USER_PERMISSIONS ]) && (
                <>
                  <Divider className={dividerClass} />
                  <Row justify="space-between" className='hp-mb-8'>
                    <Col md={12}>
                      <h3><IntlMessages id='ui-general-permissions' /></h3>
                      <p className="hp-p1-body hp-mb-0">
                        {!isEmpty(userPermissions) ? userPermissions.map((permission) => {
                          return (
                            <Tag
                              key={permission.id}
                              className='hp-my-4'
                              color='geekblue'
                            >
                              {capitalize(permission.name)}
                            </Tag>
                          );
                        }) : (
                          <IntlMessages id='user-details-permissions-placeholder' />
                        )}
                      </p>
                    </Col>

                    {HasRights([
                      Permissions.ADMINISTRATION.USERS.USER_DETAILS.UPDATE_USER_PERMISSIONS,
                      Permissions.ADMINISTRATION.PERMISSIONS.VIEW_LIST,
                    ], true) && (
                      <Col className="hp-mt-md-24">
                        <Button
                          type="primary"
                          onClick={toggleEditUserPermissionsModal}
                        >
                          <IntlMessages id='ui-general-edit' />
                        </Button>
                      </Col>
                    )}
                  </Row>
                </>
              )}
            </div>
          </Card>
        </Spin>
      </Col>
    </>
  );
};

export default PageUserDetails;
