import React from 'react';
import { Col, Row, Spin, Card, Table } from 'antd';
import { useIntl } from 'react-intl';
import BreadCrumbs from '../../../../layout/components/breadcrumbs';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import {
  useGetCompressedCargoQuery,
} from '../../api/imagesApiSlice';
import useQueryParams from '../../../../app/hooks/useQueryParams';
import { getCompressedImagesTableColumns } from './PageImagesCompressed.const';
import basePagination from '../../../../const/pagination';



const PageImagesCompressed = () => {
  const {
    pagination,
    searchParams,
    handleChangeTableParams,
  } = useQueryParams({
    isFilter: true,
    paginationInit: {
      page: 1,
      limit: 50,
    } });
  const intl = useIntl();

  const { data: {
    data: images = [],
    pagination: dataPagination = {},
  } = { data: [], pagination: {} }, isFetching } = useGetCompressedCargoQuery({
    queryParams: `${searchParams.toString()}`,
  });

  const isLoading = isFetching;

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs breadCrumbActive={<IntlMessages id='content-images-breadcrumbs' />} />
      </Row>

      {isLoading && (
        <div className="spin-overlay">
          <div className="spin-wrapper">
            <Spin spinning={isLoading} tip={intl.formatMessage({ id: 'ui-general-loading' })} />
          </div>
        </div>
      )}

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <Table
              sticky
              loading={isFetching}
              rowKey="id"
              columns={getCompressedImagesTableColumns()}
              dataSource={images}
              onChange={handleChangeTableParams}
              pagination={{
                ...basePagination,
                current: pagination.page,
                pageSize: pagination.limit,
                total: dataPagination?.total,
              }}
            />
          </Card>
        </Col>
      </Row>

    </>
  );
};

export default PageImagesCompressed;
